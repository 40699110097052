import { Component, OnInit } from '@angular/core';
import { ChartObvezniciComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/chart-obveznici/chart-obveznici.component';
import { ChartDokumentiComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/chart-dokumenti/chart-dokumenti.component';
import { ChartObvezniciDokumentiComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/chart-obveznici-dokumenti/chart-obveznici-dokumenti.component';
import { StrukturaDugaComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/struktura-duga/struktura-duga.component';
import { RocnostDugaComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/rocnost-duga/rocnost-duga.component';


@Component({
  selector: 'kontrolna-ploca',
  templateUrl: './kontrolna-ploca.component.html'  
})

export class KontrolnaPlocaComponent implements OnInit {
  ngOnInit() {
   
  }
}

