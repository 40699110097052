import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/_models/user';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'users',
  templateUrl: './users.component.html'
})
export class UsersComponent implements OnInit {
  users: any;
  getservice: httpservice;
  displayedColumns: string[] = ['id', 'firstName','lastName', 'username'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;


  constructor(dataservice: httpservice, public dialog: MatDialog, private router: Router) {
    this.getservice = dataservice;
    
  }
  ngOnInit() {

  
    try {
      this.getservice.GetRequest<Message>("User/List").subscribe(result => {
        if (result.status === "success") {
          this.users = new MatTableDataSource<User>(Object.values(result.data));
          this.users.paginator = this.paginator;
          setTimeout(() => this.users.sort = this.sort, 2000);
          this.paginator._intl = new MatPaginatorIntlCro();
          if (this.users.data.length > 0) {
            this.selectedrow = this.users.data[0];
          }
        }
      }, error => console.error('Get zahtjev',error));
    }
    catch (error) {
      console.error('Log error', error);
    }       
  }
  

  highlight(row) {
    this.selectedrow = row;
  }

  openDialog() {
    if (this.selectedrow) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Brisanje korisnika ' + this.selectedrow.username
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.DeleteUser();
        }
      });
    }
  }
  

  DeleteUser() {
    var ind = this.users.data.findIndex(function checkUser(user) {
      return user.id == this.selectedrow.id;
    },this);
    if (ind > -1) {
      this.getservice.PostRequest<Message>("User/delete", this.selectedrow.id).subscribe(result => {
        if (result.status === "success") {

          this.users.data.splice(ind, 1);
          this.users._updateChangeSubscription();
        }
      });
    }
  }

  

  EditUser() {
    if (this.selectedrow) {
      this.router.navigate(['/users/add', this.selectedrow.id]);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();
  }  
}


