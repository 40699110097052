import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit,Input } from '@angular/core';
import { Korisnik } from 'src/app/_models/korisnik';


@Component({
  selector: 'korisnik-info',
  templateUrl: './korisnik-info.component.html'
})
export class KorisnikInfoComponent implements OnInit {
  getservice: httpservice;
  data: any;
  @Input() korisnik?: "";
  
  constructor(dataservice: httpservice) {
    this.getservice = dataservice;       
  }
  ngOnInit() {
    if (this.korisnik !== "") {
      this.getKorisnik(this.korisnik);
    }        
  }

  getKorisnik(id: string) {
    this.getservice.GetRequest<Message>("komunalno/korisnik/Get?sifra=" + id).subscribe(result => {
      if (result.status === "success") {
        this.data = result.data;
      }
    });
  }

}
