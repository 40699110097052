import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { License } from 'src/app/_models/license';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['id', 'client', 'datumKreiranja', 'datumIsteka', 'produzi', 'produziDana','diskSerialNumber'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  lista : any;


  constructor(dataservice: httpservice, public dialog: MatDialog, private router: Router) {
    this.getservice = dataservice;
    
  }
  ngOnInit() {

  
    try {
      this.UcitajPodatke();
    }
    catch (error) {
      console.error('Log error', error);
    }   
  }
  

  highlight(row) {
    this.selectedrow = row;
  }
  
  EditData() {
    if (this.selectedrow) {
      this.router.navigate(['/license/edit', this.selectedrow.id]);
    }
  }

  UcitajPodatke() {
    this.getservice.GetRequest<Message>("license/List").subscribe(result => {
      if (result.status === "success") {
        this.lista = new MatTableDataSource<License>(Object.values(result.data));
        this.lista.paginator = this.paginator;
        setTimeout(() => this.lista.sort = this.sort, 2000);
        this.paginator._intl = new MatPaginatorIntlCro();
        if (this.lista.data.length > 0) {
          this.selectedrow = this.lista.data[0];
        }
      }
    }, error => console.error('Get zahtjev', error));
  }



  openDialog() {
    if (this.selectedrow) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Brisanje licence ' + this.selectedrow.id
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.DeleteData();
        }
      });
    }
  }


  DeleteData() {
    var ind = this.lista.data.findIndex(function checkData(data) {
      return data.id == this.selectedrow.id;
    }, this);
    if (ind > -1) {
      this.getservice.PostRequest<Message>("license/Remove", this.selectedrow.id).subscribe(result => {
        if (result.status === "success") {

          this.lista.data.splice(ind, 1);
          this.lista._updateChangeSubscription();
        }
      });
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }  
}


