import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatRadioButton } from '@angular/material/radio';



@Component({
  selector: 'chart-dokumenti',
  templateUrl: './chart-dokumenti.component.html',
  styleUrls: ['./chart-dokumenti.component.css']
})

export class ChartDokumentiComponent implements OnInit {
  getservice: httpservice;
  chartOption: any;
  dokumenti: any;
  ukupno: number;
  aktivni: string="1";
  iznos: number;
  podaci: { value, name }[];


  constructor(dataservice: httpservice) {
    this.getservice = dataservice;
  }

  ngOnInit() {
    this.Ucitaj();    
  }

  Inicijaliziraj() {
    this.chartOption = {
      title: {
        text: 'Broj pokrenutih postupaka',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Vrsta dokumenta (postupka)',
          type: 'pie',
          radius: '50%',
          data: [...this.podaci],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
    };
  }



  Ucitaj() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/dokument/CountGroupByDokument?aktivni=" + this.aktivni).subscribe(result => {
      if (result.status === "success") {
        this.podaci = [,];
        this.dokumenti = result.data;
        this.ukupno = 0;
        this.iznos = 0;
        result.data.forEach(e => {
          this.podaci.push({ value: e.broj, name: e.dokument });
          this.ukupno += e.broj;
          this.iznos += e.iznos;
        });
        this.Inicijaliziraj();
      }
    }, error => console.error('Get dokumente', error));
  }
}

