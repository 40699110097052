
<div class="containerpadding">
  <div class="row d-flex justify-content-between">
    <div class="col-12">
      <div class="card">
        <div class="news-header background-blue">
          <mat-icon aria-hidden="false" aria-label="Novosti">article</mat-icon> Novosti
        </div>
        <div class="card-body">
          <div *ngFor="let log of changelog" class="mt-2">
            <div class="news-description">{{log.date | date: 'dd.MM.yyyy'}} Verzija: {{log.version}}</div>
            <div class="news-content" [innerHtml]="log.description">              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-flex justify-content-between">
    <div class="col-sm-11 col-lg-6">
      <div class="card">
        <div class="card-header backgrund-green">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <mat-icon aria-hidden="false" aria-label="Zahtjevi">task_alt</mat-icon> Aktivni zahtjevi <span *ngIf="lista">{{lista.length}}</span>
            </div>
            <div>
              <button (click)="NoviZahtjev()" mat-button class="add-color"><mat-icon aria-hidden="false" aria-label="Novi">add_task</mat-icon> Novi</button>&nbsp;
              <button (click)="UcitajOtvorene()" mat-button><mat-icon aria-hidden="false" aria-label="Osvježi">refresh</mat-icon> Osvježi</button>
            </div>
          </div>
        </div>
        <requestsAdd *ngIf="noviZahtjev" [home]="true" (newItemEvent)="addItem($event)"></requestsAdd>

        <ul *ngIf="lista" class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let zahtjev of lista">
            <mat-card-content>
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5 class="requesttitle">{{zahtjev.date | date: 'dd.MM.yyyy HH:mm:ss'}} {{zahtjev.clientname}}</h5>
                <button (click)="IspraviNoviZahtjev(zahtjev.id)" mat-button class="edit-color"><mat-icon aria-hidden="false" aria-label="Uredi">edit</mat-icon> Uredi</button>
              </div>
              <p class="requesttext">
                {{zahtjev.description}}
              </p>
              <h6 class="card-subtitle mb-2  requeststatus">Status: {{zahtjev.status}}</h6>
            </mat-card-content>
            <mat-card-actions>
              <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Detalji
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-form-field class="full-width">
                    <mat-label>Korisnik</mat-label>
                    <input matInput disabled value="{{zahtjev.username}}">
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>Važnost</mat-label>
                    <input matInput disabled value="{{zahtjev.level}}">
                  </mat-form-field>
                  <mat-form-field class="full-width">
                    <mat-label>Riješenje</mat-label>
                    <textarea matInput cdkTextareaAutosize disabled>{{zahtjev.solution}}</textarea>
                  </mat-form-field>
                </mat-expansion-panel>
              </mat-accordion>
            </mat-card-actions>
            <requestsAdd *ngIf="ispravakNovog" [item]="currentItem" (newItemEvent)="editnewItem($event)"></requestsAdd>
          </li>
        </ul>
      </div>



    </div>
    <div class="col-sm-11 col-lg-6">


      <div class="card">
        <div class="card-header backgrund-green">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <mat-icon aria-hidden="false" aria-label="Zatvoreni zahtjevi">check_circle_outline</mat-icon> Zatvoreni zahtjevi
            </div>
            <button (click)="OsvjeziZatvorene()" mat-button class="align-items-center"><mat-icon aria-hidden="false" aria-label="Osvježi">refresh</mat-icon> Osvježi</button>
          </div>
        </div>
        <ul *ngIf="listazatvoreni" class="list-group list-group-flush">
          <li *ngFor="let zatvoren of listazatvoreni" class="list-group-item">
            <h5 class="card-title d-flex justify-content-between align-items-center">
              {{zatvoren.enddate | date: 'dd.MM.yyyy HH:mm:ss'}} {{zatvoren.clientname}}
              <span><button (click)="IspraviZahtjev(zatvoren.id)" mat-button class="edit-color"><mat-icon aria-hidden="false" aria-label="Novi">edit</mat-icon> Uredi</button></span>
            </h5>
            <p class="card-text">{{zatvoren.description}}</p>
            <mat-accordion class="example-headers-align" multi>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Detalji
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-form-field class="full-width">
                  <mat-label>Datum otvaranja</mat-label>
                  <input matInput disabled value="{{zatvoren.date | date: 'dd.MM.yyyy HH:mm:ss'}}">
                </mat-form-field>
                <mat-form-field class="full-width">
                  <mat-label>Korisnik</mat-label>
                  <input matInput disabled value="{{zatvoren.username}}">
                </mat-form-field>
                <mat-form-field class="full-width">
                  <mat-label>Važnost</mat-label>
                  <input matInput disabled value="{{zatvoren.level}}">
                </mat-form-field>
                <mat-form-field class="full-width">
                  <mat-label>Riješenje</mat-label>
                  <textarea *ngIf="zatvoren.solution" matInput cdkTextareaAutosize disabled>{{zatvoren.solution}}</textarea>
                  <input *ngIf="!zatvoren.solution" matInput disabled value="">
                </mat-form-field>
              </mat-expansion-panel>
            </mat-accordion>
            <requestsAdd *ngIf="ispravakZahtjeva" [item]="currentItem" (newItemEvent)="editItem($event)"></requestsAdd>
          </li>
        </ul>
        <button (click)="UcitajJos()" mat-button><mat-icon aria-hidden="false" aria-label="Osvježi">download</mat-icon>Učitaj još</button>
      </div>
    </div>
  </div>
</div>
