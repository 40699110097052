<div class="addressbar">
  <span class="addresstext">Komunalno / Ovrhe</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <p>
      API token za pristup servisu eOvrhe
    </p>
    <div class="input-border col-12">
      <form (ngSubmit)="saveAPIKey()">
        <mat-form-field appearance="fill" class="mt-4 col-lg-10 col-12">
          <mat-label>eOvrhe API token</mat-label>
          <textarea matInput placeholder="Šifra" cdkTextareaAutosize [(ngModel)]="api.eOvrheApiKey" name="eOvrheApiKey"></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill" class="mt-4 col-lg-10 col-12">
          <mat-label>Organizacijska jedinica</mat-label>
          <input matInput placeholder="Organizacijska jedinica" cdkTextareaAutosize [(ngModel)]="api.eOvrhePoslovnaJedinica" name="eOvrhePoslovnaJedinica">
        </mat-form-field>
        <mat-form-field appearance="fill" class="mt-4 col-lg-10 col-12">
          <mat-label>Kontakt podaci</mat-label>
          <textarea matInput placeholder="Kontakt podaci" cdkTextareaAutosize [(ngModel)]="api.eOvrheKontakt" name="eOvrheKontakt"></textarea>
        </mat-form-field>
        <button mat-button type="submit"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon></button>
        <div *ngIf="loading" class="form-group">
          <img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
      </form>
    </div>
  </mat-card>
  <hr />
</div>
