import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter } from "src/app/_models/filter";

@Component({
  selector: 'obveznik-zona-ulica',
  templateUrl: './obveznik-zona-ulica.component.html'
})
export class ObveznikZonaUlicaComponent implements OnInit {
  getservice: httpservice;
  listaObveznika: any;
  listaZona: any;
  listaUlica: any;
  brojkorisnika: number;
  obveznik: string;
  zona: string;
  ulica: string;
  @Output() podaci = new EventEmitter<Filter>();
  
  constructor(dataservice: httpservice) {
    this.getservice = dataservice;       
  }
  ngOnInit() {
    this.brojkorisnika = 0;
    this.obveznik = "";
    this.ulica = "";
    this.zona = "";

    try {
      this.getservice.GetRequest<Message>("komunalno/obveznik/list").subscribe(result => {
        if (result.status === "success") {
          this.listaObveznika = Object.values(result.data);
          this.listaObveznika.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get obveznike', error));
      this.getservice.GetRequest<Message>("komunalno/zona/list").subscribe(result => {
        if (result.status === "success") {
          this.listaZona = Object.values(result.data);
          this.listaZona.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get obveznike', error));
      this.getservice.GetRequest<Message>("komunalno/ulica/list").subscribe(result => {
        if (result.status === "success") {
          this.listaUlica = Object.values(result.data);
          this.listaUlica.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get obveznike', error));
      this.DohvatiBrojKorisnika();
    }
    catch (error) {
      console.error('Log error', error);
    }
  }

  DohvatiBrojKorisnika() {
    this.getservice.GetRequest<Message>("komunalno/korisnik/count?obveznik=" + this.obveznik + "&zona=" + this.zona + "&ulica=" + this.ulica).subscribe(result => {
      if (result.status === "success") {
        this.brojkorisnika = result.data;
        
      }
    }, error => console.error('Get data', error));
    let filter: Filter;
    filter = new Filter();
    filter.obveznik = this.obveznik;
    filter.zona = this.zona;
    filter.ulica = this.ulica;
    this.podaci.emit(filter);
  }



}
