import { Component, OnInit } from '@angular/core';
import { KarticaComponent } from 'src/app/Komunalno/SaldaKonti/Kartica/kartica.component';
import { KarticaKomboComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/kartica-kombo/kartica-kombo.component';
import { KorisnikInfoComponent } from 'src/app/Komunalno/Korisnik/korisnik-info.component';
import { OtvoreniRacuniComponent } from 'src/app/Komunalno/SaldaKonti/otvoreni-racuni/otvoreni-racuni.component';
import { OtvoreniRacuniIDComponent } from 'src/app/Komunalno/SaldaKonti/otvoreni-racuni-id/otvoreni-racuni-id.component';
import { ZatvoreniRacuniComponent } from 'src/app/Komunalno/SaldaKonti/zatvoreni-racuni/zatvoreni-racuni.component';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { Korisnik } from 'src/app/_models/korisnik';
import { FormControl } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'salda-korisnik',
  templateUrl: './salda-korisnik.component.html'
})

export class SaldaKorisnikComponent implements OnInit {
  getservice: httpservice;
  qkorisnik: any;
  listakorisnika: Korisnik[];
  korisnikCtrl = new FormControl();
  loginService: LoginService;
  login: User;

  constructor(dataservice: httpservice, loginservice: LoginService) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.qkorisnik = "";
    this.korisnikCtrl = new FormControl();
    this.loginService.currentUser.subscribe(data => {
      this.login = data;      
    });
  }

  

  onchangeKorisnik(value) {
    if (value.length == 8) {
      this.qkorisnik = value;
    }
    else {
      this.qkorisnik = "";
    }
      this.popuniListu();    
  }

  popuniListu() {
    if (this.korisnikCtrl.value !== "") {
      this.getservice.GetRequest<Message>("komunalno/korisnik/search?searchstring=" + this.korisnikCtrl.value).subscribe(result => {
        if (result.status === "success") {
          this.listakorisnika = result.data;
        }
      }, error => console.error('Get korisnike', error));
    }
  }
  
}

