import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent {
    currentUser: User;
    
    constructor(
        private router: Router,
        private authenticationService: LoginService
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    }

    
}
