import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { LoginService } from 'src/app/login/login.service';
import { Router } from '@angular/router';
import { User } from 'src/app/_models/user';
import { Observable } from 'rxjs';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { Firma } from '../_models/firma';
import { UserSettingsComponent } from '../users/users-settings.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';






@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent implements OnInit {
  getservice: httpservice;
    isExpanded = false;
    login: User;
    loginService: LoginService;
    logiran = false;
    navMain = false;
    navSustav = true;
    navMaticni = true;
  navBI = true;
  navFakturno = true;
  navKomunalno = true;
  navKomunalnoMaticni = true;
  navKomunalnoSalda = true;
  navKomunalnoNaplata = true;
  navKomunalnoeOvrhe=true;
  navPodrska = true;
  modulSustav = false;
  firmapodaci: Firma;

  constructor(loginservice: LoginService, public dialog: MatDialog, dataservice: httpservice, private router: Router) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.firmapodaci = { id: 0, bazaKomunalno: "", broj: "", drzava: 1, eMail: "", iban: "", jib: "", mjesto: "", naziv: "", oib: "", porezniBroj: "", ptt: "", sifra: "", telefon: "", ulica: "", ziroRacun: "", eOvrheAPIKey: "", vrstaUsluge: false, konto: false, eOvrheAddress: "", modulBI: false, modulFakturno: false, modulFinancije: false, modulGdpr: false, modulKomunalno: false, modulMaterijalno: false, modulOsnovna: false, modulPlace: false, sokopBanka: "", sokopFunkcija: "", sokopIme: "", sokopPrezime: "", sokopSud: "", sokopUlica: "" }
      this.loginService.currentUser.subscribe(data => {
        this.modulSustav = false;
          this.login = data;
        if (data) {
          this.UcitajPodatkeoFirmi();
            if (data.role == "SuperUser") {
              this.modulSustav = true;
            }  
          }
        });
  }

  UcitajPodatkeoFirmi() {
    this.getservice.GetRequest<Message>("firma/get?id="+this.login.aktivnaFirma).subscribe(result => {
      if (result.status === "success") {
        this.firmapodaci = result.data;                
      }
    }, error => console.error('Get firma', error));
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    }

    logout() {
        this.resetMeni();
        this.loginService.logout();
      this.logiran = false;      
      this.router.navigate(['/login']);

    }

    clickMaticni() {
      if (this.navMaticni)
        this.navMaticni = false;
      else
        this.navMaticni = true;   
    }
  clickSustav() {
    if (this.navSustav)
      this.navSustav = false;
    else
      this.navSustav = true;
  }
  clickPodrska() {
    if (this.navPodrska)
      this.navPodrska = false;
    else
      this.navPodrska = true;
  }
    clickFakturno() {
        this.navFakturno = false;
  }
  clickBI() {
    if (this.navBI)
      this.navBI = false;
    else
      this.navBI = true;
  }

  clickKomunalno() {
    if (this.navKomunalno)
      this.navKomunalno = false;
    else
      this.navKomunalno = true;    
  }

  clickKomunalnoMaticni() {
    if (this.navKomunalnoMaticni)
      this.navKomunalnoMaticni = false;
    else
      this.navKomunalnoMaticni = true;    
  }

  clickKomunalnoSalda() {
    if (this.navKomunalnoSalda)
      this.navKomunalnoSalda = false;
    else
      this.navKomunalnoSalda = true;
  }

  clickKomunalnoNaplata() {
    if (this.navKomunalnoNaplata)
      this.navKomunalnoNaplata = false;
    else
      this.navKomunalnoNaplata = true;    
  }

  clickKomunalnoeOvrhe() {
    if (this.navKomunalnoeOvrhe)
      this.navKomunalnoeOvrhe = false;
    else
      this.navKomunalnoeOvrhe = true;
  }

    resetMeni() {
        this.navMain = false;
        this.navSustav = true;
        this.navMaticni = true;
      this.navFakturno = true;
      this.navPodrska = true;
      this.navKomunalno = true;
      this.navKomunalnoMaticni = true;
      this.navKomunalnoNaplata = true;
      this.navKomunalnoeOvrhe = true;
  }
 
  }




