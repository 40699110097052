  <mat-card appearance="outlined" class="mt-2">
    <mat-form-field class="fullwidth" appearance="fill">
      <mat-label>Korisnik</mat-label>
      <input matInput (ngModelChange)="onchangeKorisnik($event)"
             aria-label="Korisnik"
             [matAutocomplete]="auto"
             [formControl]="korisnikCtrl">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let korisnik of listakorisnika" [(value)]="korisnik.sifra">
          <span>{{korisnik.sifra}}</span>
          <span>{{korisnik.ime}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-card>
