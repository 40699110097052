import { Component, OnInit, Input } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatTableDataSource } from '@angular/material/table';
import { SaldaStavka } from '../../../_models/SaldaKonti/saldaStavka';


@Component({
  selector: 'zatvoreni-racuni-id',
  templateUrl: 'zatvoreni-racuni-id.component.html',
  styleUrls: ['zatvoreni-racuni-id.component.css']
})

export class ZatvoreniRacuniIDComponent implements OnInit {
  getservice: httpservice;
  @Input('korisnik') korisnik?: "";
  @Input('vrstausluge') vrstausluge?: "";
  displayedColumns: string[] = ['datum', 'isprava', 'redniBroj', 'racun','datumRacuna','valutaRacuna', 'duguje','potrazuje','saldo','status'];
  lista: any;
  duguje: number;
  potrazuje: number;
  saldo: number;
  selectedrow: SaldaStavka;

  constructor(dataservice: httpservice)
  {
    this.getservice = dataservice;
  }

  ngOnInit() {
    this.duguje = 0;
    this.potrazuje = 0;
    this.saldo = 0;
    try {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/kartica/ZatvoreniRacuniPoIDu?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstausluge).subscribe(result => {
        if (result.status === "success") {
          result.data.forEach((element) => {  element.prikazikarticu = false; });
          this.lista = result.data;
          this.lista.forEach((element) => {
            this.duguje += element.duguje;
            this.potrazuje += element.potrazuje;
            this.saldo += element.saldo;
          });
          if (this.lista.length > 0) {
            this.selectedrow = this.lista[0];
          }
        }
      }, error => console.error('Get zatvoreni računi', error));
    }
    catch (error) {
      console.error('Log error', error);
    }
  }

  Ispis() {
    this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/kartica/zatvoreniRacuniPoIDuPdf?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstausluge).subscribe((data: Blob) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }, (error) => {
      console.log('getPDF error: ', error);
    });
  }


  highlight(row) {
    this.selectedrow = row;
  }


  kartica(row) {
    if (row.prikazikarticu) {
      row.prikazikarticu = false;
    }
    else {
      row.prikazikarticu = true;      
    }
  }

}



