<div class="p-1 mt-2">
  <mat-card appearance="outlined">
    <mat-tab-group mat-align-tabs="start">
      <mat-tab label="Ukupni troškovi">
        <div echarts [options]="chartOption2" class="chart"></div>
      </mat-tab>
      <mat-tab label="Struktura">
        <div echarts [options]="chartOption" class="chart"></div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-title>
      Promet po vrstama troška
    </mat-card-title>
    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />
    <p>
      <button (click)="Ispis()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Ispis">print</mat-icon> Ispis</button>&nbsp;
      <button (click)="Export()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Export">save</mat-icon> Izvoz u excel</button>
    </p>
    <table *ngIf="lista" mat-table [dataSource]="lista" multiTemplateDataRows style="width:100%">

      <ng-container matColumnDef="trosak">
        <th mat-header-cell *matHeaderCellDef>Šifra</th>
        <td mat-cell *matCellDef="let stavka"><span (click)="kartica(stavka)" class="material-icons pokazivac align-bottom">expand_more</span> {{stavka.trosak}} </td>
        <td mat-footer-cell *matFooterCellDef> Ukupno </td>
      </ng-container>

      <ng-container matColumnDef="opis">
        <th mat-header-cell *matHeaderCellDef>Opis</th>
        <td mat-cell *matCellDef="let stavka" class="text-opis"> {{stavka.opis}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="mjesec1">
        <th mat-header-cell *matHeaderCellDef>1.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec1 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[0] | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="mjesec2">
        <th mat-header-cell *matHeaderCellDef>2.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec2 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[1] | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="mjesec3">
        <th mat-header-cell *matHeaderCellDef>3.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec3 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[2] | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="mjesec4">
        <th mat-header-cell *matHeaderCellDef>4.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec4 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[3] | number: '1.2-2':'hr'}} </td>
      </ng-container>
      <ng-container matColumnDef="mjesec5">
        <th mat-header-cell *matHeaderCellDef>5.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec5 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[4] | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="mjesec6">
        <th mat-header-cell *matHeaderCellDef>6.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec6 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[5] | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="mjesec7">
        <th mat-header-cell *matHeaderCellDef>7.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec7 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[6] | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="mjesec8">
        <th mat-header-cell *matHeaderCellDef>8.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec8 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[7] | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="mjesec9">
        <th mat-header-cell *matHeaderCellDef>9.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec9 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[8] | number: '1.2-2':'hr'}} </td>
      </ng-container>
      <ng-container matColumnDef="mjesec10">
        <th mat-header-cell *matHeaderCellDef>10.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec10 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[9] | number: '1.2-2':'hr'}} </td>
      </ng-container>
      <ng-container matColumnDef="mjesec11">
        <th mat-header-cell *matHeaderCellDef>11.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec11 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[10] | number: '1.2-2':'hr'}} </td>
      </ng-container>
      <ng-container matColumnDef="mjesec12">
        <th mat-header-cell *matHeaderCellDef>12.</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.mjesec12 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje[11] | number: '1.2-2':'hr'}} </td>
      </ng-container>
      <ng-container matColumnDef="ukupno">
        <th mat-header-cell *matHeaderCellDef>Ukupno</th>
        <td mat-cell *matCellDef="let stavka" class="text-right text-ukupno"> {{stavka.mjesec1+stavka.mjesec2+stavka.mjesec3+stavka.mjesec4+stavka.mjesec5+stavka.mjesec6+stavka.mjesec7+stavka.mjesec8+stavka.mjesec9+stavka.mjesec10+stavka.mjesec11+stavka.mjesec12 | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right text-ukupno"> {{ukupno | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <!-- Header row first group -->
      <ng-container matColumnDef="header-row-first-group">
        <th mat-header-cell *matHeaderCellDef
            [style.text-align]="center"
            [attr.colspan]="2">
        </th>
      </ng-container>

      <!-- Header row second group -->
      <ng-container matColumnDef="header-row-second-group">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="12"> Mjesec </th>
      </ng-container>
      <!-- Header row second group -->
      <ng-container matColumnDef="header-row-third-group">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div *ngIf="element.prikazikarticu">
            <troskovi-kartica [trosak]="element.trosak"></troskovi-kartica>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['header-row-first-group', 'header-row-second-group','header-row-third-group']"></tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row;columns: displayedColumns;" class="example-element-row"></tr>
      <tr mat-row *matRowDef="let row;columns: ['expandedDetail']" class="example-detail-row"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </mat-card>

</div>


