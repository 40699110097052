

<mat-form-field appearance="fill" class="col-lg-4 col-12">
  <mat-label>Tip vezanja</mat-label>
  <mat-select ngDefaultControl name="tipVezanja" [(ngModel)]="tipVezanja" (selectionChange)="onTipVezanja($event.value)">
    <mat-option value="R">
      Ručno zatvaranje računa
    </mat-option>
    <mat-option value="A">
      Automatsko zatvaranje
    </mat-option>
  </mat-select>
</mat-form-field>
<p *ngIf="!data">
  Učitavam podatke ročnosti<img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
</p>
<mat-card appearance="outlined" class="mt-2">
  <div echarts [options]="chartOption" class="chart"></div>
</mat-card>

<mat-card appearance="outlined" class="mt-2">
  <table class="col-12" *ngIf="data">
    <thead>
      <tr class="table-header text-center">
        <td>Ročnost duga</td>
        <td>Iznos</td>
        <td>Postotak u ukupnom dugu</td>
        <td>Broj računa</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dug of data" class="table-row">
        <td>{{dug.naziv}} <button (click)="IspisListeDuznika(dug.naziv)" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Ispis">print</mat-icon> Ispis</button></td>
        <td class="text-right">{{dug.iznos | number: '1.2-2':'hr'}}</td>
        <td class="text-center">{{dug.iznos*100/ukupniiznos | number: '1.0-0':'hr'}}%</td>
        <td class="text-right">{{dug.kolicina  | number: '1.0-0':'hr'}}</td>
      </tr>
      <tr class="table-footer">
        <td>Ukupno</td>
        <td class="text-right">{{ukupniiznos | number: '1.2-2':'hr'}}</td>
        <td></td>
        <td class="text-right">{{ukupnibroj | number: '1.0-0':'hr'}}</td>
      </tr>
    </tbody>
  </table>
  <p *ngIf="ispis">
    Ispisujem podatke ročnosti<img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
  </p>
</mat-card>
<mat-card appearance="outlined" class="mt-2" *ngIf="login.vrstaUsluge">
  <div echarts [options]="chartOptioncistoca" class="chart"></div>
</mat-card>

<mat-card appearance="outlined" class="mt-2" *ngIf="login.vrstaUsluge">
  <table class="col-12" *ngIf="datacistoca">
    <thead>
      <tr class="table-header text-center">
        <td>Ročnost duga čistoća</td>
        <td>Iznos</td>
        <td>Postotak u ukupnom dugu</td>
        <td>Broj računa</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dug of datacistoca" class="table-row">
        <td>{{dug.naziv}} </td>
        <td class="text-right">{{dug.iznos | number: '1.2-2':'hr'}}</td>
        <td class="text-center">{{dug.iznos*100/ukupniiznoscistoca | number: '1.0-0':'hr'}}%</td>
        <td class="text-right">{{dug.kolicina  | number: '1.0-0':'hr'}}</td>
      </tr>
      <tr class="table-footer">
        <td>Ukupno</td>
        <td class="text-right">{{ukupniiznoscistoca | number: '1.2-2':'hr'}}</td>
        <td></td>
        <td class="text-right">{{ukupnibrojcistoca | number: '1.0-0':'hr'}}</td>
      </tr>
    </tbody>
  </table>
</mat-card>
