import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { KreirajPrijedlogSokopModel } from 'src/app/_models/Sokop/KreirajPrijedlogModel';
import * as FileSaver from 'file-saver';


export interface DialogData {
  pitanje: string;
  pojasnjenje: string;
}

@Component({
  selector: 'SokopPrijedlogComponent',
  templateUrl: 'sokop-prijedlog.component.html',
  styleUrls: ['sokop-prijedlog.component.css']
})

export class SokopPrijedlogComponent {
  greska: string;
  getservice: httpservice;
  slanje: boolean = false;
  fileName: string = "";
  file:any;
  listaSudova: any;
  listaBanaka: any;
  banka: any;
  sud: any;

  constructor(public dialogRef: MatDialogRef<SokopPrijedlogComponent>, @Inject(MAT_DIALOG_DATA) public data: KreirajPrijedlogSokopModel, dataservice: httpservice)
  {
    this.getservice = dataservice;
    this.data.sud = "";
    this.data.banka = "";
  }

  ngOnInit() {
    
    try {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/sokop/sudovi").subscribe(result => {
        if (result.status === "success") {
          this.listaSudova = Object.values(result.data);          
        }
      }, error => console.error('Get sudove', error));
      this.getservice.GetRequest<Message>("komunalno/saldakonti/sokop/banke").subscribe(result => {
        if (result.status === "success") {
          this.listaBanaka = Object.values(result.data);          
        }
      }, error => console.error('Get banke', error));            
    }
    catch (error) {
      console.error('Log error', error);
    }
  }

  KreirajPrijedlog() {
    this.slanje = true;
    
    
    this.getservice.PostRequestFile<Blob>("komunalno/saldakonti/sokop/PrijedlogZaIzvrsenjeSud", this.data).subscribe(data => {
      var file = new Blob([data], { type: 'application/octet-stream' })
      //var fileURL = URL.createObjectURL(file);
      //window.open(fileURL);
      FileSaver.saveAs(file, "Prijedlozi.zip");
      this.slanje = false;
    }, (error) => {
      console.log('getZip error: ', error);
      this.slanje = false;
    });

  }
  
  onYesClick(): void {
    this.KreirajPrijedlog();
  } 

}



