import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


import { LoginService } from 'src/app/login/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private authenticationService: LoginService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
      if (currentUser) {
        let url: string = route.url.join('');
        if (url == "license" || url == "users" || url == "firma" || url == "klijent" || url == "changelog") {
          if (currentUser.role != "SuperUser") {
            this.router.navigate(['/home']);
          }
        }
            // logged in so return true
            return true;
        }
      
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      
        return false;
    }
}
