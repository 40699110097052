<div class="addressbar">
  <span class="addresstext">Komunalno / Obrada dugovanja / Ispis</span>
</div>
<div class="containerpadding">
  <form (ngSubmit)="Ispisi()">
    <mat-card appearance="outlined">
      <mat-card-title>
        Ispis
      </mat-card-title>
      <mat-card-content>
        <p>
          <obveznik-zona-ulica (podaci)="OdabirUvjeta($event)"></obveznik-zona-ulica>
        </p>
        <div class="row">
          <div class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Vrsta izvješća</mat-label>
              <mat-select name="izvjesce" [(ngModel)]="vrstaizvjesca">
                <mat-option value="1">Lista korisnika sa statusom (dokumentom)</mat-option>
                <mat-option value="2">Ispis dopisa</mat-option>
                <mat-option value="3">Izvoz liste u excel</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="vrstaizvjesca!=2" class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="col-lg-6 col-12">
              <mat-label>Od dana</mat-label>
              <input matInput type="date-local" [matDatepicker]="dp1" [ngModel]="oddana | date:'yyyy-MM-dd'" (ngModelChange)="oddana=$event" placeholder="Od dana" name="oddana">
              <mat-datepicker-toggle matIconSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-lg-6 col-12">
              <mat-label>Do dana</mat-label>
              <input matInput type="date-local" [matDatepicker]="dp2" [ngModel]="dodana | date:'yyyy-MM-dd'" (ngModelChange)="dodana=$event" placeholder="Do dana" name="dodana">
              <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="vrstaizvjesca==2" class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="col-6">
              <mat-label>Od broja</mat-label>
              <input [(ngModel)]="brojod" matInput placeholder="Broj dokumenta" name="brojod" type="number">
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-6">
              <mat-label>do broja</mat-label>
              <input [(ngModel)]="brojdo" matInput placeholder="Broj dokumenta" name="brojdo" type="number">
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-12">
            <mat-form-field *ngIf="listadokumenata" appearance="fill" class="col-12">
              <mat-label>Dokument (status)</mat-label>
              <mat-select ngDefaultControl name="dokument" [(ngModel)]="dokumentid">
                <mat-option *ngFor="let dokument of listadokumenata" [value]="dokument.pravniDokumentVrstaId">
                  {{dokument.sifra}} {{dokument.naziv}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="vrstaizvjesca==2" class="col-lg-6 col-12">
            <mat-form-field *ngIf="listatekstova" appearance="fill" class="col-12">
              <mat-label>Tekst za ispis</mat-label>
              <mat-select ngDefaultControl name="tekstispis" [(ngModel)]="tekstispis">
                <mat-option *ngFor="let dokument of listatekstova" [value]="dokument.pravniDokumentTekstIspisId">
                  {{dokument.sifra}} {{dokument.naziv}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-12">
            <mat-form-field appearance="fill" class="col-12">
              <mat-label>Prikaži</mat-label>
              <mat-select [(ngModel)]="aktivan" name="aktivan">
                <mat-option value="1">Sve</mat-option>
                <mat-option value="2">Aktivne</mat-option>
                <mat-option value="3">Neaktivne</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-12 pl-5" *ngIf="vrstaizvjesca==2">
            <mat-checkbox [(ngModel)]="prilozikarticu" name="prilozikarticu">Priloži analitičku karticu</mat-checkbox>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="text-center">
        <button mat-button type="submit" class="ok-color"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon>Ispiši</button>
      </mat-card-actions>
    </mat-card>
  </form>
  </div>
