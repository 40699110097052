<div class="p-1 mt-2">
  <mat-card appearance="outlined">
    <mat-card-title>
      Kartica troška
    </mat-card-title>
    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />
    
    <p class="col-lg-6 col-12">
      <mat-form-field *ngIf="listatroskova && trosak==undefined" appearance="fill" class="col-12">
        <mat-label>Troškovi/rashodi</mat-label>
        <mat-select ngDefaultControl [(ngModel)]="odabrani" name="odabrani">
          <mat-option value=""></mat-option>
          <mat-option *ngFor="let dokument of listatroskova" [value]="dokument">
            {{dokument.sifra}} {{dokument.opis}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-lg-6 col-12">
        <mat-label>Od dana</mat-label>
        <input matInput type="date" [ngModel]="oddana | date:'yyyy-MM-dd'" (ngModelChange)="oddana=$event" placeholder="Od dana" name="oddana">
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-lg-6 col-12">
        <mat-label>Do dana</mat-label>
        <input matInput type="date" [ngModel]="dodana | date:'yyyy-MM-dd'" (ngModelChange)="dodana=$event" placeholder="Do dana" name="dodana">
      </mat-form-field>
    </p>
    <p>
      <button (click)="UcitajKarticu()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Osvježi">refresh</mat-icon> Osvježi</button>&nbsp;
      <button (click)="Ispis()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Ispis">print</mat-icon> Ispis</button>
    </p>
    <p *ngIf="prikazani">
      {{prikazani.sifra}} {{prikazani.opis}}
    </p>
    <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

      <ng-container matColumnDef="datum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Datum</th>
        <td mat-cell *matCellDef="let stavka"> {{stavka.datum | date: 'dd.MM.yyyy'}} </td>
        <td mat-footer-cell *matFooterCellDef> Ukupno </td>
      </ng-container>

      <ng-container matColumnDef="isprava">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Isprava</th>
        <td mat-cell *matCellDef="let stavka"> {{stavka.isprava}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="redniBroj">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Redni broj</th>
        <td mat-cell *matCellDef="let stavka"> {{stavka.redniBroj}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="opis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Opis</th>
        <td mat-cell *matCellDef="let stavka"> {{stavka.opis}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="duguje">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Duguje</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.duguje | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="potrazuje">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Potražuje</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.potrazuje | number: '1.2-2':'hr'}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right"> {{potrazuje | number: '1.2-2':'hr'}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Status</th>
        <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.status}} </td>
        <td mat-footer-cell *matFooterCellDef class="text-right">{{duguje - potrazuje | number: '1.2-2':'hr'}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row;columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </mat-card>
</div>


