import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'struktura-duga',
  templateUrl: './struktura-duga.component.html',
  styleUrls: ['./struktura-duga.component.css'],

})

export class StrukturaDugaComponent implements OnInit {
  getservice: httpservice;
  data: any;
  ukupnidug: number;
  ukupniiznos: number;
  ukupnibroj: number;
  chartOption: any;
  podaci: { value, name }[];
  datacistoca: any;
  ukupnidugcistoca: number;
  ukupniiznoscistoca: number;
  ukupnibrojcistoca: number;
  chartOptioncistoca: any;
  podacicistoca: { value, name }[];
  loginService: LoginService;
  login: User;

  constructor(dataservice: httpservice, loginservice: LoginService) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.ukupnidug = 0;
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      if (data) {
        if (this.login.vrstaUsluge) {
          this.UcitajPodatkeUsluge();
        }
        else {
          this.UcitajPodatke();
        }
      }
    });
    
  }

  UcitajPodatke() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/strukturaduga").subscribe(result => {
      if (result.status === "success") {
        this.data = result.data;
        this.ukupnidug = this.data.zaduzenje - this.data.uplate + this.data.ostalo;
        this.ukupnibroj = 0;
        this.ukupniiznos = 0;
        this.data.struktura.forEach(e => {
          this.ukupnibroj += e.kolicina;
          this.ukupniiznos += e.iznos;
        });
        this.podaci = [,];
        result.data.struktura.forEach(e => {
          this.podaci.push({ value: e.iznos, name: e.naziv });
        });
        this.Inicijaliziraj();
      }
    }, error => console.error('Get data', error));
  }


  UcitajPodatkeUsluge() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/strukturaduga?vrstausluge=V").subscribe(result => {
      if (result.status === "success") {
        this.data = result.data;
        this.ukupnidug = this.data.zaduzenje - this.data.uplate + this.data.ostalo;
        this.ukupnibroj = 0;
        this.ukupniiznos = 0;
        this.data.struktura.forEach(e => {
          this.ukupnibroj += e.kolicina;
          this.ukupniiznos += e.iznos;
        });
        this.podaci = [,];
        result.data.struktura.forEach(e => {
          this.podaci.push({ value: e.iznos, name: e.naziv });
        });
        this.Inicijaliziraj();
      }
    }, error => console.error('Get data', error));

    this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/strukturaduga?vrstausluge=C").subscribe(result => {
      if (result.status === "success") {
        this.datacistoca = result.data;
        this.ukupnidugcistoca = this.datacistoca.zaduzenje - this.datacistoca.uplate + this.datacistoca.ostalo;
        this.ukupnibrojcistoca = 0;
        this.ukupniiznoscistoca = 0;
        this.datacistoca.struktura.forEach(e => {
          this.ukupnibrojcistoca += e.kolicina;
          this.ukupniiznoscistoca += e.iznos;
        });
        this.podacicistoca = [,];
        result.data.struktura.forEach(e => {
          this.podacicistoca.push({ value: e.iznos, name: e.naziv });
        });
        this.InicijalizirajCistoca();
      }
    }, error => console.error('Get data', error));
  }


  Inicijaliziraj() {
    this.chartOption = {
      title: {
        text: 'Struktura duga',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Dug po iznosu',
          type: 'pie',
          radius: '50%',
          data: [...this.podaci],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
    };
  }

  InicijalizirajCistoca() {
    this.chartOptioncistoca = {
      title: {
        text: 'Struktura duga čistoća',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Dug po iznosu',
          type: 'pie',
          radius: '50%',
          data: [...this.podacicistoca],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
    };
  }

}
