<mat-card appearance="outlined">
  <div echarts [options]="chartOption" class="chart"></div>
  <mat-radio-group (change)="Ucitaj()" aria-label="Aktivnost" [(ngModel)]="aktivni">
    <mat-radio-button value="1" name="svi" class="redovi">Svi</mat-radio-button>&nbsp;
    <mat-radio-button value="2" name="aktivni" class="redovi">Aktivni</mat-radio-button>&nbsp;
    <mat-radio-button value="3" name="nekativni" class="redovi">Neaktivni</mat-radio-button>
  </mat-radio-group>
</mat-card>
<mat-card appearance="outlined" class="mt-2">
  <table class="col-12">
    <thead>
      <tr class="table-header">
        <td>Vrsta dokumenta</td>
        <td>Broj</td>
        <td class="text-center">Iznos</td>
      </tr>
    </thead>
    <tr *ngFor="let podatak of dokumenti" class="table-row">
      <td>{{podatak.dokument}}</td>
      <td class="text-right">{{podatak.broj}}</td>
      <td class="text-right">{{podatak.iznos | number: '1.2-2':'hr'}}</td>
    </tr>
    <tr class="table-footer">
      <td>Ukupno</td>
      <td class="text-right">{{ukupno}}</td>
      <td class="text-right">{{iznos | number: '1.0-2':'hr'}}</td>
    </tr>
  </table>
</mat-card>
