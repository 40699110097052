import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { Router } from '@angular/router';
import { PravniDokument } from 'src/app/_models/Utuzivanje/pravnidokument';
import { KarticaComponent } from '../../../../Komunalno/SaldaKonti/Kartica/kartica.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NastavakLista } from 'src/app/_models/Utuzivanje/nastavaklista';
import { PokretanjePodaci } from 'src/app/_models/Utuzivanje/pokretanjepodaci';
import { NastavakPodaci } from '../../../../_models/Utuzivanje/nastavakpodaci';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'nastavak',
  templateUrl: './nastavak.component.html',
  styleUrls: ['./nastavak.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0'})),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class NastavakComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['sifra', 'ime','datum', 'iznos','saldo', 'nastavipostupak'];
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    if (this.lista) {
      value._intl = new MatPaginatorIntlCro();
      this.lista.paginator = value;
      this.lista.sort = this.sort;
    }
  };
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;

  listaObveznika: any;
  listaZona: any;
  listaUlica: any;
  listadokumenata: any;
  qObveznik: any;
  qZona: any;
  qUlica: any;
  qDokument: any;
  qVrstausluge: any;
  listakorisnika: any;
  lista: any;
  rezultat: any;
  aktivan: any;
  odabranidokument: any;
  vrstasalda: any;
  brojodabranih = 0;
  startdata: NastavakPodaci = { dokumentId: 0, nadan: new Date(), dokumenti: [], rokdana: 0, tipiznosa: "0" };
  oznaci: boolean;
  loginService: LoginService;
  login: User;
  loadingstart: boolean = false;
  korisnik: string;
  pojedinacnikorisnik: string;

  constructor(dataservice: httpservice, loginservice: LoginService, public dialog: MatDialog, private router: Router, private _snackBar: MatSnackBar) {
    this.getservice = dataservice;
    this.loginService = loginservice;

  }
  ngOnInit() {
    this.oznaci = true;
    this.startdata.nadan = new Date();    
    this.qObveznik = "";
    this.qZona = "";
    this.qUlica = "";
    this.qDokument = "";
    this.qVrstausluge = "";
    this.aktivan = true;
    this.korisnik = "";
    this.pojedinacnikorisnik = "0";
    this.vrstasalda = "0";
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
    });

    try {
      this.getservice.GetRequest<Message>("komunalno/obveznik/list").subscribe(result => {
        if (result.status === "success") {
          this.listaObveznika = Object.values(result.data);
          this.listaObveznika.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get obveznike', error));
      this.getservice.GetRequest<Message>("komunalno/zona/list").subscribe(result => {
        if (result.status === "success") {
          this.listaZona = Object.values(result.data);
          this.listaZona.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get obveznike', error));
      this.getservice.GetRequest<Message>("komunalno/ulica/list").subscribe(result => {
        if (result.status === "success") {
          this.listaUlica = Object.values(result.data);
          this.listaUlica.unshift({ sifra: "", naziv: "Sve" });
        }
      }, error => console.error('Get obveznike', error));
      this.UcitajDokumente();      
    }
    catch (error) {
      console.error('Log error', error);
    }
  }  

  UcitajKorisnike() {
    this.loadingstart = true;
    if (this.pojedinacnikorisnik == "0") {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/listadokumenata?obveznik=" + this.qObveznik + "&zona=" + this.qZona + "&ulica=" + this.qUlica + "&dokumentid=" + this.odabranidokument.pravniDokumentVrstaId + "&aktivan=" + this.aktivan + "&vrstausluge=" + this.qVrstausluge + "&trenutnisaldo=" + this.vrstasalda).subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource<NastavakLista>(Object.values(result.data));
          if (this.lista.data.length > 0) {
            this.lista.data.forEach(l => l.nastavipostupak = true);
            this.selectedrow = this.lista.data[0];
            this.prebroj();
            this.loadingstart = false;
            this.UcitajDokumente();
          }
        }
      });
    }
    else {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/dokumentkorisnika?korisnik=" + this.korisnik + "&dokumentid=" + this.odabranidokument.pravniDokumentVrstaId + "&aktivan=" + this.aktivan + "&vrstausluge=" + this.qVrstausluge + "&trenutnisaldo=" + this.vrstasalda).subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource<NastavakLista>(Object.values(result.data));
          if (this.lista.data.length > 0) {
            this.lista.data.forEach(l => l.nastavipostupak = true);
            this.selectedrow = this.lista.data[0];
            this.prebroj();
            this.loadingstart = false;
            this.UcitajDokumente();
          }
        }
      });
    }
  }

  UcitajDokumente() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/dokumentvrsta/List").subscribe(result => {
      if (result.status === "success") {
        this.listadokumenata = Object.values(result.data);        
      }
    }, error => console.error('Get obveznike', error));
  }


 

 

  highlight(row) {
    this.selectedrow = row;
    if (row.prikazikarticu) {
      row.prikazikarticu = false;
    }
    else {
      row.prikazikarticu = true;
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }


  openDialog() {    
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        pitanje: 'Da li ste sigurni ?',
        pojasnjenje: 'Pokretanje ' + this.brojodabranih + ' postupka'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.PokreniPostupak();
      }
    });

  }


  PokreniPostupak() {
    if (this.startdata.dokumentId != 0)
    {
      let zaslanje: number[] = [];

      this.lista.data.forEach((e, i) => {
        if (e.nastavipostupak) {
          zaslanje.push(e.dokumentid);
        }
      }
      );
      this.startdata.dokumenti = zaslanje;
      this.getservice.PostRequest<Message>("komunalno/saldakonti/utuzivanje/nastavak", this.startdata).subscribe(result => {
        this.brojodabranih = 0;
        this.lista = null;
        this.PostupakZavrsen(result.data, "Uredu");
      }, error => {
        this.PostupakZavrsen("Greška prilikom nastavka postupka " + error, "Uredu");
        console.error('Pokreni postupak', error);
      });
    }
  }

  pocetniuvjeti() {
    this.lista = null;
    this.odabranidokument = null;
    this.korisnik = "";
  }

  prebroj() {
    this.brojodabranih = 0;
    this.lista.data.forEach((e, i) => {
      if (e.nastavipostupak) {
        this.brojodabranih++;
      }
    }
    );
  }

  PostupakZavrsen(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  OznaciSve() {
    if (this.oznaci) {
      this.lista.data.forEach((e, i) => {
        e.nastavipostupak = false;
      });
      this.oznaci = false;
    }
        else {
      this.lista.data.forEach((e, i) => {
        e.nastavipostupak = true;
      });
      this.oznaci = true;
    }
    this.prebroj();
  }

  OdabirKorisnika(izbor: string) {
    this.korisnik = izbor;
  }

  onchangeKorisnik(value) {
    if (this.pojedinacnikorisnik === "0") {
      this.korisnik = "";
    }
  }
}


