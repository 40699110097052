import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit, Output,Input, EventEmitter } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { Request } from 'src/app/_models/request';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { User } from 'src/app/_models/user';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { Observable } from 'rxjs';


@Component({
  selector: 'requestsAdd',
  templateUrl: './requestsAdd.component.html'
})
export class RequestsAddComponent implements OnInit {
  getservice: httpservice;
  data: Request = { clientid: 0, date: new Date(),description:"",level:"",status:"",id:0,userid:"",solution:"",enddate:null,workTime:""};
  loading = false;
  submitted = false;
  superuser = false;
  status: string = "";
  returnUrl: string;
  error = '';
  editing = false;
  poruka = "Dodavanje";
  loginService: LoginService;
  login: User;
  clients: any[];
  @Input() item: 0;
  @Input() home: false;
  @Output() newItemEvent = new EventEmitter<Request>();


  constructor(dataservice: httpservice, private router: Router, private route: ActivatedRoute, private location: Location, loginservice: LoginService) {
    this.getservice = dataservice;
    this.loginService = loginservice;
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      this.data.userid = this.login.id;
      if (this.login.role == "SuperUser") {
        this.superuser = true;
      }
    });  
  }
  ngOnInit() {
    
    this.data.date = new Date();
    this.getservice.GetRequest<Message>("User/Clients?id=" + this.login.id).subscribe(result => {
      if (result.status === "success") {
        this.clients = Object.values(result.data);
      }
    });
    var idreq = this.route.snapshot.paramMap.get('id');
    if (idreq != null) {
      this.getRequest(idreq);
    }
    if (this.item > 0) {
      this.getRequest(this.item.toString());
    }
   
  }

  

  addRequest() {
    this.submitted = true;
    this.loading = true;
      if (!this.editing) {
        this.getservice.PostRequest<Message>("request/add", this.data).subscribe(result => {
          if (result.status === "success")
          {
            this.data.id = result.data;
            this.newItemEvent.emit(this.data);
            //this.goBack();
          }
          else {
            console.error('Log error', result);
          }
        },
          error => console.error('Log error', error));
      }
      else {
        this.getservice.PostRequest<Message>("request/edit", this.data).subscribe(result => {
          if (result.status === "success") {
            this.data.id = result.data;
            this.newItemEvent.emit(this.data);
            /*this.goBack();*/
            //this.router.navigate(['/requests']);
          }
          else {
            console.error('Log error', result);
          }
        }, error => console.error('Log error',error));
      }
    
    
  }

  goBack() {
    if (this.editing) {
      if (this.item) {
        this.data.id = 0;
        this.newItemEvent.emit(this.data);
      }
      else {
        this.location.back();
      }
    }
    else {
      if (this.home) {
        this.data.id = 0;
        this.newItemEvent.emit(this.data);
      }
      else {
        this.location.back();
      }
    }
  }   

  getRequest(id: string) {
    this.getservice.GetRequest<Message>("request/Get?id=" + id).subscribe(result => {
      if (result.status === "success") {
        this.editing = true;
        this.poruka = "Ispravak";
        this.data = result.data;
        console.log(result.data);
      }
    });
  }

}
