<div class="p-1 mt-2">
  <mat-card appearance="outlined">
    <mat-card-title>
      Zatvoreni računi korisnika automatika
    </mat-card-title>
    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />
    <p>
      <button (click)="Ispis()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Brisanje">print</mat-icon> Ispis</button>
    </p>
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" multiTemplateDataRows matSort style="width:100%">

        <ng-container matColumnDef="datum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Datum</th>
          <td mat-cell *matCellDef="let stavka"><span (click)="kartica(stavka)" class="material-icons pokazivac align-bottom">expand_more</span> {{stavka.datum | date: 'dd.MM.yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef> Ukupno </td>
        </ng-container>

        <ng-container matColumnDef="isprava">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Isprava</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.isprava}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="redniBroj">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Redni broj</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.redniBroj}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="racun">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Račun</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.racun}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="datumRacuna">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum računa</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.datumRacuna | date: 'dd.MM.yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="valutaRacuna">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Valuta računa</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.valutaRacuna | date: 'dd.MM.yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="duguje">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Duguje</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.duguje | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <ng-container matColumnDef="potrazuje">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Potražuje</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.potrazuje | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{potrazuje | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <ng-container matColumnDef="saldo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Saldo</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.saldo | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{saldo | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Status</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.status}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"></td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <p *ngIf="element.prikazikarticu">
              <racun-uplate [racunid]="element.saldaStavkaId"></racun-uplate>
            </p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" (click)="highlight(row)" [ngClass]="{highlight: selectedrow.saldaStavkaId === row.saldaStavkaId}" class="example-element-row"></tr>
        <tr mat-row *matRowDef="let row;columns: ['expandedDetail']" class="example-detail-row"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
      </div>
  </mat-card>
</div>


