import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { Korisnik } from 'src/app/_models/korisnik';
import { FormControl } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'korisnik-select',
  templateUrl: './korisnik-select.component.html'
})

export class KorisnikSelectComponent implements OnInit {
  getservice: httpservice;
  qkorisnik: any;
  listakorisnika: Korisnik[];
  korisnikCtrl = new FormControl();
  loginService: LoginService;
  login: User;
  @Output() podaci = new EventEmitter<string>();

  constructor(dataservice: httpservice, loginservice: LoginService) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.qkorisnik = "";
    this.korisnikCtrl = new FormControl();
    this.loginService.currentUser.subscribe(data => {
      this.login = data;      
    });
  }

  

  onchangeKorisnik(value) {
    if (value.length == 8) {
      this.qkorisnik = value;
      
    }
    else {
      this.qkorisnik = "";
    }
    this.podaci.emit(this.qkorisnik);
      this.popuniListu();    
  }

  popuniListu() {
    if (this.korisnikCtrl.value !== "") {
      this.getservice.GetRequest<Message>("komunalno/korisnik/search?searchstring=" + this.korisnikCtrl.value).subscribe(result => {
        if (result.status === "success") {
          this.listakorisnika = result.data;
        }
      }, error => console.error('Get korisnike', error));
    }
  }
  
}

