<div class="addressbar">
  <span class="addresstext">Komunalno / Matični / Korisnici</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Korisnici
    </mat-card-title>

    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />


    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

      <ng-container matColumnDef="sifra">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Šifra </th>
        <td mat-cell *matCellDef="let element"> {{element.sifra}} </td>
      </ng-container>

      <ng-container matColumnDef="ime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Naziv </th>
        <td mat-cell *matCellDef="let element"> {{element.ime}} </td>
      </ng-container>

      <ng-container matColumnDef="obveznik">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Obveznik </th>
        <td mat-cell *matCellDef="let element"> {{element.obveznik}} </td>
      </ng-container>

      <ng-container matColumnDef="zona">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
        <td mat-cell *matCellDef="let element"> {{element.zona}} </td>
      </ng-container>

      <ng-container matColumnDef="ulica">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Ulica </th>
        <td mat-cell *matCellDef="let element"> {{element.ulica}} </td>
      </ng-container>

      <ng-container matColumnDef="dodatnaAdresa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dodatna adresa</th>
        <td mat-cell *matCellDef="let element"><mat-checkbox [(ngModel)]="element.dodatnaAdresa" name="dodatnaadresa"></mat-checkbox></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row;columns: displayedColumns;" [ngClass]="{highlight: selectedrow.sifra === row.sifra}" (click)="highlight(row)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[ 10, 20,50]" showFirstLastButtons></mat-paginator>
  </mat-card>
   <hr />
   </div>
