import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './users/users.component';
import { KlijentComponent } from './Klijent/klijent.component';
import { KlijentAddComponent } from './Klijent/klijentAdd.component';
import { FirmaComponent } from './firma/firma.component';
import { FirmaAddComponent } from './firma/firmaAdd.component';
import { RequestsComponent } from './requests/requests.component';
import { RequestsAddComponent } from './requests/requestsAdd.component';
import { LicenseComponent } from './license/license.component';
import { LicenseEditComponent } from './license/licenseEdit.component';
import { VodomjerilaComponent } from './Komunalno/Vodomjerila/vodomjerila.component';
import { KorisnikComponent } from './Komunalno/Korisnik/korisnik.component';
import { KarticaComponent } from './Komunalno/SaldaKonti/Kartica/kartica.component';
import { KarticaKomboComponent } from './Komunalno/SaldaKonti/Utuzivanje/kartica-kombo/kartica-kombo.component';
import { StartComponent } from './Komunalno/SaldaKonti/Utuzivanje/Start/start.component';
import { NastavakComponent } from './Komunalno/SaldaKonti/Utuzivanje/Nastavak/nastavak.component';
import { ObustavakComponent } from './Komunalno/SaldaKonti/Utuzivanje/obustavak/obustavak.component';
import { MatCardModule } from '@angular/material/card';
import { JwtInterceptor } from 'src/app/_helpers/jwt.interceptor';
import { ErrorInterceptor } from 'src/app/_helpers/error.interceptor';
import { routing } from 'src/app/app.routing';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatPaginatorModule } from '@angular/material/paginator';
import { UsersAddComponent } from './users/usersAdd.component';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { Korisnik } from './_models/korisnik';
import { DokumentVrstaComponent } from './Komunalno/SaldaKonti/Utuzivanje/DokumentVrsta/dokumentvrsta.component';
import { DokumentVrstaAddComponent } from './Komunalno/SaldaKonti/Utuzivanje/DokumentVrsta/dokumentvrstaAdd.component';
import { DokumentComponent } from './Komunalno/SaldaKonti/Utuzivanje/Dokument/dokument.component';
import { KontrolnaPlocaComponent } from './Komunalno/SaldaKonti/Utuzivanje/kontrolna-ploca/kontrolna-ploca.component';
import { ChartObvezniciComponent } from './Komunalno/SaldaKonti/Utuzivanje/chart-obveznici/chart-obveznici.component';
import { ChartDokumentiComponent } from './Komunalno/SaldaKonti/Utuzivanje/chart-dokumenti/chart-dokumenti.component';
import { ChartObvezniciDokumentiComponent } from './Komunalno/SaldaKonti/Utuzivanje/chart-obveznici-dokumenti/chart-obveznici-dokumenti.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { IspisUtuzivanjeComponent } from './Komunalno/SaldaKonti/Utuzivanje/ispis/ispis-utuzivanje.component';
import { StrukturaDugaComponent } from './Komunalno/SaldaKonti/Utuzivanje/struktura-duga/struktura-duga.component';
import { UserSettingsComponent } from './users/users-settings.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SaldaKorisnikComponent } from './Komunalno/SaldaKonti/Korisnik/salda-korisnik.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { KorisnikInfoComponent } from './Komunalno/Korisnik/korisnik-info.component';
import { DokumentTekstIspisComponent } from './Komunalno/SaldaKonti/Utuzivanje/DokumentTekstIspis/dokument-tekst-ispis.component';
import { DokumentTekstIspisUnosComponent } from './Komunalno/SaldaKonti/Utuzivanje/DokumentTekstIspis/dokument-tekst-ispis-unos.component';
import { OtvoreniRacuniComponent } from './Komunalno/SaldaKonti/otvoreni-racuni/otvoreni-racuni.component';
import { ZatvoreniRacuniComponent } from './Komunalno/SaldaKonti/zatvoreni-racuni/zatvoreni-racuni.component';
import { ChangeLogComponent } from './changelog/changelog.component';
import { ChangeLogUnosComponent } from './changelog/changelog-unos.component';
import { RocnostDugaComponent } from './Komunalno/SaldaKonti/Utuzivanje/rocnost-duga/rocnost-duga.component';
import { DokumentRacuniComponent } from './Komunalno/SaldaKonti/Utuzivanje/dokument-racuni/dokument-racuni.component';
import { UputeUtuzivanjeComponent } from './Komunalno/SaldaKonti/Utuzivanje/upute/upute-utuzivanje.component';
import { PocetnoStanjeComponent } from './Komunalno/SaldaKonti/pocetno-stanje/pocetno-stanje.component';
import { DataLogComponent } from './system/datalog/datalog.component';
import { PregledPrijedlogaComponent } from './Komunalno/SaldaKonti/Ovrhe/pregled-prijedloga/pregled-prijedloga.component';
import { PregledZahtjevaComponent } from './Komunalno/SaldaKonti/Ovrhe/pregled-zahtjeva/pregled-zahtjeva.component';
import { ObveznikZonaUlicaComponent } from './Komunalno/obveznik-zona-ulica/obveznik-zona-ulica.component';
import { KorisnikSelectComponent } from './Komunalno/Korisnik/korisnik-select.component';
import { PdvComponent } from './maticni/pdv/pdv.component';
import { PdvAddComponent } from './maticni/pdv/pdvAdd.component';
import { ApiTokenComponent } from './Komunalno/SaldaKonti/Ovrhe/api-token/api-token.component';
import { KreirajPrijedlogComponent } from './Komunalno/SaldaKonti/Ovrhe/kreiraj_prijedlog/kreiraj-prijedlog.component';
import { PosaljiNaSudComponent } from './Komunalno/SaldaKonti/Ovrhe/posalji-na-sud/posalji-na-sud.component';
import { ZatvaranjeRacunaComponent } from './Komunalno/SaldaKonti/zatvaranje-racuna/zatvaranje-racuna.component';
import { OtvoreniRacuniIDComponent } from './Komunalno/SaldaKonti/otvoreni-racuni-id/otvoreni-racuni-id.component';
import { ZatvoreniRacuniIDComponent } from './Komunalno/SaldaKonti/zatvoreni-racuni-id/zatvoreni-racuni-id.component';
import { RacunUplateComponent } from './Komunalno/SaldaKonti/racun-uplate/racun-uplate.component';
import { TroskoviPrometComponent } from './financije/bi/troskovi/troskovi-promet/troskovi-promet.component';
import { TroskoviKarticaComponent } from './financije/bi/troskovi/troskovi-kartica/troskovi-kartica.component';
import { SokopPrijedlogComponent } from './Komunalno/SaldaKonti/Sokop/sokop-prijedlog.component';

registerLocaleData(localeHr);

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        FetchDataComponent,
        LoginComponent,
        KlijentComponent,
        KlijentAddComponent,
        UsersComponent,
        UsersAddComponent,
        DialogComponent,
        FirmaComponent,
        FirmaAddComponent,
        RequestsComponent,
        RequestsAddComponent,
        LicenseComponent,
        LicenseEditComponent,
        VodomjerilaComponent,
        KorisnikComponent,
        StartComponent,
        KarticaComponent,
        DokumentVrstaComponent,
        DokumentVrstaAddComponent,
        DokumentComponent,
        NastavakComponent,
        ObustavakComponent,
        KontrolnaPlocaComponent,
        ChartObvezniciComponent,
        ChartDokumentiComponent,
        ChartObvezniciDokumentiComponent,
        IspisUtuzivanjeComponent,
        KarticaKomboComponent,
        StrukturaDugaComponent,
        UserSettingsComponent,
        MainMenuComponent,
        SaldaKorisnikComponent,
        KorisnikInfoComponent,
        DokumentTekstIspisComponent,
        DokumentTekstIspisUnosComponent,
        OtvoreniRacuniComponent,
        ZatvoreniRacuniComponent,
        ChangeLogComponent,
        ChangeLogUnosComponent,
        RocnostDugaComponent,
        DokumentRacuniComponent,
        UputeUtuzivanjeComponent,
        PocetnoStanjeComponent,
    DataLogComponent,
    PregledPrijedlogaComponent,
    ObveznikZonaUlicaComponent,
    KorisnikSelectComponent,
    PdvComponent,
    PdvAddComponent,
    PregledZahtjevaComponent,
    ApiTokenComponent,
    KreirajPrijedlogComponent,
    PosaljiNaSudComponent,
    ZatvaranjeRacunaComponent,
    OtvoreniRacuniIDComponent,
    ZatvoreniRacuniIDComponent,
    RacunUplateComponent,
    TroskoviPrometComponent,
    TroskoviKarticaComponent,
        SokopPrijedlogComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        routing,
        MatFormFieldModule,
        MatInputModule,
        BrowserAnimationsModule,
        MatCardModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDialogModule,
        MatTabsModule,
        MatSelectModule,
      MatDatepickerModule,        
        MatNativeDateModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatRadioModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro }],
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'hr-HR' },
      { provide: MAT_DATE_LOCALE, useValue: 'hr-HR' }  ],
    bootstrap: [AppComponent]
})
export class AppModule { }
