<div class="addressbar">
  <span class="addresstext">Sustav / Firme</span>
</div>
<div class="containerpadding">
  <div class="d-flex justify-content-center">
    <mat-card appearance="outlined" class="col-12 col-lg-5">
      <mat-card-title>{{poruka}} firme</mat-card-title>
      <hr />
      <form (ngSubmit)="addUser()">
        <mat-card-content>
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Osnovno">
              <div class="mt-3">
                <mat-form-field appearance="fill" class="col-lg-3 col-12">
                  <mat-label>Šifra firme</mat-label>
                  <input matInput placeholder="Šifra" [(ngModel)]="data.sifra" name="sifra">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-lg-6 col-12">
                  <mat-label>Država</mat-label>
                  <mat-select name="drzava" [(ngModel)]="data.drzava">
                    <mat-option [value]="1">Hrvatska</mat-option>
                    <mat-option [value]="2">Bosna i Hercegovina</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12">
                  <mat-label>Naziv firme</mat-label>
                  <input matInput placeholder="Naziv" [(ngModel)]="data.naziv" name="naziv">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-9">
                  <mat-label>Ulica</mat-label>
                  <input matInput placeholder="Ulica" [(ngModel)]="data.ulica" name="ulica">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-3">
                  <mat-label>Kućni broj</mat-label>
                  <input matInput placeholder="Kućni broj" [(ngModel)]="data.broj" name="broj">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-4">
                  <mat-label>Ptt</mat-label>
                  <input matInput placeholder="Ptt" [(ngModel)]="data.ptt" name="ptt">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-8">
                  <mat-label>Naselje</mat-label>
                  <input matInput placeholder="Naselje" [(ngModel)]="data.mjesto" name="mjesto">
                </mat-form-field>
                <mat-form-field *ngIf="data.drzava==1" appearance="fill" class="col-12 col-lg-6">
                  <mat-label>OIB</mat-label>
                  <input matInput placeholder="OIB" [(ngModel)]="data.oib" name="oib">
                </mat-form-field>
                <mat-form-field *ngIf="data.drzava==1" appearance="fill" class="col-12 col-lg-6">
                  <mat-label>IBAN</mat-label>
                  <input matInput placeholder="IBAN" [(ngModel)]="data.iban" name="iban">
                </mat-form-field>
                <mat-form-field *ngIf="data.drzava==2" appearance="fill" class="col-12 col-lg-6">
                  <mat-label>Žiro račun</mat-label>
                  <input matInput placeholder="Žiro račun" [(ngModel)]="data.ziroRacun" name="ziroracun">
                </mat-form-field><br />
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>Porezni broj</mat-label>
                  <input matInput placeholder="Porezni broj" [(ngModel)]="data.porezniBroj" name="poreznibroj">
                </mat-form-field>
                <mat-form-field *ngIf="data.drzava==2" appearance="fill" class="col-12 col-lg-6">
                  <mat-label>JIB</mat-label>
                  <input matInput placeholder="JIB" [(ngModel)]="data.jib" name="jib">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>Telefon</mat-label>
                  <input matInput placeholder="Telefon" [(ngModel)]="data.telefon" name="telefon">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>eMail</mat-label>
                  <input matInput placeholder="eMail" [(ngModel)]="data.eMail" name="email">
                </mat-form-field>
                
                <mat-form-field appearance="fill" class="col-12">
                  <textarea matInput placeholder="Baza komunalno" [(ngModel)]="data.bazaKomunalno" name="bazaKomunalno"></textarea>
                </mat-form-field>

              </div>
            </mat-tab>
            <mat-tab label="Ostalo">
              <div *ngIf="data.drzava==1" class="mt-3">
                <h4>eOvrhe</h4>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>eOvrhe API key</mat-label>
                  <input matInput placeholder="eOvrheAPIKey" [(ngModel)]="data.eOvrheAPIKey" name="eOvrheAPIKey">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>eOvrhe adresa</mat-label>
                  <input matInput placeholder="eOvrheAddress" [(ngModel)]="data.eOvrheAddress" name="eOvrheAddress">
                </mat-form-field>
              </div>
              <div *ngIf="data.drzava==2" class="mt-3">
                <h4>SOKOP</h4>
                <h5>Podaci o zastupniku</h5>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>Ime</mat-label>
                  <input matInput placeholder="Ime" [(ngModel)]="data.sokopIme" name="sokopIme">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>Prezime</mat-label>
                  <input matInput placeholder="Prezime" [(ngModel)]="data.sokopPrezime" name="sokopPrezime">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>Funkcija</mat-label>
                  <input matInput placeholder="Prezime" [(ngModel)]="data.sokopFunkcija" name="sokopFunkcija">
                </mat-form-field>
                <mat-form-field appearance="fill" class="col-12 col-lg-6">
                  <mat-label>Ulica</mat-label>
                  <input matInput placeholder="Ulica" [(ngModel)]="data.sokopUlica" name="sokopUlica">
                </mat-form-field>
                <h5>Podaci za prijedlog</h5>
                <mat-form-field *ngIf="listasudova" appearance="fill" class="col-12">
                  <mat-label>Sud na koji se šalje prijedlog</mat-label>
                  <mat-select ngDefaultControl [(ngModel)]="data.sokopSud" name="sokopSud">
                    <mat-option *ngFor="let dokument of listasudova" [value]="dokument.sifra">
                      {{dokument.sifra}} {{dokument.naziv}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="listabanaka" appearance="fill" class="col-12">
                  <mat-label>Banka firme</mat-label>
                  <mat-select ngDefaultControl [(ngModel)]="data.sokopBanka" name="sokopBanka">
                    <mat-option *ngFor="let dokument of listabanaka" [value]="dokument.sifra">
                      {{dokument.sifra}} {{dokument.naziv}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-tab>
              <mat-tab label="Moduli">
                <div class="mt-3">
                  <h4>Moduli</h4>
                  <p>
                    <mat-checkbox [(ngModel)]="data.modulKomunalno" name="modulKomunalno">Komunalno</mat-checkbox><br />
                    <mat-checkbox [(ngModel)]="data.modulFinancije" name="modulFinancije">Financije</mat-checkbox><br />
                    <mat-checkbox [(ngModel)]="data.modulBI" name="modulBi">Poslovno izvješćivanje BI</mat-checkbox><br />
                    <mat-checkbox [(ngModel)]="data.modulFakturno" name="modulFakturno">Fakturno</mat-checkbox><br />
                    <mat-checkbox [(ngModel)]="data.modulPlace" name="modulPlace">Place</mat-checkbox><br />
                    <mat-checkbox [(ngModel)]="data.modulOsnovna" name="modulOsnovna">Osnovna sredstva</mat-checkbox><br />
                    <mat-checkbox [(ngModel)]="data.modulMaterijalno" name="modulMaterijalno">Materijalno</mat-checkbox><br />
                  </p>
                  <h4>Opcije</h4>
                  <p>
                    <mat-checkbox [(ngModel)]="data.vrstaUsluge" name="vrstaUsluge">Upotreba vrste usluge</mat-checkbox><br />
                    <mat-checkbox [(ngModel)]="data.konto" name="konto">Upotreba konta u salda-kontima</mat-checkbox>
                  </p>
                </div>
              </mat-tab>

          </mat-tab-group>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-center">
          <button mat-button type="submit" class="ok-color"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon> Snimi</button>
          <button mat-button (click)="goBack()" type="button" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon> Odustani</button>
        </mat-card-actions>

        <div class="form-group">
          <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
      </form>
      <p>
        <label [ngClass]="status == 'Uspješno dodan' ? 'badge badge-success': 'badge badge-danger'">{{status}}</label>
      </p>

    </mat-card>
  </div>
  </div>
