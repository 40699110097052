<div class="addressbar">
  <span class="addresstext">Podrška / Zahtjevi</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Zahtjevi
    </mat-card-title>

    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />

    <mat-card-actions>
    <button routerLink="add" mat-stroked-button class="add-color"><mat-icon aria-hidden="false" aria-label="Dodavanje novog zahtjeva">add</mat-icon> Novi</button>&nbsp;
    <button (click)="EditData()" mat-stroked-button class="edit-color"><mat-icon aria-hidden="false" aria-label="Ispravak">edit</mat-icon> Ispravak</button>&nbsp;
    <button (click)="openDialog()" mat-stroked-button class="delete-color"><mat-icon aria-hidden="false" aria-label="Brisanje">delete</mat-icon> Briši</button><br />
      </mat-card-actions>
    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Korisnik </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum </th>
          <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd.MM.yyyy hh:mm:ss'}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Opis </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>

        <ng-container matColumnDef="level">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Važnost </th>
          <td mat-cell *matCellDef="let element"> {{element.level}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="clientname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Klijent </th>
          <td mat-cell *matCellDef="let element"> {{element.clientname}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" [ngClass]="{highlight: selectedrow.id === row.id}" (click)="highlight(row)"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[ 10, 20,50]" showFirstLastButtons></mat-paginator>
      </div>
  </mat-card>
  <hr />
  </div>
