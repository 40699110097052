import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { eOvrheAPI } from "src/app/_models/eOvrhe/eOvrheAPI";


@Component({
  selector: 'api-token',
  templateUrl: './api-token.component.html'
})
export class ApiTokenComponent implements OnInit {
  getservice: httpservice;
  loading = false;
  submitted = false;
  api: eOvrheAPI = {eOvrheApiKey:"",eOvrheKontakt:"",eOvrhePoslovnaJedinica:""};
  returnUrl: string;
  error = '';

  constructor(dataservice: httpservice, private router: Router, private route: ActivatedRoute, private location: Location,private snackBar: MatSnackBar) {
    this.getservice = dataservice;
  }
  ngOnInit() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/ovrhe/GetAPIKey").subscribe(result => {
      if (result.status === "success") {
        this.api = result.data;
      }
    }, error => console.error('Get API Key', error));
  }

  saveAPIKey() {
    this.submitted = true;
    this.loading = true;
    this.getservice.PostRequest<Message>("komunalno/saldakonti/ovrhe/SaveAPIKey", this.api).subscribe(result => {
      if (result.status === "success") {
        this.Poruka("Uspješno snimljeni podaci.", "Uredu");
        this.router.navigateByUrl("/home");
      }
      else {
        this.Poruka("Greška prilikom snimanja podataka", "Uredu");
        console.error('Log error', result);
      }
      this.loading = false;
    }, error => {
      console.error('Log error', error);
      this.loading = false;
    }
    );
  }

  Poruka(message: string, action: string) {
    this.snackBar.open(message, action);
  }
}
