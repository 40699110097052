<form>  
  <mat-form-field *ngIf="listafirmi" appearance="fill" class="fullwidth">
    <mat-label>Aktivna firma</mat-label>
    <mat-select ngDefaultControl name="firma" [(ngModel)]="data.aktivnaFirma" (selectionChange)="getGodine()">
      <mat-option *ngFor="let firma of listafirmi" [value]="firma.id" [selected]="data.aktivnaFirma==firma.id">
        {{firma.naziv}} - {{firma.mjesto}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="listagodina" appearance="fill" class="fullwidth">
    <mat-label>Aktivna godina</mat-label>
    <mat-select ngDefaultControl name="godina" [(ngModel)]="data.aktivnaGodina">
      <mat-option *ngFor="let godina of listagodina" [value]="godina.godina" [selected]="data.aktivnaGodina==godina.godina">
        {{godina.godina}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-button (click)="onOkClick()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon> Snimi</button>
  <button mat-button (click)="close()" type="button" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon> Odustani</button>
  <p *ngIf="error">
    Dogodila se greška prilikom simanja: {{error}}
  </p>
</form>
