
<nav class="bg-light">
  <div *ngIf="login" class="navbar navbar-light sticky-top border-bottom-0" >   
    <div class="navbar-nav">
      {{firmapodaci.naziv}} {{login.aktivnaGodina}}
    </div>
    <div *ngIf="login">
      <button mat-button [matMenuTriggerFor]="menu"><mat-icon aria-hidden="true" aria-label="Korisnik">account_circle</mat-icon> {{login.username}}</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openUserSettings()" class="ok-color">
          <mat-icon>settings</mat-icon>
          <span> Postavke</span>
        </button>
        <button mat-menu-item (click)="logout()" class="cancel-color">
          <mat-icon>logout</mat-icon>
          <span> Odjava</span>
        </button>
      </mat-menu>
      
    </div>
  </div>  
</nav>

  

