import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit } from '@angular/core';
import { User } from '../_models/user';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { firme } from '../commons/commons.share';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'users-settings',
  templateUrl: './users-settings.component.html'
})

export class UserSettingsComponent implements OnInit {
  getservice: httpservice;
  listafirmi: firme[];
  listagodina: any;
  error: any;
  loginService: LoginService;

  constructor(dataservice: httpservice,loginservice: LoginService, public dialogRef: MatDialogRef<UserSettingsComponent>, @Inject(MAT_DIALOG_DATA) public data: User) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.getFirme();
    this.getGodine();
  }



  getFirme() {
    this.getservice.GetRequest<Message>("User/Firme?id="+this.data.id).subscribe(result => {
      if (result.status === "success") {        
        this.listafirmi = Object.values(result.data);        
      }
    }, error => console.error('Get firme', error));
  }
  getGodine() {
    this.getPostavkeFirme(this.data.aktivnaFirma);
    this.getservice.GetRequest<Message>("Firma/Godine?id="+this.data.aktivnaFirma).subscribe(result => {
      if (result.status === "success") {        
        this.listagodina = Object.values(result.data);        
      }
    }, error => console.error('Get godine', error));
    
  }

  getPostavkeFirme(id) {
    this.getservice.GetRequest<Message>("Firma/get?id=" + id).subscribe(result => {
      if (result.status === "success") {
        this.data.konto = result.data.konto;
        this.data.vrstaUsluge = result.data.vrstaUsluge;
      }
    }, error => console.error('Get firma', error));
  }


  onOkClick(): void {
    this.getservice.PostRequest<Message>("User/Postavke", this.data).subscribe(result => {
      if (result.status === "success") {
        this.loginService.currentUserSetValue = this.data;
        this.dialogRef.close();
      }
      else {
        this.error = result.data;
        console.error('Log error', result);
      }
    }, error => console.error('Log error', error));
    
  }

  close(): void {
    this.dialogRef.close();
  }

}
