
  <div class="col-12 col-lg-10">
    <hr />
    <p>
      {{poruka}} teksta za ispis dokumenta
    </p>
    <form (ngSubmit)="addClient()">
      <mat-form-field appearance="fill" class="col-lg-3 col-12">
        <mat-label>Verzija</mat-label>
        <input matInput placeholder="Verzija" [(ngModel)]="data.version" name="version" maxlength="10">
      </mat-form-field>&nbsp;
      <mat-form-field appearance="fill" class="col-lg-6 col-12">
        <mat-label>Datum</mat-label>
        <input matInput type="date" placeholder="Datum" [ngModel]="data.date | date:'yyyy-MM-dd'"  (ngModelChange)="data.date=$event" name="date">        
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Opis</mat-label>
        <textarea matInput cdkTextareaAutosize placeholder="Opis" [(ngModel)]="data.description" name="description"></textarea>        
      </mat-form-field>
      <button mat-button type="submit"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon></button>
      <button mat-button (click)="goBack()" type="button"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon></button>
      <div class="form-group">
        <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </div>
      <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    </form>
    <hr />
  </div>
