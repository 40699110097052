import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';
import { httpservice, Message } from 'src/app/commons/commons.module';




@Component({
  selector: 'chart-obveznici',
  templateUrl: './chart-obveznici.component.html'
  
})

export class ChartObvezniciComponent implements OnInit {
  getservice: httpservice;
  chartOption: any;
  podaci: { value, name }[];
  obveznici:any;
  ukupno: number;

  constructor(dataservice: httpservice) {
    this.getservice = dataservice;
  }

  ngOnInit() {
    this.getservice.GetRequest<Message>("komunalno/korisnik/CountGroupByObveznik").subscribe(result => {
      if (result.status === "success") {
        this.podaci=[,];
        this.obveznici = result.data;
        this.ukupno = 0;
        result.data.forEach(e => {
          this.podaci.push({ value: e.broj, name: e.obveznik });
          this.ukupno +=e.broj;
        });
        this.chartOption= {
          title: {
            text: 'Broj korisnika',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              name: 'Vrsta obveznika',
              type: 'pie',
              radius: '50%',
              data:this.podaci,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
        };                
      }
    }, error => console.error('Get obveznike', error));
  }
}

