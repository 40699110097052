<div class="addressbar">
  <span class="addresstext">Sustav / Korisnici</span>
</div>
<div class="containerpadding">
  <div class="d-flex justify-content-center">
    <mat-card appearance="outlined" class="col-12 col-lg-5">
      <mat-card-title>{{poruka}} korisnika</mat-card-title>

      <form (ngSubmit)="addUser()">
        <mat-card-content>
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Osnovno">
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Ime</mat-label>
                <input matInput placeholder="Ime" [(ngModel)]="data.firstName" name="firstName" required>
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Prezime</mat-label>
                <input matInput placeholder="Prezime" [(ngModel)]="data.lastName" name="lastName">
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Korisničko ime</mat-label>
                <input matInput placeholder="Korisničko ime" [(ngModel)]="data.username" name="username" required>
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Lozinka</mat-label>
                <input matInput placeholder="Lozinka" type="password" [(ngModel)]="data.password" name="password" required>
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>eMail</mat-label>
                <input matInput placeholder="eMail" [(ngModel)]="data.mail" name="mail">
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Aktivna godina</mat-label>
                <input matInput placeholder="Aktivna godina" [(ngModel)]="data.aktivnaGodina" name="aktivnaGodina">
              </mat-form-field><br />
            </mat-tab>
            <mat-tab label="Klijenti">

              <!--<mat-selection-list #klijenti [(ngModel)]="odabraniKlijenti" (selectionChange)="getFirme()" [ngModelOptions]="{standalone: true}" class="h-75">
                <mat-list-option *ngFor="let klijent of listaklijenata" [value]="klijent.id" [selected]="klijent.selected">
                  {{klijent.id}} {{klijent.naziv}}
                </mat-list-option>
              </mat-selection-list>-->

              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Odabrani klijenti</mat-label>
                <mat-select multiple [(ngModel)]="odabraniKlijenti" (selectionChange)="getFirme()">
                  <mat-option *ngFor="let klijent of listaklijenata" [value]="klijent">
                    {{klijent.id}} {{klijent.naziv}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p>
                <!--Izabrano klijenata: {{klijenti.selectedOptions.selected.length}}-->
                Izabrano klijenata: {{odabraniKlijenti.length}}                 
              </p>               
              <hr />               
              <mat-form-field *ngIf="listafirmi" appearance="fill" class="fullwidth">
                <mat-label>Aktivna firma</mat-label>
                <mat-select ngDefaultControl name="firma" [(ngModel)]="data.aktivnaFirma">
                  <mat-option *ngFor="let firma of listafirmi" [value]="firma.id" [selected]="data.aktivnaFirma==firma.id">
                    {{firma.naziv}} {{firma.mjesto}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </mat-tab>
            <mat-tab label="Ovlasti">
            </mat-tab>

          </mat-tab-group>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-center">
          <button mat-button type="submit" class="ok-color"><mat-icon aria-hidden="false" aria-label="Dodaj korisnika">done</mat-icon> Snimi</button>
          <button mat-button (click)="goBack()" type="button" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon> Odustani</button>
        </mat-card-actions>

        <div class="form-group">
          <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
      </form>
      <p>
        <label [ngClass]="status == 'Uspješno dodan' ? 'badge badge-success': 'badge badge-danger'">{{status}}</label>
      </p>

    </mat-card>
  </div>
  </div>
