import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';

@Component({
  selector: 'racun-uplate',
  templateUrl: 'racun-uplate.component.html',
  styleUrls: ['racun-uplate.component.css']
})

export class RacunUplateComponent implements OnInit {
  getservice: httpservice;
  @Input() racunid?: "";
  displayedColumns: string[] = ['datum', 'isprava', 'redniBroj','pozicija', 'duguje','potrazuje','iznos'];
  lista: any;
  duguje: number;
  potrazuje: number;
  saldo: number;
  
  constructor(dataservice: httpservice, @Inject(LOCALE_ID) private locale: string)
  {
    this.getservice = dataservice;
   
  }

  ngOnInit() {
    
    this.duguje = 0;
    this.potrazuje = 0;
    this.saldo = 0;
    this.UcitajUplate();    
  }


  UcitajUplate() {
    try {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/kartica/UplatePoRacunu?racunid=" + this.racunid).subscribe(result => {
        if (result.status === "success") {
          this.lista = result.data;
          this.duguje = 0;
          this.potrazuje = 0;
          this.saldo = 0;
          this.lista.forEach((element) => {
            this.duguje += element.duguje;
            this.potrazuje += element.potrazuje;
            this.saldo += element.iznos;
          })
        }
      }, error => console.error('Get uplate po računu', error));
    }
    catch (error) {
      console.error('Log error', error);
    }
  }  
}



