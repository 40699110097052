import { httpservice, Message } from 'src/app/commons/commons.module';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import { icon, Marker } from 'leaflet';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});


@Component({
  selector: 'vodomjerila',
  templateUrl: './vodomjerila.component.html',
  styleUrls: ['./vodomjerila.component.css']
})
export class VodomjerilaComponent implements OnInit, AfterViewInit {
  getservice: httpservice;
  lista : any;  
  mymap: any;
  recno: "100";

  constructor(dataservice: httpservice, public dialog: MatDialog, private router: Router) {
    this.getservice = dataservice;
    this.recno = "100";
  }

  ngAfterViewInit() {
    this.InitializeMap();
  }

  InitializeMap() {
    if (this.mymap) {
      //to remove any initialization of a previous map
      this.mymap.off();
      this.mymap.remove();
    }
    this.mymap = L.map('mapid').setView([43.85870471821483, 16.156836741418587], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { foo: 'bar', attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' }).addTo(this.mymap);
    //c.tile.opentopomap.org / { z } / { x } / { y }.png
  }

  ngOnInit() {
    this.Ucitaj();
  }

  Ucitaj() {
    try {
      this.getservice.GetRequest<Message>("komunalno/vodomjerila/List?recno="+this.recno).subscribe(result => {
        if (result.status === "success") {
          this.lista = result.data;
          Marker.prototype.options.icon = iconDefault;
          for (var i = 0; i < this.lista.length; i++) {
            
            var marker = L.marker([this.lista[i].x, this.lista[i].y]).addTo(this.mymap);
            marker.bindPopup("<b>Vodomjer:</b>" + this.lista[i].sifra).openPopup();
            
          }
        }
      }, error => console.error('Get zahtjev', error));
    }
    catch (error) {
      console.error('Log error', error);
    }
  }

  RefreshData() {
    this.lista = null;
    this.InitializeMap();
    this.Ucitaj();
  }

  }
  

  



