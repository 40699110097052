<div class="addressbar">
  <span class="addresstext">Podrška / Zahtjevi</span>
</div>
<div class="containerpadding">
  <div class="d-flex justify-content-center">
    <mat-card appearance="outlined" class="col-12">
      <mat-card-title>{{poruka}} zahtjeva</mat-card-title>

      <form (ngSubmit)="addRequest()">
        <mat-card-content>
          <mat-form-field appearance="fill" class="col-12 col-lg-6">
            <mat-label>Datum</mat-label>
            <input matInput type="datetime-local"  placeholder="Odaberite datum" [ngModel]="data.date | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="data.date=$event" name="date">            
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-12">
            <mat-label>Opis</mat-label>
            <textarea matInput placeholder="Opis" [(ngModel)]="data.description" name="description"></textarea>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-12 col-lg-6">
            <mat-label>Važnost</mat-label>
            <mat-select [(ngModel)]="data.level" name="level">
              <mat-option value="Niska">Niska</mat-option>
              <mat-option value="Srednja">Srednja</mat-option>
              <mat-option value="Velika">Velika</mat-option>
              <mat-option value="Hitno">Hitno</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="superuser" appearance="fill" class="col-12 col-lg-6">
            <mat-label>Klijent</mat-label>
            <mat-select [(ngModel)]="data.clientid" name="clientid">
              <mat-option *ngFor="let client of clients" [value]="client.id">
                {{client.naziv}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="superuser" appearance="fill" class="col-12">
            <mat-label>Riješenje</mat-label>
            <textarea matInput placeholder="Riješenje" [(ngModel)]="data.solution" name="solution"></textarea>
          </mat-form-field>
          <mat-form-field *ngIf="superuser" appearance="fill" class="col-12 col-lg-6">
            <mat-label>Datum zatvaranja</mat-label>
            <input matInput type="datetime-local" placeholder="Odaberite datum zatvaranja" [ngModel]="data.endDate | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="data.endDate=$event" name="endDate">
          </mat-form-field>
          <mat-form-field *ngIf="superuser" appearance="fill" class="col-12 col-lg-6">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="data.status" name="status">
              <mat-option value="Zaprimljen">Zaprimljen</mat-option>
              <mat-option value="U obradi">U obradi</mat-option>
              <mat-option value="Zatvoren">Zatvoren</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions class="d-flex justify-content-center">
          <button mat-button type="submit" class="ok-color"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon> Snimi</button>
          <button mat-button (click)="goBack()" type="button" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon> Odustani</button>
        </mat-card-actions>

        <div class="form-group">
          <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
      </form>
      <p>
        <label [ngClass]="status == 'Uspješno dodan' ? 'badge badge-success': 'badge badge-danger'">{{status}}</label>
      </p>

    </mat-card>
  </div>
  </div>
