import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PravniDokumentTekstIspis } from 'src/app/_models/Utuzivanje/pravnidokumenttekstispis';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';
import { DokumentVrstaAddComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/DokumentVrsta/dokumentvrstaAdd.component';

@Component({
  selector: 'dokument-tekst-ispis',
  templateUrl: './dokument-tekst-ispis.component.html'  
})
export class DokumentTekstIspisComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['sifra','naziv'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  lista: any;
  showAdd: boolean;
  currentItem: 0;


  constructor(dataservice: httpservice, public dialog: MatDialog, private router: Router) {
    this.getservice = dataservice;
    
  }
  ngOnInit() {

    this.showAdd = false;
    try {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/dokumenttekstispis/List").subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource<PravniDokumentTekstIspis>(Object.values(result.data));
          this.lista.paginator = this.paginator;
          setTimeout(() => this.lista.sort = this.sort, 2000);
          this.paginator._intl = new MatPaginatorIntlCro();
          if (this.lista.data.length > 0) {
            this.selectedrow = this.lista.data[0];
          }
        }
      }, error => console.error('Get dokument tekst ispis',error));
    }
    catch (error) {
      console.error('Log error', error);
    }   
  }
  

  highlight(row) {
    this.selectedrow = row;
  }

  add() {
    this.currentItem = 0;
    this.showAdd = true;
  }

  addItem(newItem: PravniDokumentTekstIspis) {
    this.showAdd = false;
    if (newItem.pravniDokumentTekstIspisId > 0) {
      var ind = this.lista.data.findIndex(function checkUser(user) {
        return user.pravniDokumentTekstIspisId == newItem.pravniDokumentTekstIspisId;
      }, this);
      if (ind > -1) {
        this.lista.data[ind] = newItem;
      }
      else {
        this.lista.data.push(newItem);
      }
      this.selectedrow = newItem;
      this.lista._updateChangeSubscription();
    }
  }

  openDialog() {
    if (this.selectedrow) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Brisanje teksta dokumenta ' + this.selectedrow.naziv
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.DeleteData();
        }
      });
    }
  }
  

  DeleteData() {
    var ind = this.lista.data.findIndex(function checkUser(user) {
      return user.pravniDokumentTekstIspisId == this.selectedrow.pravniDokumentTekstIspisId;
    },this);
    if (ind > -1) {      
      this.getservice.PostRequest<Message>("komunalno/saldakonti/dokumenttekstispis/Remove", this.selectedrow.pravniDokumentTekstIspisId).subscribe(result => {
        if (result.status === "success") {
          this.lista.data.splice(ind, 1);
          this.lista._updateChangeSubscription();
          this.selectedrow = null;
        }
      });
    }
  }

  

  EditData() {
    if (this.selectedrow) {      
      this.currentItem = this.selectedrow.pravniDokumentTekstIspisId;
      this.showAdd = true;
      //this.router.navigate(['/komunalno/saldakonti/utuzivanje/dokument/add', this.selectedrow.pravniDokumentVrstaId]);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }  
}


