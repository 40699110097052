
  <div class="input-border col-12 col-lg-10">
    <p>
      {{poruka}} teksta za ispis dokumenta
    </p>
    <form (ngSubmit)="addClient()">
      <mat-form-field appearance="fill" class="col-lg-3 col-12">
        <mat-label>Šifra</mat-label>
        <input matInput placeholder="Šifra" [(ngModel)]="data.sifra" name="sifra" maxlength="2">
      </mat-form-field>&nbsp;
      <mat-form-field appearance="fill" class="col-lg-6 col-12">
        <mat-label>Naziv</mat-label>
        <input matInput placeholder="Naziv" [(ngModel)]="data.naziv" name="naziv" maxlength="100">        
      </mat-form-field>
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Sadržaj</mat-label>
        <textarea matInput cdkTextareaAutosize placeholder="Sadržaj" [(ngModel)]="data.sadrzaj" name="sadrzaj"></textarea>
        <mat-hint>
          Specijalna polja:
          [Broj] - broj dokumenta,
          [Datum] - datum dokumenta,
          [Iznos] - iznos duga,
          [BrojDana] - broj dana za plaćanje
          [Pravni subjekt] - podaci o korisniku
          [Period] - period od 12 mjeseci od datuma dokumenta
        </mat-hint>
      </mat-form-field>
      <button mat-button type="submit"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon></button>
      <button mat-button (click)="goBack()" type="button"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon></button>
      <div class="form-group">
        <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </div>
      <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    </form>   
  </div>
