import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { Pdv } from 'src/app/_models/Maticni/pdv';


@Component({
  selector: 'pdvAdd',
  templateUrl: './pdvAdd.component.html'
})
export class PdvAddComponent implements OnInit {
  getservice: httpservice;
  data: Pdv = {pdvId:0,sifra:"",naziv:"",postotak:0};
  loading = false;
  submitted = false;
  status: string = "";
  returnUrl: string;
  error = '';
  editing = false;
  poruka = "Dodavanje";
  @Input() item: 0;
  @Output() newItemEvent = new EventEmitter<Pdv>();  

  constructor(dataservice: httpservice, private router: Router, private route: ActivatedRoute, private location: Location) {
    this.getservice = dataservice;       
  }
  ngOnInit() {
    if (this.item > 0) {
      this.getClient(this.item.toString());
    }
    else {
      var idkor = this.route.snapshot.paramMap.get('id');
      if (idkor != null) {
        this.getClient(idkor);
      }
      else {
        idkor = "0";
      }
    }
  }

 


  

  addClient() {
    this.submitted = true;
    this.loading = true;
    
      if (!this.editing) {
        this.getservice.PostRequest<Message>("maticni/pdv/add", this.data).subscribe(result => {
          if (result.status === "success")
          {
            this.data.pdvId = result.data;
            this.newItemEvent.emit(this.data);            
          }
          else {
            this.loading = false;
            this.error = result.data;
            console.error('Log error', result);
          }
        },
          error => {
            this.loading = false;
            console.error('Log error', error);
          });
      }
      else {
        this.getservice.PostRequest<Message>("maticni/pdv/edit", this.data).subscribe(result => {
          if (result.status === "success") {
            this.newItemEvent.emit(this.data);            
          }
          else {
            this.loading = false;
            console.error('Log error', result);
          }
        }, error => console.error('Log error',error));
      }        
  }


  goBack() {
    //this.location.back();
    this.data.pdvId = 0;
    this.newItemEvent.emit(this.data);
  }   

  getClient(id: string) {
    this.getservice.GetRequest<Message>("maticni/pdv/Get?id=" + id).subscribe(result => {
      if (result.status === "success") {
        this.editing = true;
        this.poruka = "Ispravak";
        this.data = result.data;
      }
    });
  }

}
