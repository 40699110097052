<div class="addressbar">
  <span class="addresstext">Komunalno / Salda-konti / Korisnik</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-form-field class="fullwidth" appearance="fill">
      <mat-label>Korisnik</mat-label>
      <input matInput (ngModelChange)="onchangeKorisnik($event)"
             aria-label="Korisnik"
             [matAutocomplete]="auto"
             [formControl]="korisnikCtrl">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let korisnik of listakorisnika" [(value)]="korisnik.sifra">
          <span>{{korisnik.sifra}}</span>
          <span>{{korisnik.ime}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mat-card>
  <korisnik-info *ngIf="qkorisnik" [korisnik]="qkorisnik"></korisnik-info>
  <mat-tab-group mat-align-tabs="start">
    <mat-tab label="Analitička kartica">
      <kartica *ngIf="qkorisnik" [korisnik]="qkorisnik"></kartica>
    </mat-tab>
    <mat-tab label="Analitička kartica - kombinirana">
      <kartica-kombo *ngIf="qkorisnik" [korisnik]="qkorisnik"></kartica-kombo>
    </mat-tab>
    <mat-tab label="Računi" *ngIf="!login.vrstaUsluge">
      <otvoreni-racuni *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="''"></otvoreni-racuni>
      <zatvoreni-racuni *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="''"></zatvoreni-racuni>
    </mat-tab>
    <mat-tab label="Računi voda" *ngIf="login.vrstaUsluge">
      <otvoreni-racuni *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'V'"></otvoreni-racuni>
      <zatvoreni-racuni *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'V'"></zatvoreni-racuni>
    </mat-tab>
    <mat-tab label="Računi čistoća" *ngIf="login.vrstaUsluge">
      <otvoreni-racuni *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'C'"></otvoreni-racuni>
      <zatvoreni-racuni *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'C'"></zatvoreni-racuni>
    </mat-tab>
    <mat-tab label="Računi automatika" *ngIf="!login.vrstaUsluge">
      <otvoreni-racuni-id *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="''"></otvoreni-racuni-id>
      <zatvoreni-racuni-id *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="''"></zatvoreni-racuni-id>
    </mat-tab>
    <mat-tab label="Računi voda automatika" *ngIf="login.vrstaUsluge">
      <otvoreni-racuni-id *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'V'"></otvoreni-racuni-id>
      <zatvoreni-racuni-id *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'V'"></zatvoreni-racuni-id>
    </mat-tab>
    <mat-tab label="Računi čistoća automatika" *ngIf="login.vrstaUsluge">
      <otvoreni-racuni-id *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'C'"></otvoreni-racuni-id>
      <zatvoreni-racuni-id *ngIf="qkorisnik" [korisnik]="qkorisnik" [vrstausluge]="'C'"></zatvoreni-racuni-id>
    </mat-tab>

  </mat-tab-group>      
</div>
