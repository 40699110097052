import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit } from '@angular/core';
import { Firma } from 'src/app/_models/firma';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'firmaAdd',
  templateUrl: './firmaAdd.component.html'
})
export class FirmaAddComponent implements OnInit {
  getservice: httpservice;
  data: Firma = { sifra: "", naziv: "", ulica: "", broj: "", mjesto: "", id: 0, oib: "", iban: "", bazaKomunalno: "", drzava: 1, eMail: "", jib: "", porezniBroj: "", telefon: "", ziroRacun: "", ptt: "", eOvrheAPIKey: "", konto: false, vrstaUsluge: false, eOvrheAddress: "", modulBI: false, modulFakturno: false, modulFinancije: false, modulGdpr: false, modulKomunalno: false, modulMaterijalno: false, modulOsnovna: false, modulPlace: false, sokopBanka: "", sokopFunkcija: "", sokopIme: "", sokopPrezime: "", sokopSud: "", sokopUlica: "" };
  loading = false;
  submitted = false;
  status: string = "";
  returnUrl: string;
  error = '';
  editing = false;
  poruka = "Dodavanje";
  listasudova: any;
  listabanaka: any;

  constructor(dataservice: httpservice, private router: Router, private route: ActivatedRoute, private location: Location) {
    this.getservice = dataservice;       
  }
  ngOnInit() {
    var idkor = this.route.snapshot.paramMap.get('id');
    if (idkor != null) {
      this.getUser(idkor);
    }
    this.UcitajSudove();
    this.UcitajBanke();
  }


  UcitajSudove() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/sokop/sudovi").subscribe(result => {
      if (result.status === "success") {
        this.listasudova = Object.values(result.data);
      }
    }, error => console.error('Get dokumente', error));
  }

  UcitajBanke() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/sokop/banke").subscribe(result => {
      if (result.status === "success") {
        this.listabanaka = Object.values(result.data);
      }
    }, error => console.error('Get dokumente', error));
  }
  

  addUser() {
    this.submitted = true;
    this.loading = true;
    
      if (!this.editing) {
        this.getservice.PostRequest<Message>("Firma/add", this.data).subscribe(result => {
          if (result.status === "success")
          {
            this.router.navigate(['/firma']);
          }
          else {
            console.error('Log error', result);
          }
        },
          error => console.error('Log error', error));
      }
      else {
        this.getservice.PostRequest<Message>("firma/edit", this.data).subscribe(result => {
          if (result.status === "success") {
            this.router.navigate(['/firma']);
          }
          else {
            console.error('Log error', result);
          }
        }, error => console.error('Log error',error));
      }
    
    
  }

  goBack() {
    this.location.back();
  }   

  getUser(id: string) {
    this.getservice.GetRequest<Message>("firma/Get?id=" + id).subscribe(result => {
      if (result.status === "success") {
        this.editing = true;
        this.poruka = "Ispravak";
        this.data = result.data;
      }
    });
  }

}
