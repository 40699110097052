import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  connection: signalR.HubConnection;
  loginService: LoginService;
  login: User;
  message: BehaviorSubject<string>;
  messageEnd: BehaviorSubject<string>;
  count: BehaviorSubject<number>;
  current: BehaviorSubject<number>;
  cancelreqest: BehaviorSubject<string>;
  status: BehaviorSubject<string>;

  constructor(private loginservice: LoginService) {
    this.loginService = loginservice;
    this.loginService.currentUser.subscribe(data => {
      this.login = data;      
    });
    this.message = new BehaviorSubject<string>(null);
    this.messageEnd = new BehaviorSubject<string>(null);
    this.count = new BehaviorSubject<number>(null);
    this.current = new BehaviorSubject<number>(null);
    this.cancelreqest = new BehaviorSubject<string>(null);
    this.status = new BehaviorSubject<string>(null);
  }


  public initiateSignalrConnection(api: string): Promise<void> {
    if (this.login.token != "") {
      return new Promise((resolve, reject) => {
        this.connection = new signalR.HubConnectionBuilder()
          .withUrl("https://api.matsys.hr/" + api, { accessTokenFactory: () => this.login.token }) // the SignalR server url
          .build();
        this.setSignalrClientMethods();
        this.connection
          .start()
          .then(() => {
            console.log('SignalR connection success! connectionId:' + this.connection.connectionId);
            resolve();
          })
          .catch((error) => {
            console.log('SignalR connection error: ${error}');
            reject();
          });
      });
    }
    else {
      console.log('SignalR connection error: Nema tokena');
    }
  }

  private setSignalrClientMethods(): void {
    this.connection.on('PocetnoStart', (message: string) => {
      this.message.next(message);
    });

    this.connection.on('PocetnoProgress', (count: number,current:number,status:string,cancelreqest:string) => {
      this.count.next(count);
      this.current.next(current);
      this.cancelreqest.next(cancelreqest);
      this.status.next(status);
    });

    this.connection.on('PocetnoEnd', (message: string) => {
      this.messageEnd.next(message);
      console.log('Dobijena poruka prekida');
    });
  }

}
