<mat-card appearance="outlined">
  <div echarts [options]="chartOption" class="chart"></div>
</mat-card>
<mat-card appearance="outlined" class="mt-2">
  <table class="col-12">
    <thead>
      <tr class="table-header">
        <td>Vrsta obveznika</td>
        <td>Broj</td>
      </tr>
    </thead>
    <tr *ngFor="let podatak of obveznici" class="table-row">
      <td>{{podatak.obveznik}}</td>
      <td class="text-right">{{podatak.broj | number: '1.0-0':'hr'}}</td>
    </tr>
    <tr class="table-footer">
      <td>Ukupno</td>
      <td class="text-right">{{ukupno | number: '1.0-0':'hr'}}</td>
    </tr>
  </table>
  </mat-card>
