<div class="p-1">
  <mat-card appearance="outlined">
    <mat-card-title>
      Računi dokumenta
    </mat-card-title>
    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

        <ng-container matColumnDef="datum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Datum</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.datum | date: 'dd.MM.yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef> Ukupno </td>
        </ng-container>

        <ng-container matColumnDef="racun">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Broj računa</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.racun}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="valuta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Valuta računa</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.valuta | date: 'dd.MM.yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="iznos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Iznos</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.iznos | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{iznos | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
      </div>
  </mat-card>
</div>


