import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';




@Component({
  selector: 'chart-obveznici-dokumenti',
  templateUrl: './chart-obveznici-dokumenti.component.html'
})

export class ChartObvezniciDokumentiComponent implements OnInit {
  chartOption: EChartsOption = {
    title: {
      text: 'Broj pokrenutih postupaka opomene po vrsti obveznika',
      left: 'center'
      
    },
    tooltip: {
      trigger: 'item'
    },
    
    series: [
      {
        name: 'Opomene',
        type: 'pie',
        radius: '50%',
        data: [
          { value: 500, name: 'Domaćinstva' },
          { value: 300, name: 'Privreda' },
          { value: 100, name: 'Obrt' }          
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }]
};
  ngOnInit() {
   
  }
}

