import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@angular/common';

@Component({
  selector: 'otvoreni-racuni',
  templateUrl: 'otvoreni-racuni.component.html',
  styleUrls: ['otvoreni-racuni.component.css']
})

export class OtvoreniRacuniComponent implements OnInit {
  getservice: httpservice;
  @Input() korisnik?: "";
  @Input() vrstausluge?: "";
  displayedColumns: string[] = ['datum', 'isprava', 'redniBroj', 'racun','datumRacuna','valutaRacuna', 'duguje','potrazuje','saldo','status'];
  lista: any;
  duguje: number;
  potrazuje: number;
  saldo: number;
  oddana: Date;
  dodana: Date;

  constructor(dataservice: httpservice, @Inject(LOCALE_ID) private locale: string)
  {
    this.getservice = dataservice;
   
  }

  ngOnInit() {
    
    this.duguje = 0;
    this.potrazuje = 0;
    this.saldo = 0;
    this.dodana = new Date();
    this.oddana = new Date(this.dodana.getFullYear(), 0, 1);
    this.UcitajRacune();
    

  }


  UcitajRacune() {
    try {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/kartica/OtvoreniRacuni?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstausluge + "&oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)).subscribe(result => {
        if (result.status === "success") {
          this.lista = result.data;
          this.duguje = 0;
          this.potrazuje = 0;
          this.saldo = 0;
          this.lista.forEach((element) => {
            this.duguje += element.duguje;
            this.potrazuje += element.potrazuje;
            this.saldo += element.saldo;
          })
        }
      }, error => console.error('Get otvoreni računi', error));
    }
    catch (error) {
      console.error('Log error', error);
    }
  }

  Ispis() {    
    this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/kartica/otvoreniRacuniPdf?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstausluge).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });    
  }

  IspisIos() {
    this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/kartica/IosPdf?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstausluge + "&oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)).subscribe((data: Blob) => {
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }, (error) => {
      console.log('getPDF error: ', error);
    });
  }

}



