<div>
  <h1 mat-dialog-title>Kreiraj prijedlog dokumenta {{data.id}}</h1>
  <div mat-dialog-content class="col-12">
    <mat-form-field appearance="fill" class="col-12">
      <mat-label>Identifkator za dopunu</mat-label>
      <input matInput placeholder="Identifikator" [(ngModel)]="data.identifikator" name="identifikator" value="">
    </mat-form-field>
    <input type="file" class="file-input" accept="application/pdf"
           (change)="onFileSelected($event)" #file name="file">
    <div class="file-upload">
      {{fileName || "Nije odabran pdf prijedloga."}}
      <button mat-mini-fab color="primary" class="upload-btn"
              (click)="file.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
    </div>
    <p *ngIf="slanje">Radim...<img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /></p>
    <p *ngIf="greska">{{greska}}</p>
  </div>
  <hr />
  <div mat-dialog-actions class="col-12 d-flex justify-content-center">
    <button mat-button (click)="KreirajPrijedlog()" class="ok-color"><mat-icon aria-hidden="false" aria-label="DA">check</mat-icon> Da</button>
    <button mat-button mat-dialog-close class="cancel-color"><mat-icon aria-hidden="false" aria-label="Ne">cancel</mat-icon> Ne</button>
  </div>
</div>
