import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  pitanje: string;
  pojasnjenje: string;
}

@Component({
  selector: 'DialogComponent',
  templateUrl: 'dialog.component.html' 
})

export class DialogComponent {
  constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onYesClick(): void {
    this.dialogRef.close();
  }
}



