import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { KreirajPrijedlogModel } from 'src/app/_models/eOvrhe/KreirajPrijedlogModel';



export interface DialogData {
  pitanje: string;
  pojasnjenje: string;
}

@Component({
  selector: 'KreirajPrijedlogComponent',
  templateUrl: 'kreiraj-prijedlog.component.html',
  styleUrls:['kreiraj-prijedlog.component.css']
})

export class KreirajPrijedlogComponent {
  greska: string;
  getservice: httpservice;
  slanje: boolean = false;
  fileName: string = "";
  file:any;

  constructor(public dialogRef: MatDialogRef<KreirajPrijedlogComponent>, @Inject(MAT_DIALOG_DATA) public data: KreirajPrijedlogModel, dataservice: httpservice)
  {
    this.getservice = dataservice;
    this.data.konto = "";
    this.data.vrstaPriloga = "1";
    this.data.dopuna = false;
    this.data.kamate = true;
  }


  KreirajPrijedlog() {
    this.slanje = true;
    const formData = new FormData();
    //formData.append("prijedlogModel", JSON.stringify(this.data));
    formData.append("file", this.file);

    this.getservice.PostRequestForm<Message>("komunalno/saldakonti/ovrhe/PosaljiPrijedlog?id=" + this.data.id + "&vrstaPriloga=" + this.data.vrstaPriloga + "&konto=" + this.data.konto + "&dopuna=" + this.data.dopuna + "&kamate=" + this.data.kamate, formData).subscribe(result => {
      if (result.status === "success") {
        this.dialogRef.close();
      }
      else {
        this.greska = result.data;
      }
      this.slanje = false;
    }, error => {
      this.greska = error;
      this.slanje = false;
});

  }

  onYesClick(): void {
    this.KreirajPrijedlog();
  }

  onFileSelected(event) {

    this.file = event.target.files[0];
    this.fileName = this.file.name;
  }

}



