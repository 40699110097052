import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';

export class LoginModel {
    UserName: string;
    Password: string;
}


export class UserToken {
    token: string;
    expiration: string;
}

export class Message {
  status: string;
  data: any;
}

export class firme { id: number; naziv: string; mjesto: string; selected: boolean }


@Injectable({
  providedIn: 'root'
}
)
export class httpservice {
  private httpclient: HttpClient;
  loginService: LoginService;
  login: User;

  constructor(private http: HttpClient, private loginservice: LoginService) {
    this.httpclient = http;
    this.loginService = loginservice;
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
    });    
  }


 //public GetRequest<T>(api: string) {
 // const httpOptions = {
 //   headers: new HttpHeaders({
 //     'Accept': 'application/json',
 //     'rejectUnauthorized': 'false',
 //     'Authorization': 'Bearer '+this.login.token
 //   })
 // };
 // return this.httpclient.get<T>(environment.apiURL + api, httpOptions);
 // //.pipe(catchError(handleError<T>('GetRequest')))
 // }

  public GetRequest<Message>(api: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'rejectUnauthorized': 'false',
        'Authorization': 'Bearer ' + this.login.token        
      }),
      withCredentials: true
    };
    //httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.login.token);
    //httpOptions.headers = httpOptions.headers.set('Accept', 'application/json');
    //httpOptions.headers = httpOptions.headers.set('rejectUnauthorized', 'false');
    return this.httpclient.get<Message>(environment.apiURL + api, httpOptions);
    //.pipe(catchError(handleError<T>('GetRequest')))
  }

  public GetRequestFile<Message>(api: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/pdf',
        'rejectUnauthorized': 'false',
        'Authorization': 'Bearer ' + this.login.token
      }       
      ),
      responseType: 'blob' as const,
      withCredentials: true
      
    };
    //httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.login.token);
    //httpOptions.headers = httpOptions.headers.set('Accept', 'application/json');
    //httpOptions.headers = httpOptions.headers.set('rejectUnauthorized', 'false');
    return this.httpclient.get(environment.apiURL + api, httpOptions);
    //.pipe(catchError(handleError<T>('GetRequest')))
  }

  public PostRequestFile<Message>(api: string, senddata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/zip',
        'rejectUnauthorized': 'false',
        'Authorization': 'Bearer ' + this.login.token
      }
      ),
      responseType: 'arraybuffer' as const,
      withCredentials: true

    };
    //httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.login.token);
    //httpOptions.headers = httpOptions.headers.set('Accept', 'application/json');
    //httpOptions.headers = httpOptions.headers.set('rejectUnauthorized', 'false');
    return this.http.post(environment.apiURL + api,senddata, httpOptions);
    //.pipe(catchError(handleError<T>('GetRequest')))
  }


  public PostRequest<Message>(api: string, senddata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'rejectUnauthorized': 'false',
        'Authorization': 'Bearer ' + this.login.token
      })
    };
    //console.log(JSON.stringify(senddata));
    return this.http.post<Message>(environment.apiURL + api, JSON.stringify(senddata), httpOptions);
  }

  public PostRequestForm<Message>(api: string, senddata: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'rejectUnauthorized': 'false',
        'Authorization': 'Bearer ' + this.login.token
      })
    };
    //console.log(JSON.stringify(senddata));
    return this.http.post<Message>(environment.apiURL + api, senddata, httpOptions);
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message   
  };
}
