<div class="addressbar">
  <span class="addresstext">Sustav / Klijenti</span>
</div>
<div class="containerpadding">
  <div class="d-flex justify-content-center">
    <mat-card appearance="outlined" class="col-12 col-lg-5">
      <mat-card-title>{{poruka}} klijenta</mat-card-title>

      <form (ngSubmit)="addClient()">
        <mat-card-content>
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Osnovno">
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Naziv</mat-label>
                <input matInput placeholder="Naziv" [(ngModel)]="data.naziv" name="naziv">
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Ulica</mat-label>
                <input matInput placeholder="Ulica" [(ngModel)]="data.ulica" name="ulica">
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Kućni broj</mat-label>
                <input matInput placeholder="broj" [(ngModel)]="data.broj" name="broj">
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Naselje</mat-label>
                <input matInput placeholder="Naselje" [(ngModel)]="data.mjesto" name="mjesto">
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>OIB</mat-label>
                <input matInput placeholder="OIB" [(ngModel)]="data.oib" name="oib">
              </mat-form-field><br />
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>IBAN</mat-label>
                <input matInput placeholder="IBAN" [(ngModel)]="data.iban" name="iban">
              </mat-form-field><br />
            </mat-tab>
            <mat-tab label="Firme">
              <mat-selection-list #firme [(ngModel)]="odabraneFirme" [ngModelOptions]="{standalone: true}">
                <mat-list-option *ngFor="let firma of listafirmi" [value]="firma.id" [selected]="firma.selected">
                  {{firma.id}} {{firma.naziv}}
                </mat-list-option>
              </mat-selection-list>
              <hr />
              <p>
                Izabrano firmi: {{firme.selectedOptions.selected.length}}
              </p>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>

        <mat-card-actions class="d-flex justify-content-center">
          <button mat-button type="submit" class="ok-color"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon> Snimi</button>
          <button mat-button (click)="goBack()" type="button" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon> Odustani</button>
        </mat-card-actions>

        <div class="form-group">
          <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
      </form>
      <p>
        <label [ngClass]="status == 'Uspješno dodan' ? 'badge badge-success': 'badge badge-danger'">{{status}}</label>
      </p>

    </mat-card>
  </div>
  </div>
