import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { formatDate } from '@angular/common';
import { ObveznikZonaUlicaComponent } from 'src/app/Komunalno/obveznik-zona-ulica/obveznik-zona-ulica.component';
import { Filter } from '../../../../_models/filter';
import * as saveAs from "file-saver";

@Component({
  selector: 'ispis-utuzivanje',
  templateUrl: './ispis-utuzivanje.component.html'
})

export class IspisUtuzivanjeComponent implements OnInit {
  getservice: httpservice;
  listadokumenata: any;
  listatekstova: any;
  oddana: Date;
  dodana: Date;
  brojod: number;
  brojdo: number;
  dokumentid: number;
  tekstispis: number;
  vrstaizvjesca: any;
  aktivan: number;
  prilozikarticu: boolean;
  obveznik: string = "";
  zona: string = "";
  ulica: string = "";

  constructor(dataservice: httpservice, @Inject(LOCALE_ID) private locale: string) {
    this.getservice = dataservice;

  }

  ngOnInit() {
    this.prilozikarticu = false;
    this.aktivan = 2;
    this.oddana = new Date();
    this.dodana = new Date();
    this.brojod = 0;
    this.brojdo = 0;
    this.dokumentid = 0;
    this.vrstaizvjesca = "1";
    this.UcitajDokumente();
    this.UcitajTekstove();
  }


  UcitajDokumente() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/dokumentvrsta/List").subscribe(result => {
      if (result.status === "success") {
        this.listadokumenata = Object.values(result.data);
      }
    }, error => console.error('Get dokumente', error));
  }

  UcitajTekstove() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/dokumenttekstispis/List").subscribe(result => {
      if (result.status === "success") {
        this.listatekstova = Object.values(result.data);
      }
    }, error => console.error('Get tekstove', error));
  }


  Ispisi() {
    if (this.vrstaizvjesca == "1") {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/dokument/listapdf?id=" + this.dokumentid + "&oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale) + "&aktivan=" + this.aktivan + "&obveznik=" + this.obveznik + "&zona=" + this.zona + "&ulica=" + this.ulica).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      }

      );
    }
    if (this.vrstaizvjesca == "2") {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/dokument/pdf?vrstadokumenta=" + this.dokumentid + "&tekstispis=" + this.tekstispis + "&aktivan=" + this.aktivan + "&odbroja=" + this.brojod + "&dobroja=" + this.brojdo + "&prilozikarticu=" + this.prilozikarticu + "&obveznik=" + this.obveznik + "&zona=" + this.zona + "&ulica=" + this.ulica).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      }

      );
    }
    if (this.vrstaizvjesca == "3") {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/dokument/listaexcel?id=" + this.dokumentid + "&oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale) + "&aktivan=" + this.aktivan + "&obveznik=" + this.obveznik + "&zona=" + this.zona + "&ulica=" + this.ulica).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        saveAs(file, "ListaKorisnika.xlsx");
        //var fileURL = URL.createObjectURL(file);
        //window.open(fileURL);
      }, (error) => {
        console.log('getExcel error: ', error);
      }

      );
    }
  }
  OdabirUvjeta(izbor: Filter) {
    this.obveznik = izbor.obveznik;
    this.zona = izbor.zona;
    this.ulica = izbor.ulica;
  }

}
