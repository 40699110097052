<div class="addressbar">
  <span class="addresstext">Komunalno / Obrada dugovanja / Kontrolna ploča</span>
</div>
<div class="containerpadding">
  <h1>Kontrolna ploča obrade dugovanja</h1>
  <hr />
  <div class="row">
    <div class="col-12 col-lg-6">
      <chart-obveznici></chart-obveznici>
    </div>
    <div class="col-12 col-lg-6">
      <chart-dokumenti></chart-dokumenti>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-12 mt-1">
      <struktura-duga></struktura-duga>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-12 mt-1">
      <rocnost-duga></rocnost-duga>
    </div>
  </div>
</div>
