import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/_models/user';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { Firma } from '../_models/firma';
import { UserClientsPost } from '../_models/userclientspost';
import { firme } from '../commons/commons.share';

@Component({
  selector: 'usersAdd',
  templateUrl: './usersAdd.component.html'
})
export class UsersAddComponent implements OnInit {
  getservice: httpservice;
  data: User = {firstName:"",lastName:"",username:"",mail:"",password:"",id:"",token:"",role:"",aktivnaGodina:2021,aktivnaFirma:0,vrstaUsluge:false,konto:false};
  loading = false;
  submitted = false;
  status: string = "";
  returnUrl: string;
  error = '';
  editing = false;
  poruka = "Dodavanje";
  listaklijenata: firme[];
  listafirmi: firme[];
  odabraniKlijenti: firme[];
  

  constructor(dataservice: httpservice, private router: Router, private route: ActivatedRoute, private location: Location) {
    this.getservice = dataservice;       
  }

  ngOnInit() {
    this.odabraniKlijenti = [];
    var idkor = this.route.snapshot.paramMap.get('id');
    if (idkor != "") {
      this.getUser(idkor);
    }
    this.getservice.GetRequest<Message>("User/Clients?id=" + idkor).subscribe(result => {
      if (result.status === "success") {
        this.listaklijenata = Object.values(result.data);
        this.oznaciKlijente();
        this.getFirme();
      }
    }, error => console.error('Get zahtjev', error));
  }


  //oznaciKlijente() {
  //  for (var a = 0; a < this.listaklijenata.length; a++) {
  //    if (this.listaklijenata[a].selected) {
  //      this.odabraniKlijenti.push(this.listaklijenata[a].id);
  //    }
  //  }
  //}
  oznaciKlijente() {
    for (var a = 0; a < this.listaklijenata.length; a++) {
      if (this.listaklijenata[a].selected) {
        this.odabraniKlijenti.push(this.listaklijenata[a]);
      }
    }
  }


  addUser() {
    if (this.data.firstName !== '' && this.data.username !== '') {
      this.submitted = true;
      this.loading = true;

      if (!this.editing) {
        this.getservice.PostRequest<Message>("User/add", this.data).subscribe(result => {
          if (result.status === "success") {
            this.addClients(result.data);
          }
          else {
            this.loading = false;            
            this.error = JSON.stringify(result.data);
            console.error('Log error', result);
          }
        },
          error => {
            this.loading = false;
            console.error('Log error', error);
          });
      }
      else {
        this.getservice.PostRequest<Message>("User/edit", this.data).subscribe(result => {
          if (result.status === "success") {
            this.addClients(this.data.id);
          }
          else {
            this.error = result.data;
            console.error('Log error', result);
          }
        }, error => console.error('Log error', error));
      }
    }
  }

  addClients(userid:string) {

    let odklij: number[]=[];
    this.odabraniKlijenti.forEach(e => odklij.push(e.id));
    let senddata: UserClientsPost = { id : userid, clients : odklij };


    this.getservice.PostRequest<Message>("User/addClients", senddata).subscribe(result => {
      if (result.status === "success") { this.router.navigate(['/users']);}
      else {

        this.error = result.data;
            console.error('Log error', result);
      }      
    },
      error => console.error('Log error', error));
    return false;
  }


  goBack() {
    this.location.back();
  }   

  getUser(id: string) {
    if (id != "") {
      this.getservice.GetRequest<Message>("User/Get?id=" + id).subscribe(result => {
        if (result.status === "success") {
          this.editing = true;
          this.poruka = "Ispravak";
          this.data = result.data;         
        }
      });
    }
  }

  getFirme() {
    let upit: string = "";
    this.odabraniKlijenti.forEach(e => upit += "&id=" + e.id);
    upit.slice(1, upit.length - 1);
    this.getservice.GetRequest<Message>("User/ClientFirme?" + upit).subscribe(result => {
      if (result.status === "success") {
        let svefirme: firme[];
        svefirme  = Object.values(result.data);
        this.listafirmi = [];
        svefirme.forEach(e => {
          if (e.selected) this.listafirmi.push(e);
        });
        
        
      }
    }, error => console.error('Get firme', error));
  }

 
  
}



