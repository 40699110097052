<div class="p-1 mt-2">
  <mat-card appearance="outlined">
    <mat-card-title>
      Uplate po računu
    </mat-card-title>
    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

        <ng-container matColumnDef="datum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Datum</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.datum | date: 'dd.MM.yyyy'}} </td>
          <td mat-footer-cell *matFooterCellDef> Ukupno </td>
        </ng-container>

        <ng-container matColumnDef="isprava">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Isprava</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.isprava}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="redniBroj">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Redni broj</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.redniBroj}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="pozicija">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Pozicija</th>
          <td mat-cell *matCellDef="let stavka"> {{stavka.pozicija}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="duguje">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Duguje</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.duguje | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{duguje | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <ng-container matColumnDef="potrazuje">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Potražuje</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.potrazuje | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{potrazuje | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <ng-container matColumnDef="iznos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center">Iznos uplate</th>
          <td mat-cell *matCellDef="let stavka" class="text-right"> {{stavka.iznos | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{saldo | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
      </div>
</mat-card>
</div>


