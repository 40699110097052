<div class="addressbar">
  <span class="addresstext">Komunalno / Salda-konti / Zatvaranje računa</span>
</div>
<div class="containerpadding">
  <div class="row">
    <h1 class="col-12">Automatsko zatvaranje računa komunalnih kupaca</h1>
    <div *ngIf="!start">
      <div style="padding-left:1em">
        <mat-checkbox [(ngModel)]="iskljucipocetno" name="iskljucipocetno">Isključi početno stanje (zatvori samo ako ima viška uplata)</mat-checkbox>
      </div>
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Konto</mat-label>
        <input matInput placeholder="Konto" [(ngModel)]="konto" name="konto" value="">
      </mat-form-field>
      <button mat-button (click)="PokreniPostupak()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Pokreni">done</mat-icon> Pokreni</button><br />
    </div>
    <mat-progress-bar *ngIf="start" style="height:25px;"
                      mode="determinate"
                      [value]="progressvalue">
    </mat-progress-bar>
    <div *ngIf="start" class="text-center col-12">
      {{current}}/{{count}} - {{progressvalue | number: '1.2-2':'hr'}}%
      <br />
      {{messageStatus}}
      <br />
      <div *ngIf="start" class="text-center">
        {{message}}
      </div>
      <br />
      <button mat-button (click)="PrekiniPostupak()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Pokreni">done</mat-icon> Odustani</button>
    </div>
  </div>
</div>
