<div class="addressbar">
  <span class="addresstext">Podrška / Log</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Data log
    </mat-card-title>

    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />
    <mat-card-actions>
      <button (click)="UcitajPodatke()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Osvježi">update</mat-icon> Učitaj podatke</button><br />
    </mat-card-actions>
    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Korisnik </th>
          <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="firma">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Firma </th>
          <td mat-cell *matCellDef="let element"> {{element.firma}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum </th>
          <td mat-cell *matCellDef="let element"> {{element.date | date: 'dd.MM.yyyy hh:mm:ss'}} </td>
        </ng-container>

        <ng-container matColumnDef="controller">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Opis </th>
          <td mat-cell *matCellDef="let element"> {{element.controller}} </td>
        </ng-container>

        <ng-container matColumnDef="operation">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Operacija </th>
          <td mat-cell *matCellDef="let element"> {{element.operation}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Uspješno </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" [ngClass]="{highlight: selectedrow.id === row.id}" (click)="highlight(row)"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[ 10, 20,50]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>
  <hr />
  </div>
