<mat-card appearance="outlined">
  <mat-card-title>
    Vodomjerila
  </mat-card-title>

  <p *ngIf="!lista"><em>Učitavam...</em></p>
  <hr />
  <mat-form-field appearance="fill">
    <mat-label>Broj vodomjera</mat-label>
    <mat-select [(value)]="recno" (selectionChange)="RefreshData()">
      <mat-option [value]="100">100</mat-option>
      <mat-option [value]="500">500</mat-option>
      <mat-option [value]="1000">1000</mat-option>
      <mat-option [value]="1500">1500</mat-option>
      <mat-option [value]="2000">2000</mat-option>
      <mat-option [value]="4000">4000</mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;
  <mat-form-field appearance="fill">
    <mat-label>Obveznik</mat-label>
    <mat-select>
      <mat-option [value]=""></mat-option>
      <mat-option [value]="100">100</mat-option>
      <mat-option [value]="500">500</mat-option>
      <mat-option [value]="1000">1000</mat-option>
      <mat-option [value]="1500">1500</mat-option>
      <mat-option [value]="2000">2000</mat-option>
      <mat-option [value]="4000">4000</mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;
  <mat-form-field appearance="fill">
    <mat-label>Zona</mat-label>
    <mat-select>
      <mat-option [value]=""></mat-option>
      <mat-option [value]="100">100</mat-option>
      <mat-option [value]="500">500</mat-option>
      <mat-option [value]="1000">1000</mat-option>
      <mat-option [value]="1500">1500</mat-option>
      <mat-option [value]="2000">2000</mat-option>
      <mat-option [value]="4000">4000</mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;
  <mat-form-field appearance="fill">
    <mat-label>Ulica</mat-label>
    <mat-select>
      <mat-option [value]=""></mat-option>
      <mat-option [value]="100">100</mat-option>
      <mat-option [value]="500">500</mat-option>
      <mat-option [value]="1000">1000</mat-option>
      <mat-option [value]="1500">1500</mat-option>
      <mat-option [value]="2000">2000</mat-option>
      <mat-option [value]="4000">4000</mat-option>
    </mat-select>
  </mat-form-field>
  <div id="mapid" style="min-height:600px;height:100%"></div>

</mat-card>
