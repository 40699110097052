import { NgModule } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'rocnost-duga',
  templateUrl: './rocnost-duga.component.html'  

})

export class RocnostDugaComponent implements OnInit {
  getservice: httpservice;
  data: any;
  datacistoca: any;
  ukupnidug: number;
  ukupniiznos: number;
  ukupnibroj: number;
  ukupnidugcistoca: number;
  ukupniiznoscistoca: number;
  ukupnibrojcistoca: number;
  chartOption: any;
  chartOptioncistoca: any;
  podaci: { value, name }[];
  podacicistoca: { value, name }[];
  loginService: LoginService;
  login: User;
  ispis: boolean;
  tipVezanja:string;

  constructor(dataservice: httpservice, loginservice: LoginService) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.ispis = false;
    this.ukupnidug = 0;
    this.ukupnibroj = 0;
    this.ukupnidugcistoca = 0;
    this.ukupnibrojcistoca = 0;
    this.tipVezanja = "A";
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      if (data) {
        this.UcitajPodatke();
      }
    });
    
  }

  UcitajPodatke() {
    if (this.login.vrstaUsluge) {
      this.data = null;
      this.podaci = null;
      var apicall = "rocnostduga";
      if (this.tipVezanja == "A") {
        apicall = "rocnostdugaautomatika";
      }
      this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/"+apicall+"?vrstausluge=V").subscribe(result => {
        if (result.status === "success") {
          this.data = result.data;
          this.ukupnibroj = 0;
          this.ukupniiznos = 0;
          this.data.forEach(e => {
            this.ukupnibroj += e.kolicina;
            this.ukupniiznos += e.iznos;
          });
          this.podaci = [,];
          result.data.forEach(e => {
            this.podaci.push({ value: e.iznos, name: e.naziv });
          });
          this.Inicijaliziraj();
          this.UcitajPodatkeCistoca();
        }
      }, error => console.error('Get data', error));
      
    }
    else {
      this.data = null;
      this.podaci = null;
      var apicall = "rocnostduga";
      if (this.tipVezanja == "A") {
        apicall = "rocnostdugaautomatika";
      }
      this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/"+apicall).subscribe(result => {
        if (result.status === "success") {
          this.data = result.data;
          this.ukupnibroj = 0;
          this.ukupniiznos = 0;
          this.data.forEach(e => {
            this.ukupnibroj += e.kolicina;
            this.ukupniiznos += e.iznos;
          });
          this.podaci = [,];
          result.data.forEach(e => {
            this.podaci.push({ value: e.iznos, name: e.naziv });
          });
          this.Inicijaliziraj();
        }
      }, error => console.error('Get data', error));
    }
  }

  UcitajPodatkeCistoca() {
    this.datacistoca = null;
    this.podacicistoca = null;
    var apicall = "rocnostduga";
    if (this.tipVezanja == "A") {
      apicall = "rocnostdugaautomatika";
    }
    this.getservice.GetRequest<Message>("komunalno/saldakonti/utuzivanje/"+apicall+"?vrstausluge=C").subscribe(result => {
      if (result.status === "success") {
        this.datacistoca = result.data;
        this.ukupnibrojcistoca = 0;
        this.ukupniiznoscistoca = 0;
        this.datacistoca.forEach(e => {
          this.ukupnibrojcistoca += e.kolicina;
          this.ukupniiznoscistoca += e.iznos;
        });
        this.podacicistoca = [,];
        result.data.forEach(e => {
          this.podacicistoca.push({ value: e.iznos, name: e.naziv });
        });
        this.InicijalizirajCistoca();
      }
    }, error => console.error('Get data', error));
  }


  Inicijaliziraj() {
    this.chartOption = {
      title: {
        text: 'Ročnost duga',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Dug po danima',
          type: 'pie',
          radius: '50%',
          data: [...this.podaci],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
    };
  }
  InicijalizirajCistoca() {
    this.chartOptioncistoca = {
      title: {
        text: 'Ročnost duga čistoća',
        left: 'center'
      },
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Dug po danima',
          type: 'pie',
          radius: '50%',
          data: [...this.podacicistoca],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
    };
  }

  onTipVezanja(value: any) {
    this.UcitajPodatke();
  }

  IspisListeDuznika(rok) {
    this.ispis = true;
    let query = "";
    switch (rok) {
      case "Do 30 dana":
        {
          query = "30";
          break;
        }
      case "Do 60 dana":
        {
          query = "60";
          break;
        }
      case "Do 180 dana":
        {
          query = "180";
          break;
        }
      case "Do 365 dana":
        {
          query = "365";
          break;
        }
      case "1 godina":
        {
          query = "1g";
          break;
        }
      case "2 godine":
        {
          query = "2g";
          break;
        }
      case "3 godine":
        {
          query = "3g";
          break;
        }
      case "4 godine":
        {
          query = "4g";
          break;
        }
      case "preko 5 godina":
        {
          query = "5g";          
        }
    }
    this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/utuzivanje/rocnostdugalistapdf?rok=" + query).subscribe((data: Blob) => {
      this.ispis = false;
      var file = new Blob([data], { type: 'application/pdf' })
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }, (error) => {
      console.log('getPDF error: ', error);
    });
  }
}
