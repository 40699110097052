<div class="addressbar">
  <span class="addresstext">Komunalno / Obrada dugovanja / Upute</span>
</div>
<div class="containerpadding">
  <h1>Upute obrada dugovanja</h1>
  U glavnom izborniku nalazi se:
  <ul class="contentstable">
    <li>
      1. Podrška (pregled svih podnesenih zahtjeva za izmjene, dorade u programima i razne intervencije)
    </li>
    <li>
      2. Komunalno
    </li>
    <li>
      2.1. Matični podaci
    </li>
    <li>
      <a href="komunalno/saldakonti/utuzivanje/upute#vrstedokumenata">2.1.1 Vrste dokumenata (statusa) – evidencija vrsta dokumenata (statusa) koji se otvaraju u procesu naplate dugovanja (opomene, opomene pred isključenje, tužbe, sporazumi…). Dokumenti se otvaraju po potrebi</a>
    </li>
    <li>
      <a href="komunalno/saldakonti/utuzivanje/upute#tekstoviispis">2.1.2 Tekst za ispis – tekstovi za ispis dokumenata koji se povezuju sa dokumentom kako bi se mogao ispisati bez izbora teksta za ispis</a>
</li>
    <li>
      2.1.3 Korisnici – lista korisnika komunalnog programa
    </li>
    <li>
      2.2. Salda-konti
    </li>
    <li>
      2.2.1 Pregled korisnika – svi bitni podaci o korisniku na jednom mjestu. Moguće odabrati korisnika i pregledati njegovu analitičku karticu, kombiniranu karticu (salda-konti + dokumenti naplate dugovanja), otvorene i zatvorene račune
    </li>
    <li>
      2.3. Obrada dugovanja
    </li>
    <li>
      2.3.1. Kontrolna ploča – grafički i tablični prikaz dugovanja kupaca
    </li>
    <li>
      <a href="komunalno/saldakonti/utuzivanje/upute#pokretanje">2.3.2 Pokretanje postupka – prvi korak u postupku naplate dugovanja</a>
</li>
    <li>
      <a href="komunalno/saldakonti/utuzivanje/upute#nastavak">2.3.3 Nastavak postupka – prelazak iz statusa u status odabranih korisnika npr. iz opomene u opomenu pred isključenje</a>
</li>
    <li>
      <a href="komunalno/saldakonti/utuzivanje/upute#pokrenuti">2.3.4 Pokrenuti postupci – evidencija svih pokrenutih postupaka</a>
</li>
    <li>
      <a href="komunalno/saldakonti/utuzivanje/upute#ispis">2.3.5 Ispis – ispis lista dokumenata i ispis</a>
</li>
  </ul>
  <hr />
  <h2 id="vrstedokumenata">
    1. Vrste dokumenata
  </h2>
  <p>
    <img src="/assets/images/upute_utuzivanje_01.jpg" /><br />
    Evidencija vrsti dokumenata. U gornjem dijelu ekrana nalaze se naredbe za dodavanje, ispravak i brisanje vrste dokumenta.<br />
    <img src="/assets/images/upute_utuzivanje_02.jpg" /><br />
    Klikom na „Novi“ otvara se prostor za unos podataka o vrsti dokumenta.<br />
    <img src="/assets/images/upute_utuzivanje_03.jpg" /><br />
    Potrebno je upisati šifru duljine jednog znaka. Npr. O za opomenu. Naziv vrste i iz padajućeg izbornika odabrati tekst kojim će se dokument ispisivati.
    Za ispravak vrste dokumenta potrebno je kliknuti u tablici na redak i zatim na „Ispravak“.<br />
    <img src="/assets/images/upute_utuzivanje_04.jpg" /><br />
    Nakon ispravka podataka kliknuti na kvačicu (prihvati).
  </p>
  <hr />
  <h2 id="tekstoviispis">
    2. Tekstovi za ispis
  </h2>
  <p>
    <img src="/assets/images/upute_utuzivanje_05.jpg" /><br />
    Kod unosa teksta moguće je koristit kodove za programsko ubacivanje podataka a to su:<br />
    <br />
    [Broj] - broj dokumenta,<br />
    [Datum] - datum dokumenta,<br />
    [Iznos] - iznos duga,<br />
    [BrojDana] - broj dana za plaćanje<br />
    Pa bi tako npr. tekst za iznos dugovanja bio: Poštovani na dan [Datum] utvrdili smo Vaš dug u iznosu od  [Iznos].
  </p>
  <hr />
  <h2 id="dijagramtoka">
    3. Dijagram toka procesa obrade dugovanja
  </h2>
  <p>
    <img src="/assets/images/upute_utuzivanje_06.jpg" /><br />
  </p>
  <hr />
  <h2 id="pokretanje">
    4. Pokretanje postupka
  </h2>
  <p>
    <img src="/assets/images/upute_utuzivanje_07.jpg" /><br />
    Prilikom pokretanja postupka za obradu dugovanja potrebno je prvo odabrati korisnike. Odabir vršimo preko uobičajenih uvjeta obveznik, zona, ulica i uvjeta o dugu. Uvjeti duga mogu se kombinirati pa je moguće zadati odabir korisnika čiji je dug (saldo) u iznosu od do te čiji dug prelazi iznos zadnjih N računa. Kada smo odabrali uvjete kliknemo na "Pretraži"<br />
    <img src="/assets/images/upute_utuzivanje_08.jpg" /><br />
    Dobijemo listu korisnika koji zadovoljavaju postavljene uvjete. Sada još možemo odabrati za koje želimo pokrenuti postupak klikom na potvrdni okvir (kvačicu) „Pokreni postupak“  klikom na „Označi“ možete promijeniti status svim korisnicima.<br />
    <img src="/assets/images/upute_utuzivanje_09.jpg" /><br />
    Potrebno je odabrati koji dokument ćemo kreirati, na koji dan, odrediti broj dana za plaćanje duga i zadati želimo li da se uz dokument zapamte otvoreni računi na dan kreiranja dokumenta (kasnije ih možete koristiti za ispis na opomeni).
    Nakon klika na „Pokreni postupak“ sustav će kreirati izabrani dokument za sve odabrane korisnike te će na analitičkoj kartici sve stavke do toga dana označiti oznakom dokumenta (npr. O za opomenu).<br />
    Na kraju postupka dobit ćete poruku da je uspješno pokrenut postupak.<br />
    <img src="/assets/images/upute_utuzivanje_10.jpg" />
  </p>
  <hr />
  <h2 id="nastavak">
    5. Nastavak postupka
  </h2>
  <p>
    <img src="/assets/images/upute_utuzivanje_11.jpg" /><br />
    Odredite uvjete za odabir korisnika i kliknite „Pretraži“.  Ako npr. želite prijeći sa statusa opomene na status opomena pred isključenje odaberite aktivne opomene.<br />
    <img src="/assets/images/upute_utuzivanje_12.jpg" /><br />
    Prikaže se lista korisnika sa odabranim statusom i trenutnim saldom. Sada možete dodatno odabrati za koga želite nastaviti proces. Izaberite dokument koji želite izraditi (npr. opomenu pred isključenje) odredite datum i rok za plaćanje duga. Nakon klika na „Pokreni postupak“ sustav će kreirati novi dokument za sve odabrane korisnike. Prethodni dokument će deaktivirati i oznaku na analitičkoj kartici zamijeniti sa novom.
  </p>
  <hr />
  <h2 id="pokrenuti">
    6. Pokrenuti postupci
  </h2>
  <p>
    <img src="/assets/images/upute_utuzivanje_13.jpg" /><br />
    Evidencija svih pokrenutih postupaka. Moguće je filtriranje po vrsti dokumenta i aktivnosti (aktivni/neaktivni). Ovdje je moguće i obustaviti pokrenuti postupak. Ako postupak ima prethodni dokument npr. opomena pred isključenje je imala običnu opomenu tada će opomena pred isključenje biti obrisana a opomena će biti aktivirana.  Klikom na strelicu dolje prikazuje se kartica korisnika sa računima koji su uz dokument, analitička kartica, kombinirana kartica i otvoreni/zatvoreni računi. Ispis dokumenta vrši se klikom na „Ispis“.
  </p>
  <hr />
  <h2 id="ispis">
    7. Ispis dokumenata
  </h2>
  <p>
    <img src="/assets/images/upute_utuzivanje_14.jpg" /><br />
    Na ispisu postoje dvije opcije<br />
    1. Lista korisnika sa statusom (dokumentom)<br />
    2. Ispis dopisa<br />
    <br />
    Lista korisnika ispisuje korisnike koji imaju otvorene dokumente po zadanom uvjetu. Uvjet je vrsta dokumenta za razdoblje od dana do dana i aktivnost.<br />
    <img src="/assets/images/upute_utuzivanje_15.jpg" /><br />
    <img src="/assets/images/upute_utuzivanje_16.jpg" /><br />
    Ispis dopisa ispisuje sve dokumente po uvjetu sa tekstom kojeg odaberete. Uvjet je vrsta dokumenta, broj dokumenta od do i aktivnost.<br />
    <img src="/assets/images/upute_utuzivanje_17.jpg" /><br />
    <img src="/assets/images/upute_utuzivanje_18.jpg" /><br />
  </p>
  <h2 id="pomoc">
    8. Odklanjanje problema    
  </h2>
  <p>
    U nekim slučajevima prilikom nadogradnje sustava potrebno je očistiti predmemorirane
    datoteke. U Chromeu odaberite glavni izbornik klikom na tri točkice u desnom gornjem
    uglu. Nakon toga „Više alata“ -> „Obriši podatke pregledavanja<br />
    <img src="/assets/images/pomoc_01.jpg" /><br />
    Zatim odaberite predmemorirane slike i datoteke<br />
    <img src="/assets/images/pomoc_02.jpg" /><br />
    Ponovo otvorite portal.<br />
    U pregledniku Edge odaberite glavni izbornik klikom na tri točkice u gornjem desnom uglu.<br />
    <img src="/assets/images/pomoc_03.jpg" /><br />
    Zatim kliknite na „Povijest“<br />
    <img src="/assets/images/pomoc_04.jpg" /><br />
    Zatim na tri točkice<br />
    <img src="/assets/images/pomoc_05.jpg" /><br />
    Nakon toga „Čišćenje podataka o pregledavanju“<br />
    <img src="/assets/images/pomoc_06.jpg" /><br />
    Odabrati „Predmemorirane slike i datoteke“ i kliknuti „Očisti sad“.<br />
  </p>
</div>
