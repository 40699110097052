import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';
import { MatDatepickerIntl } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';

@Component({
  selector: 'kartica',
  templateUrl: 'kartica.component.html',
  styleUrls: ['kartica.component.css']
})

export class KarticaComponent implements OnInit {
  getservice: httpservice;
  @Input() korisnik?: "";
  displayedColumns: string[] = ['datum', 'isprava', 'redniBroj', 'racun', 'datumRacuna', 'valutaRacuna', 'duguje', 'potrazuje', 'status'];
  lista: any;
  duguje: number;
  potrazuje: number;
  loginService: LoginService;
  login: User;
  vrstaUsluge: string;
  oddana: Date;
  dodana: Date;
  constructor(dataservice: httpservice, loginservice: LoginService, @Inject(LOCALE_ID) private locale: string) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.vrstaUsluge = "V";
    this.dodana = new Date();
    this.oddana = new Date(this.dodana.getFullYear(), 0, 1);

    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      if (data) {
        this.UcitajKarticu();
      }
    });

  }

  UcitajKarticu() {
    try {
      this.duguje = 0;
      this.potrazuje = 0;
      if (this.login.vrstaUsluge) {
        this.getservice.GetRequest<Message>("komunalno/saldakonti/kartica/KarticaKorisnika?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstaUsluge).subscribe(result => {
          if (result.status === "success") {
            this.lista = (Object.values(result.data));
            this.lista.forEach((element) => {
              this.duguje += element.duguje;
              this.potrazuje += element.potrazuje;
            })
          }
        }, error => console.error('Get karticu', error));
      }
      else {
        this.getservice.GetRequest<Message>("komunalno/saldakonti/kartica/KarticaKorisnika?korisnik=" + this.korisnik + "&oddana=" + formatDate(this.oddana, 'yyyy-MM-dd', this.locale) + "&dodana=" + formatDate(this.dodana, 'yyyy-MM-dd', this.locale)).subscribe(result => {
          if (result.status === "success") {
            this.lista = (Object.values(result.data));
            this.lista.forEach((element) => {
              this.duguje += element.duguje;
              this.potrazuje += element.potrazuje;
            })
          }
        }, error => console.error('Get karticu', error));
      }
    }
    catch (error) {
      console.error('Log error', error);
    }
  }
  Ispis() {
    if (this.login.vrstaUsluge) {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/kartica/pdf?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstaUsluge + "&oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
    }
    else {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/kartica/pdf?korisnik=" + this.korisnik + "&oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
    }
  }
}




