
<mat-card appearance="outlined" *ngIf="data" class="col-12 mt-2">
  <div class="row">
    <div class="col-lg-2 col-12">
      <span class="data-description">Šifra</span><br />
      <span class="data-content">{{data.sifra}}</span>
    </div>
    <div class="col-lg-10 col-12">
      <span class="data-description">Ime/Naziv</span><br />
      <span class="data-content">{{data.ime}}</span>
    </div>
    <div class="col-lg-3 col-12">
      <span class="data-description">Obveznik</span><br />
<span class="data-content">{{data.obveznik}} {{data.obveznikEntity.naziv}}</span>
    </div>
    <div class="col-lg-4 col-12">
      <span class="data-description">Zona</span><br />
      <span class="data-content">{{data.zona}} {{data.zonaEntity.naziv}}</span>
    </div>
    <div class="col-lg-5 col-12">
      <span class="data-description">Ulica</span><br />
      <span class="data-content">{{data.ulica}} {{data.ulicaEntity.naziv}} {{data.kucniBroj}}{{data.kucniBrojOznaka}}, {{data.ulicaEntity.ptt}} {{data.ulicaEntity.mjesto}}</span>
    </div>
    <div class="col-lg-2 col-12">
      <span class="data-description">Aktivan</span><br />
      <span class="data-content"><mat-checkbox [ngModel]="data.obracun" name="obracun" [disabled]="true"></mat-checkbox></span>
    </div>
    <div class="col-lg-2 col-12">
      <span class="data-description">Dodatna adresa</span><br />
      <span class="data-content"><mat-checkbox [ngModel]="data.dodatnaAdresa" name="dodatnaadresa" [disabled]="true"></mat-checkbox></span>
    </div>
    <div *ngIf="data.dodatnaAdresa" class="row col-12">
      <div class="col-12">
        <span class="data-description">Dodatna adresa</span>
      </div>
      <div class="col-lg-6 col-12">
        <span class="data-description">Ime/Naziv</span><br />
        <span class="data-content">{{data.dodAdrIme}}</span>
      </div>
      <div class="col-lg-6 col-12">
        <span class="data-description">Adresa</span><br />
        <span class="data-content">{{data.dodAdrUlica}} {{data.dodAdrKbr}}{{data.dodAdrKbrOzn}}, {{data.dodAdrPtt}} {{data.dodAdrMjesto}}</span>
      </div>
    </div>
  </div>
</mat-card>
