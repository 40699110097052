
<div *ngIf="login" class="leftnav">
  <ul [hidden]="navMain" class="menu">
    <li *ngIf="modulSustav" class="nav-item">
      <a [routerLink]="[]" (click)="clickSustav()" class="menuitem">
        <mat-icon *ngIf="navSustav">
          navigate_next
        </mat-icon>
        <mat-icon *ngIf="!navSustav">
          expand_more
        </mat-icon>Sustav
      </a>
      <div [hidden]="navSustav">
        <ul class="menu">
          <li>
            <a routerLink="/users" class="menuchild">
              <mat-icon aria-hidden="true">
                manage_accounts
              </mat-icon>
              Korisnici
            </a>
          </li>
          <li>
            <a routerLink="/klijent" class="menuchild">
              <mat-icon aria-hidden="true">
                business
              </mat-icon>
              Klijenti
            </a>
          </li>
          <li>
            <a routerLink="/firma" class="menuchild">
              <mat-icon aria-hidden="true">
                store
              </mat-icon>
              Firme
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/license" class="menuchild">
              <mat-icon aria-hidden="true">
                access_alarms
              </mat-icon>
              Licence
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/changelog" class="menuchild">
              <mat-icon aria-hidden="true">
                published_with_changes
              </mat-icon>
              Verzije
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/datalog" class="menuchild">
              <mat-icon aria-hidden="true">
                change_circle
              </mat-icon>
              Data Log
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li>
      <a [routerLink]="[]" (click)="clickPodrska()" class="menuitem menuroot">
        <mat-icon *ngIf="navPodrska">
          navigate_next
        </mat-icon>
        <mat-icon *ngIf="!navPodrska">
          expand_more
        </mat-icon>Podrška
      </a>
      <div [hidden]="navPodrska">
        <ul class="menu">
          <li>
            <a routerLink="/requests" class="menuchild">
              <mat-icon aria-hidden="true">
                message
              </mat-icon>
              Zahtjevi
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li *ngIf="modulSustav" class="nav-item">
      <a [routerLink]="[]" (click)="clickMaticni()" class="menuitem menuroot">
        <mat-icon *ngIf="navMaticni">
          navigate_next
        </mat-icon>
        <mat-icon *ngIf="!navMaticni">
          expand_more
        </mat-icon>Matični
      </a>
      <div [hidden]="navMaticni">
        <ul class="menu">
          <li>
            <a routerLink="/maticni/pdv" class="menuchild">
              <mat-icon aria-hidden="true">
                message
              </mat-icon>
              Pdv stope
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li *ngIf="this.firmapodaci.modulKomunalno" class="nav-item">
      <a [routerLink]="[]" (click)="clickKomunalno()" class="menuitem menuroot">
        <mat-icon *ngIf="navKomunalno">
          navigate_next
        </mat-icon>
        <mat-icon *ngIf="!navKomunalno">
          expand_more
        </mat-icon>Komunalno
      </a>
      <div>
        <ul [hidden]="navKomunalno" class="menu">
          <li class="nav-item">
            <a [routerLink]="[]" (click)="clickKomunalnoMaticni()" class="menuitem menuroot">
              <mat-icon *ngIf="navKomunalnoMaticni">
                navigate_next
              </mat-icon>
              <mat-icon *ngIf="!navKomunalnoMaticni">
                expand_more
              </mat-icon>Matični
            </a>
            <div [hidden]="navKomunalnoMaticni">
              <ul class="menu">
                <li>
                  <a routerLink="/komunalno/saldakonti/utuzivanje/dokumentvrsta" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Vrste dokumenata (statusa)
                  </a>
                </li>
                <li>
                  <a routerLink="/komunalno/saldakonti/utuzivanje/dokumenttekst" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Tekst za ispis
                  </a>
                </li>
                <li>
                  <a routerLink="/komunalno/korisnik" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Korisnici
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a [routerLink]="[]" (click)="clickKomunalnoSalda()" class="menuitem menuroot">
              <mat-icon *ngIf="navKomunalnoSalda">
                navigate_next
              </mat-icon>
              <mat-icon *ngIf="!navKomunalnoSalda">
                expand_more
              </mat-icon>Salda-konti
            </a>
            <div [hidden]="navKomunalnoSalda">
              <ul class="menu">
                <li>
                  <a routerLink="/komunalno/saldakonti/korisnik" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Pregled korisnika
                  </a>
                </li>
                <li>
                  <a routerLink="/komunalno/saldakonti/zatvaranje-racuna" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Automatsko zatvaranje računa
                  </a>
                </li>
                <!--<li>
                <a routerLink="/komunalno/saldakonti/pocetno-stanje" class="menuchild">
                  <mat-icon aria-hidden="true">
                    table_view
                  </mat-icon> Razrada PS-a po godinama
                </a>
              </li>-->
              </ul>
            </div>
          </li>


          <li class="nav-item">
            <a [routerLink]="[]" (click)="clickKomunalnoNaplata()" class="menuitem menuroot">
              <mat-icon *ngIf="navKomunalnoNaplata">
                navigate_next
              </mat-icon>
              <mat-icon *ngIf="!navKomunalnoNaplata">
                expand_more
              </mat-icon>Obrada dugovanja
            </a>
            <div [hidden]="navKomunalnoNaplata" class="menu">
              <ul class="menu">
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/kontrolna-ploca" class="menuchild">
                    <mat-icon aria-hidden="true">
                      pie_chart_outline
                    </mat-icon> Kontrolna ploča
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/start" class="menuchild">
                    <mat-icon aria-hidden="true">
                      start
                    </mat-icon> Pokretanje postupka
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/nastavak" class="menuchild">
                    <mat-icon aria-hidden="true">
                      navigate_next
                    </mat-icon> Nastavak postupka
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/obustavak" class="menuchild">
                    <mat-icon aria-hidden="true">
                      navigate_next
                    </mat-icon> Obustavak postupka
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/dokument" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Pokrenuti postupci
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/ispis" class="menuchild">
                    <mat-icon aria-hidden="true">
                      print
                    </mat-icon> Ispis
                  </a>
                </li>

                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/upute" class="menuchild">
                    <mat-icon aria-hidden="true">
                      help_outline
                    </mat-icon> Upute
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li *ngIf="firmapodaci.drzava==1" class="nav-item">
            <a [routerLink]="[]" (click)="clickKomunalnoeOvrhe()" class="menuitem menuroot">
              <mat-icon *ngIf="navKomunalnoeOvrhe">
                navigate_next
              </mat-icon>
              <mat-icon *ngIf="!navKomunalnoeOvrhe">
                expand_more
              </mat-icon>eOvrhe
            </a>
            <div [hidden]="navKomunalnoeOvrhe" class="menu">
              <ul class="menu">
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/ovrhe/apitoken" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> eOvrhe API token
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/ovrhe/pregled-prijedloga" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Pregled prijedloga za ovrhu
                  </a>
                </li>
                <li class="nav-item">
                  <a routerLink="/komunalno/saldakonti/utuzivanje/ovrhe/pregled-zahtjeva" class="menuchild">
                    <mat-icon aria-hidden="true">
                      table_view
                    </mat-icon> Pregled zahtjeva za naplatu
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <li *ngIf="this.firmapodaci.modulBI" class="nav-item">
      <a [routerLink]="[]" (click)="clickBI()" class="menuitem menuroot">
        <mat-icon *ngIf="navBI">
          navigate_next
        </mat-icon>
        <mat-icon *ngIf="!navBI">
          expand_more
        </mat-icon>Poslovno izvješćivanje BI
      </a>
      <div [hidden]="navBI">
        <ul class="menu">
          <li>
            <a routerLink="/financije/bi/troskovi/troskovi-promet" class="menuchild">
              <mat-icon aria-hidden="true">
                message
              </mat-icon>
              Promet po vrstama troška
            </a>
          </li>
          <li>
            <a routerLink="/financije/bi/troskovi/troskovi-kartica" class="menuchild">
              <mat-icon aria-hidden="true">
                message
              </mat-icon>
              Kartica troška/rashoda
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li class="nav-item">
      <a [routerLink]="[]" (click)="clickRadniNalozi()" class="menuitem menuroot">
        <mat-icon *ngIf="navRadniNalozi">
          navigate_next
        </mat-icon>
        <mat-icon *ngIf="!navRadniNalozi">
          expand_more
        </mat-icon>Radni nalozi
      </a>
      <div [hidden]="navRadniNalozi">
        <ul class="menu">
          <li>
            <a routerLink="/radninalozi/evidencija" class="menuchild">
              <mat-icon aria-hidden="true">
                message
              </mat-icon>
              Evidencija radnih naloga
            </a>
          </li>
          <li>
            <a routerLink="/radninalozi/izvjestaj" class="menuchild">
              <mat-icon aria-hidden="true">
                message
              </mat-icon>
              Izvještaj o radnim nalozima
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>

  


</div>



