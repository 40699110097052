import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';
import { DokumentVrstaAddComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/DokumentVrsta/dokumentvrstaAdd.component';
import { PravniDokument } from 'src/app/_models/Utuzivanje/pravnidokument';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { KarticaKomboComponent } from '../../../../Komunalno/SaldaKonti/Utuzivanje/kartica-kombo/kartica-kombo.component';
import { OtvoreniRacuniComponent } from '../../../../Komunalno/SaldaKonti/otvoreni-racuni/otvoreni-racuni.component';
import { ZatvoreniRacuniComponent } from '../../../../Komunalno/SaldaKonti/zatvoreni-racuni/zatvoreni-racuni.component';
import { DokumentRacuniComponent } from '../../../../Komunalno/SaldaKonti/Utuzivanje/dokument-racuni/dokument-racuni.component';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';
import { ObveznikZonaUlicaComponent } from 'src/app/Komunalno/obveznik-zona-ulica/obveznik-zona-ulica.component';
import { Filter } from '../../../../_models/filter';
import { Firma } from '../../../../_models/firma';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KreirajPrijedlogComponent } from '../../Ovrhe/kreiraj_prijedlog/kreiraj-prijedlog.component';
import { SokopPrijedlogComponent } from '../../Sokop/sokop-prijedlog.component';
import { KreirajPrijedlogModel } from '../../../../_models/eOvrhe/KreirajPrijedlogModel';
import { KreirajPrijedlogSokopModel } from '../../../../_models/Sokop/KreirajPrijedlogModel';


@Component({
  selector: 'dokument',
  templateUrl: './dokument.component.html',
  styleUrls: ['./dokument.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class DokumentComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['sifra', 'ime', 'vrstaDokumenta', 'broj', 'datum', 'datumDuga', 'rokDana', 'iznos', 'aktivan'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  lista: any;
  showAdd: boolean;
  currentItem: 0;
  aktivan: string = "2";
  listadokumenata: any;
  vrstadokumentaid = 0;
  loginService: LoginService;
  login: User;
  vrstaUsluge: string = "";
  obveznik: string = "";
  zona: string = "";
  ulica: string = "";
  ucitavanje: boolean;
  firmapodaci: Firma;

  constructor(dataservice: httpservice, loginservice: LoginService, public dialog: MatDialog, private router: Router, private _snackBar: MatSnackBar) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }
  ngOnInit() {
    this.firmapodaci = { id: 0, bazaKomunalno: "", broj: "", drzava: 1, eMail: "", iban: "", jib: "", mjesto: "", naziv: "", oib: "", porezniBroj: "", ptt: "", sifra: "", telefon: "", ulica: "", ziroRacun: "", eOvrheAPIKey: "", vrstaUsluge: false, konto: false, eOvrheAddress: "", modulBI: false, modulFakturno: false, modulFinancije: false, modulGdpr: false, modulKomunalno: false, modulMaterijalno: false, modulOsnovna: false, modulPlace: false, sokopBanka: "", sokopFunkcija: "", sokopIme: "", sokopPrezime: "", sokopSud: "", sokopUlica: "" }
    this.showAdd = false;
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      if (data) {
        this.UcitajPodatkeoFirmi();
      }
      if (this.login.vrstaUsluge) {
        this.displayedColumns = ['sifra', 'ime', 'vrstaDokumenta', 'pravniDokumentId', 'datum', 'datumDuga', 'tipIznosa', 'tipKartica', 'rokDana', 'iznos', 'aktivan', 'vrstausluge'];
      }
      else {
        this.displayedColumns = ['sifra', 'ime', 'vrstaDokumenta', 'pravniDokumentId', 'datum', 'datumDuga', 'tipIznosa', 'tipKartica', 'rokDana', 'iznos', 'aktivan'];
      }
    });
    this.Ucitaj();
    this.UcitajDokumente();
  }



  Ucitaj() {
    this.ucitavanje = true;

    if (this.login.vrstaUsluge) {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/dokument/List?aktivni=" + this.aktivan + "&vrstadokumentaid=" + this.vrstadokumentaid + "&vrstausluge=" + this.vrstaUsluge + "&obveznik=" + this.obveznik + "&zona=" + this.zona + "&ulica=" + this.ulica).subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource<PravniDokument>(Object.values(result.data));
          this.lista.paginator = this.paginator;
          setTimeout(() => this.lista.sort = this.sort, 2000);
          this.paginator._intl = new MatPaginatorIntlCro();
          this.ucitavanje = false;
          if (this.lista.data.length > 0) {
            this.selectedrow = this.lista.data[0];
          }
        }
      }, error => console.error('Get zahtjev', error));
    }
    else {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/dokument/List?aktivni=" + this.aktivan + "&vrstadokumentaid=" + this.vrstadokumentaid + "&obveznik=" + this.obveznik + "&zona=" + this.zona + "&ulica=" + this.ulica).subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource<PravniDokument>(Object.values(result.data));
          this.lista.paginator = this.paginator;
          setTimeout(() => this.lista.sort = this.sort, 2000);
          this.paginator._intl = new MatPaginatorIntlCro();
          this.ucitavanje = false;
          if (this.lista.data.length > 0) {            
            this.selectedrow = this.lista.data[0];
          }
        }
      }, error => console.error('Get zahtjev', error));
    }
  }


  UcitajPodatkeoFirmi() {
    this.getservice.GetRequest<Message>("firma/get?id=" + this.login.aktivnaFirma).subscribe(result => {
      if (result.status === "success") {
        this.firmapodaci = result.data;
      }
    }, error => console.error('Get firma', error));
  }


  UcitajDokumente() {
    this.getservice.GetRequest<Message>("komunalno/saldakonti/dokumentvrsta/List").subscribe(result => {
      if (result.status === "success") {
        this.listadokumenata = Object.values(result.data);
      }
    }, error => console.error('Get dokumente', error));
  }



  highlight(row) {
    this.selectedrow = row;
  }

  kartica(row) {
    if (row.prikazikarticu) {
      row.prikazikarticu = false;
    }
    else {
      row.prikazikarticu = true;
    }
  }



  add() {
    this.currentItem = 0;
    this.showAdd = true;
  }


  openDialog() {
    if (this.selectedrow) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Obustavljanje postupka ' + this.selectedrow.ime
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ObustaviPostupak();
        }
      });
    }
  }

  ObustaviPostupak() {
    var ind = this.lista.data.findIndex(function checkData(data) {
      return data.pravniDokumentId == this.selectedrow.pravniDokumentId;
    }, this);
    if (ind > -1) {
      this.getservice.PostRequest<Message>("komunalno/saldakonti/dokument/remove", this.selectedrow.pravniDokumentId).subscribe(result => {
        if (result.status === "success") {
          this.lista.data.splice(ind, 1);
          this.lista._updateChangeSubscription();
        }
      });
    }
  }


  EditData() {
    if (this.selectedrow) {
      this.currentItem = this.selectedrow.pravniDokumentVrstaId;
      this.showAdd = true;
      //this.router.navigate(['/komunalno/saldakonti/utuzivanje/dokument/add', this.selectedrow.pravniDokumentVrstaId]);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }

  Ispis() {
    var ind = this.lista.data.findIndex(function checkData(data) {
      return data.pravniDokumentId == this.selectedrow.pravniDokumentId;
    }, this);
    if (ind > -1) {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/dokument/pdf?vrstadokumenta=" + this.selectedrow.pravniDokumentVrstaId + "&tekstispis=0&aktivan=1&odbroja=" + this.selectedrow.pravniDokumentId + "&dobroja=" + this.selectedrow.pravniDokumentId).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
    }
  }

  IspisSaKarticom() {
    var ind = this.lista.data.findIndex(function checkData(data) {
      return data.pravniDokumentId == this.selectedrow.pravniDokumentId;
    }, this);
    if (ind > -1) {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/dokument/pdf?vrstadokumenta=" + this.selectedrow.pravniDokumentVrstaId + "&tekstispis=0&prilozikarticu=true&aktivan=1&odbroja=" + this.selectedrow.pravniDokumentId + "&dobroja=" + this.selectedrow.pravniDokumentId).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
    }
  }

  OdabirUvjeta(izbor: Filter) {
    this.obveznik = izbor.obveznik;
    this.zona = izbor.zona;
    this.ulica = izbor.ulica;
  }


  KreirajPrijedlog() {
    let iddok: number = this.selectedrow.pravniDokumentId;
    this.dialog.open(KreirajPrijedlogComponent, {
      data: {
        id: iddok,
        konto: '',
        vrstaPriloga: 0
      }
    }
    );
  }

  KreirajPrijedlogSokop() {
    let iddok: number = this.selectedrow.pravniDokumentId;
    this.dialog.open(SokopPrijedlogComponent, {
      data: {
        id: iddok,
        sud: '',
        banka: 0
      }
    }
    );
  }



  SlanjePrijedloga() {
    if (this.selectedrow) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Slanje prijedloga za ovrhu ' + this.selectedrow.ime
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.PosaljiiPotpisi();
        }
      });
    }
  }



  PosaljiiPotpisi() {
    this.getservice.PostRequest<Message>("komunalno/saldakonti/ovrhe/PosaljiPrijedlog", this.selectedrow.pravniDokumentId).subscribe(result => {
      if (result.status === "success") {
        this.Poruka("Uspješno poslan prijedlog","Uredu");
      }
      else {
        this.Poruka("greška " + result.data, "Uredu");
      }
    }, error => { this.Poruka("greška " + error, "Uredu"); });

  }


  KreirajDatotekuIzvrsenika() {
    this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/sokop/izvrsenici").subscribe((data: Blob) => {
      var file = new Blob([data], { type: 'application/zip' })
      var url = window.URL.createObjectURL(file);
      var a: any = document.createElement("a");
      document.body.appendChild(a);

      a.style = "display: none"; a.href = url;
      a.download = "izvrsenici.zip";
      a.click();
      window.URL.revokeObjectURL(url);

      //var fileURL = URL.createObjectURL(file);
      //window.open(fileURL);
    }, (error) => {
      console.log('getZip error: ', error);
    });
  }


  Poruka(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}


