import { Component, OnInit, Input, LOCALE_ID, Inject } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';
import { formatDate } from '@angular/common';

@Component({
  selector: 'troskovi-kartica',
  templateUrl: 'troskovi-kartica.component.html',
  styleUrls: ['troskovi-kartica.component.css']
})

export class TroskoviKarticaComponent implements OnInit {
  getservice: httpservice;
  @Input() trosak?: "";
  displayedColumns: string[] = ['datum', 'isprava', 'redniBroj', 'opis', 'duguje', 'potrazuje'];
  lista: any;
  duguje: number;
  potrazuje: number;
  loginService: LoginService;
  login: User;
  oddana: Date;
  dodana: Date;
  listatroskova: any;
  odabrani: any;
  prikazani: any;

  constructor(dataservice: httpservice, loginservice: LoginService, @Inject(LOCALE_ID) private locale: string) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.dodana = new Date();
    this.oddana = new Date(this.dodana.getFullYear(), 0, 1);
    this.odabrani = { sifra:this.trosak, opis:"" };
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      if (data) {
        this.UcitajTroskove();
        if (this.trosak != "") {
          this.UcitajKarticu();
        }
      }
    });

  }

  UcitajKarticu() {
    try {
      this.duguje = 0;
      this.potrazuje = 0;
      //if (this.trosak != undefined) {
      //  this.odabrani.sifra = this.trosak;
      //}
      this.getservice.GetRequest<Message>("financije/bi/troskovi/karticatroska?oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)+"&trosak="+this.odabrani.sifra).subscribe(result => {
          if (result.status === "success") {
            this.prikazani = this.odabrani;
            this.lista = (Object.values(result.data));
            this.lista.forEach((element) => {
              this.duguje += element.duguje;
              this.potrazuje += element.potrazuje;
            })
          }
        }, error => console.error('Get karticu', error));      
    }
    catch (error) {
      console.error('Log error', error);
    }
  }
  Ispis() {
    this.getservice.GetRequestFile<Blob>("financije/bi/troskovi/karticatroskapdf?oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale) + "&trosak=" + this.odabrani.sifra).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
  }

  UcitajTroskove() {
    this.getservice.GetRequest<Message>("financije/bi/troskovi/List").subscribe(result => {
      if (result.status === "success") {
        this.listatroskova = Object.values(result.data);
      }
    }, error => console.error('Get dokumente', error));
  }
}




