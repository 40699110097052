import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit } from '@angular/core';
import { License } from 'src/app/_models/license';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'licenseEdit',
  templateUrl: './licenseEdit.component.html'
})
export class LicenseEditComponent implements OnInit {
  getservice: httpservice;
  data: License = { client: 0, aktivnaDrzava: 0, aktivniModuli: 0, dana: 0, datumIsteka: null, id: "", datumKreiranja: null, diskSerialNumber: "", produzi: false, produziDana: 0 };
  loading = false;
  submitted = false;
  status: string = "";
  returnUrl: string;
  error = '';
  editing = false;
  poruka = "Ažuriranje";
  modulFinancije = false;
  modulBlagajna = false;
  modulMaterijalno = false;
  modulRadniNalozi = false;
  modulFakturno = false;
  modulKomunalno = false;
  modulCistoca = false;
  modulUrudzbeni = false;
  
  constructor(dataservice: httpservice, private router: Router, private route: ActivatedRoute, private location: Location) {
    this.getservice = dataservice;       
  }
  ngOnInit() {
    var idkor = this.route.snapshot.paramMap.get('id');
    if (idkor != null) {
      this.getUser(idkor);
    }
  }

  

  addUser() {
    this.submitted = true;
    this.loading = true;
    
      if (!this.editing) {
        this.getservice.PostRequest<Message>("License/add", this.data).subscribe(result => {
          if (result.status === "success")
          {
            this.router.navigate(['/license']);
          }
          else {
            console.error('Log error', result);
          }
        },
          error => console.error('Log error', error));
      }
      else {        
        this.data.aktivniModuli = this.KreirajModule();
        this.getservice.PostRequest<Message>("license/edit", this.data).subscribe(result => {
          if (result.status === "success") {
            this.router.navigate(['/license']);
          }
          else {
            console.error('Log error', result);
          }
        }, error => console.error('Log error',error));
      }
    
    
  }

  goBack() {
    this.location.back();
  }   

  getUser(id: string) {
    this.getservice.GetRequest<Message>("license/Get?id=" + id).subscribe(result => {
      if (result.status === "success") {
        this.editing = true;
        this.poruka = "Ispravak";
        this.data = result.data;
        this.modulFinancije = ProvjeriModul(this.data.aktivniModuli, Moduli.Financije);
        this.modulBlagajna = ProvjeriModul(this.data.aktivniModuli, Moduli.Blagajna);
        this.modulMaterijalno = ProvjeriModul(this.data.aktivniModuli, Moduli.Materijalno);
        this.modulRadniNalozi = ProvjeriModul(this.data.aktivniModuli, Moduli.Nalozi);
        this.modulFakturno = ProvjeriModul(this.data.aktivniModuli, Moduli.Fakturno);
        this.modulKomunalno = ProvjeriModul(this.data.aktivniModuli, Moduli.Komunalno);
        this.modulCistoca = ProvjeriModul(this.data.aktivniModuli, Moduli.Cistoca);
        this.modulUrudzbeni = ProvjeriModul(this.data.aktivniModuli, Moduli.UruZapisnik);
      }
    });
  }

  KreirajModule() {
  let moduli: Moduli = 0;
  if (this.modulFinancije) {
    moduli |= Moduli.Financije;
  }
  if (this.modulBlagajna) {
    moduli |= Moduli.Blagajna;
  }
  if (this.modulMaterijalno) {
    moduli |= Moduli.Materijalno;
  }
  if (this.modulRadniNalozi) {
    moduli |= Moduli.Nalozi;
  }
  if (this.modulFakturno) {
    moduli |= Moduli.Fakturno;
  }
  if (this.modulKomunalno) {
    moduli |= Moduli.Komunalno;
  }
  if (this.modulCistoca) {
    moduli |= Moduli.Cistoca;
  }
  if (this.modulUrudzbeni) {
    moduli |= Moduli.UruZapisnik;
  }
  return moduli;
}
}




function ProvjeriModul(AktivniModuli: Moduli, modul: Moduli)
{
  if ((AktivniModuli & modul) == modul) {
    return true;
  }
  return false;
}

enum Moduli {
  NemaModula=0x00,
  Financije = 0x01,
  Pdv = 0x02,
  Blagajna = 0x04,
  Fakturno = 0x08,
  Materijalno = 0x10,
  OsnovnaSredstva = 0x20,
  Place = 0x40,
  Komunalno = 0x80,
  Nalozi = 0x100,
  Bolnica = 0x200,
  Cistoca = 0x400,
  UruZapisnik = 0x800
}
