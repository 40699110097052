<div class="addressbar">
  <span class="addresstext">Sustav / Licence</span>
</div>
<div class="containerpadding">
  <div class="d-flex justify-content-center">
    <mat-card appearance="outlined" class="col-12 col-lg-7">
      <mat-card-title>{{poruka}} licence</mat-card-title>
      <hr />
      <form (ngSubmit)="addUser()">
        <mat-card-content>
          <div class="row d-flex justify-content-center">
            <div class="col-6">
              <mat-form-field class="fullwidth">
                <input matInput placeholder="id" [(ngModel)]="data.id" name="id" disabled>
              </mat-form-field><br />
              <mat-form-field class="fullwidth">
                <input matInput placeholder="Klijent" [(ngModel)]="data.client" name="client" disabled>
              </mat-form-field><br />
              <mat-form-field class="fullwidth">
                <input matInput type="datetime-local" placeholder="Datum kreiranja" [(ngModel)]="data.datumKreiranja" name="datumKreiranja" step="1" disabled>
              </mat-form-field><br />
              <mat-form-field class="fullwidth">
                <input matInput type="datetime-local" placeholder="Datum isteka" [(ngModel)]="data.datumIsteka" name="datumIsteka" step="60">
              </mat-form-field><br />
              <mat-checkbox class="fullwidth" matInput placeholder="Produži" [(ngModel)]="data.produzi" name="produzi">Produži</mat-checkbox>
              <mat-form-field class="fullwidth">
                <input matInput placeholder="Produži dana" [(ngModel)]="data.produziDana" name="produziDana">
              </mat-form-field><br />
              <mat-form-field class="fullwidth">
                <input matInput placeholder="Serijski broj diska" [(ngModel)]="data.diskSerialNumber" name="diskSerialNumber" disabled>
              </mat-form-field>
            </div>
            <div class="col-5">
              Moduli
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulFinancije" name="modulFinancije">Financije</mat-checkbox>
                </li>
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulBlagajna" name="modulBlagajna">Blagajna</mat-checkbox>
                </li>
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulMaterijalno" name="modulMaterijalno">Materijalno</mat-checkbox>
                </li>
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulRadniNalozi" name="modulRadniNalozi">Radni nalozi</mat-checkbox>
                </li>
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulFakturno" name="modulFakturno">Fakturno</mat-checkbox>
                </li>
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulKomunalno" name="modulKomunalno">Komunalno</mat-checkbox>
                </li>
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulCistoca" name="modulCistoca">Čistoća</mat-checkbox>
                <li class="list-group-item">
                  <mat-checkbox matInput [(ngModel)]="modulUrudzbeni" name="modulUrudzbeni">Urudžbeni zapisnik</mat-checkbox>
                </li>
              </ul>
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions class="d-flex justify-content-center">
          <button mat-button type="submit" class="ok-color"><mat-icon aria-hidden="false" aria-label="Snimi">done</mat-icon> Snimi</button>
          <button mat-button (click)="goBack()" type="button" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">close</mat-icon> Odustani</button>
        </mat-card-actions>

        <div class="form-group">
          <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </div>
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
      </form>
      <p>
        <label [ngClass]="status == 'Uspješno dodan' ? 'badge badge-success': 'badge badge-danger'">{{status}}</label>
      </p>

    </mat-card>
  </div>
  </div>
