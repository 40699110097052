import { Component, OnInit, Input } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'kartica-kombo',
  templateUrl: 'kartica-kombo.component.html',
  styleUrls: ['kartica-kombo.component.css']
})

export class KarticaKomboComponent implements OnInit {
  getservice: httpservice;
  @Input() korisnik?: "";
  displayedColumns: string[] = ['datum', 'isprava', 'redniBroj', 'racun','datumRacuna','valutaRacuna', 'duguje','potrazuje','status'];
  lista: any;
  duguje: number;
  potrazuje: number;
  loginService: LoginService;
  login: User;
  vrstaUsluge: string;

  constructor(dataservice: httpservice, loginservice: LoginService)
  {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.duguje = 0;
    this.potrazuje = 0;
    this.vrstaUsluge = "V";

    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      if (data) {
        this.UcitajKarticu();
      }
    });
  }

  UcitajKarticu() {
    this.duguje = 0;
    this.potrazuje = 0;
    if (this.login.vrstaUsluge) {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/kartica/KarticaKorisnikaUtuzivanje?korisnik=" + this.korisnik + "&vrstausluge=" + this.vrstaUsluge).subscribe(result => {
          if (result.status === "success") {
            this.lista = (Object.values(result.data));
            this.lista.forEach((element) => {
              this.duguje += element.duguje;
              this.potrazuje += element.potrazuje;
            })
          }
        }, error => console.error('Get karticu', error));      
    }
    else {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/kartica/KarticaKorisnikaUtuzivanje?korisnik=" + this.korisnik).subscribe(result => {
        if (result.status === "success") {
          this.lista = (Object.values(result.data));
          this.lista.forEach((element) => {
            this.duguje += element.duguje;
            this.potrazuje += element.potrazuje;
          })
        }
      }, error => console.error('Get karticu', error));
    }
  }

  Ispis() {
    if (this.login.vrstaUsluge) {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/kartica/kombiniranaPdf?korisnik=" + this.korisnik + "&vrstaUsluge=" + this.vrstaUsluge).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
    }
    else {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/kartica/kombiniranaPdf?korisnik=" + this.korisnik).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
    }
  }

}



