import { Component, OnInit, Input } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';


@Component({
  selector: 'dokument-racuni',
  templateUrl: 'dokument-racuni.component.html',
  styleUrls: ['dokument-racuni.component.css']
})

export class DokumentRacuniComponent implements OnInit {
  getservice: httpservice;
  @Input() dokumentid?: "";
  displayedColumns: string[] = ['racun', 'datum', 'valuta', 'iznos'];
  lista: any;
  iznos: number;  

  constructor(dataservice: httpservice)
  {
    this.getservice = dataservice;
  }

  ngOnInit() {
    this.iznos = 0;    
    try {
      this.getservice.GetRequest<Message>("komunalno/saldakonti/dokument/racuni?dokumentid=" + this.dokumentid).subscribe(result => {
        if (result.status === "success") {
          this.lista = (Object.values(result.data));
          this.lista.forEach((element) => {
            this.iznos += element.iznos;            
          })
        }
      }, error => console.error('Get račune dokumenta', error));
    }
    catch (error) {
      console.error('Log error', error);
    }
  }  
}



