import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignalrService } from 'src/app/Komunalno/SaldaKonti/pocetno-stanje/signalr.service';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'zatvaranje-racuna',
  templateUrl: './zatvaranje-racuna.component.html'
})
export class ZatvaranjeRacunaComponent implements OnInit {
  
  start: boolean;
  signalservice: SignalrService;
  message: string;
  count: number;
  current: number;
  progressvalue: number;
  messageEnd: string;
  cancelreqest: string;
  messageStatus: string;
  iskljucipocetno: boolean;
  konto: string;

  constructor(progressService: SignalrService, public dialog: MatDialog, private _snackBar: MatSnackBar) {
    this.signalservice = progressService;
  }
  ngOnInit() {
    this.start = false;    
    this.message = "";
    this.messageEnd = "";
    this.count = 0;
    this.current = 0;
    this.progressvalue = 0;
    this.cancelreqest = "";
    this.iskljucipocetno = false;
    this.konto = "";
    this.messageStatus = "";
    this.InicijalizirajVezu();

  }  

  InicijalizirajVezu() {
    this.signalservice.initiateSignalrConnection("saldazatvaranjeracunahub").finally(() => {
      this.signalservice.message.subscribe((hubHelloMessage: string) => {
        this.message = hubHelloMessage;
      });
      this.signalservice.messageEnd.subscribe((hubHelloMessage: string) => {
        this.messageEnd = hubHelloMessage;
        if (hubHelloMessage != null) {
          this.PostupakZavrsen(hubHelloMessage, "Uredu");
        }
      });
      this.signalservice.count.subscribe((count: number) => {
        this.count = count;
      });
      this.signalservice.current.subscribe((current: number) => {
        this.current = current;
        if (current != null)
        {
          this.progressvalue = this.current * 100 / this.count;
        }
      });
      this.signalservice.cancelreqest.subscribe((cancelreqest: string) => {
        this.cancelreqest = cancelreqest;
      });
      this.signalservice.status.subscribe((status: string) => {
        this.messageStatus = status;
      });

    });

  }


  openDialog() {    
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        pitanje: 'Da li ste sigurni ?',
        pojasnjenje: 'Pokretanje postupka'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.PokreniPostupak();
      }
    });

  }

  PokreniPostupak() {
    this.count = 0;
    this.current = 0;
    this.start = true;
    this.signalservice.connection
      .invoke('StartZatvaranje',this.iskljucipocetno,this.konto)
      .catch(error => {
        console.log(`SignalrDemoHub.Hello() error: ${error}`);
        alert('SignalrDemoHub.Hello() error!, see console for details.');
      }
    )
      .finally(() => { console.log('Poslan zahtjevi za startom');});
  }


  PrekiniPostupak() {
    console.log('Prekidam proces');
    console.log('Connection ID:' + this.signalservice.connection.connectionId);
    this.signalservice.connection
      .invoke('CancelPocetno')
      .catch(error => {
        console.log(`SignalrDemoHub.Hello() error: ${error}`);
        alert('SignalrDemoHub.Hello() error!, see console for details.');
      }
    ).finally(() => {
      this.start = false;
      this.count = 0;
      this.current = 0;
      this.progressvalue = 0;
      this.message = "";
      console.log('Poslan zahtjev za prekidom');
      console.log('Connection ID:'+this.signalservice.connection.connectionId);
    });

  }
 

  PostupakZavrsen(message: string, action: string) {
    this.start = false;
    this.count = 0;
    this.current = 0;
    this.progressvalue = 0;
    this._snackBar.open(message, action);
  }




}


