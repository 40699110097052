<div class="addressbar">
  <span class="addresstext">Komunalno / Obrada dugovanja / Pokretanje</span>
</div>
<div class="containerpadding">
  <div class="row">
    <div class="col-12">
      <mat-card-title>
        Odabir korisnika za obradu dugovanja
      </mat-card-title>
      <hr />
    </div>
    <div *ngIf="!lista" class="col-lg-6 col-12">
      <mat-radio-group aria-label="Odabir uvjeta" [(ngModel)]="pojedinacnikorisnik" (ngModelChange)="onchangeKorisnik($event)">
        <mat-radio-button value="0">Više korisnika</mat-radio-button>
        <mat-radio-button class="ml-2" value="1">Jednog korisnika</mat-radio-button>
      </mat-radio-group>
      <mat-card appearance="outlined" *ngIf="pojedinacnikorisnik==='0'">
        <h4>Uvjeti za korisnika</h4>
        <mat-form-field *ngIf="listaObveznika" appearance="fill" class="fullwidth">
          <mat-label>Obveznik</mat-label>
          <mat-select (selectionChange)="DohvatiBrojKorisnika()" [(ngModel)]="qObveznik" name="obveznici">
            <mat-option *ngFor="let obveznik of listaObveznika" [value]="obveznik.sifra">
              {{obveznik.sifra}} {{obveznik.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="listaZona" appearance="fill" class="fullwidth">
          <mat-label>Zona</mat-label>
          <mat-select (selectionChange)="DohvatiBrojKorisnika()" [(ngModel)]="qZona" name="zone">
            <mat-option *ngFor="let zona of listaZona" [value]="zona.sifra">
              {{zona.sifra}} {{zona.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="listaUlica" appearance="fill" class="fullwidth">
          <mat-label>Ulica</mat-label>
          <mat-select (selectionChange)="DohvatiBrojKorisnika()" [(ngModel)]="qUlica" name="ulica">
            <mat-option *ngFor="let ulica of listaUlica" [value]="ulica.sifra">
              {{ulica.sifra}} {{ulica.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <h4>Broj korisnika koji zadovoljavaju uvjet: {{brojkorisnika}}</h4>
      </mat-card>
      <korisnik-select  *ngIf="pojedinacnikorisnik==='1'" (podaci)="OdabirKorisnika($event)"></korisnik-select>
      <korisnik-info *ngIf="korisnik" [korisnik]="korisnik"></korisnik-info>
    </div>
    <div *ngIf="!lista" class="col-lg-6 col-12">
      <mat-card appearance="outlined">
        <h4>Odabir vrste dugovanja</h4>
        <hr />
        <div class="row">
          <div class="col-lg-4 col-12">
            <mat-checkbox [(ngModel)]="startdata.tipiznosa" (ngModelChange)="iznosracunachange($event)" name="tipiznosa">Ukupni dug od do</mat-checkbox><br />
            <mat-form-field appearance="fill" class="fullwidth">
              <mat-label>Dug od</mat-label>
              <input [(ngModel)]="dugod" matInput placeholder="iznosa duga" name="dugod" type="number" (ngModelChange)="dugpoiznosuchange($event)">
            </mat-form-field><br />
            <span *ngIf="dugod>dugdo" class="input-error">
              Iznos duga od mora biti manji od duga do
            </span>
            <mat-form-field appearance="fill" class="fullwidth">
              <mat-label>Dug do</mat-label>
              <input [(ngModel)]="dugdo" matInput placeholder="iznosa duga" name="dugdo" type="number" (ngModelChange)="dugpoiznosuchange($event)">
            </mat-form-field>
          </div>
          <div class="col-lg-4 col-12">
            <mat-checkbox [(ngModel)]="startdata.tipracuna" (ngModelChange)="nracunachange($event)" name="tipracuna">Dug veći od zadnjih</mat-checkbox><br />
            <mat-form-field appearance="fill" class="fullwidth">
              <mat-label>N računa</mat-label>
              <input matInput [(ngModel)]="nracuna" placeholder="N računa" name="nracuna" type="number" (ngModelChange)="dugporacunimachange($event)">
            </mat-form-field>
          </div>
          <!--<div class="col-lg-4 col-12">
            <mat-checkbox [(ngModel)]="startdata.tipdana" (ngModelChange)="ndanachange($event)" name="tipdana">Dug stariji od</mat-checkbox><br />
            <mat-form-field appearance="fill" class="fullwidth">
              <mat-label>N dana</mat-label>
              <input matInput [(ngModel)]="ndana" placeholder="N dana" name="nadana" type="number" (ngModelChange)="dugpodanimachange($event)">
            </mat-form-field>
          </div>-->
          <div class="col-lg-4 col-12">
            <mat-checkbox [(ngModel)]="startdata.tipkartica" (ngModelChange)="dugkarticachange($event)" name="tipkartica">Dio prometa kartice</mat-checkbox><br />
            <mat-form-field appearance="fill" class="fullwidth">
              <mat-label>Od dana</mat-label>
              <input matInput type="date-local" [matDatepicker]="dp1" [ngModel]="startdata.oddana | date:'yyyy-MM-dd'" (ngModelChange)="startdata.oddana=$event" placeholder="Od dana" name="oddana">
              <mat-datepicker-toggle matIconSuffix [for]="dp1"></mat-datepicker-toggle>
              <mat-datepicker #dp1></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="fill" class="fullwidth">
              <mat-label>Do dana</mat-label>
              <input matInput type="date-local" [matDatepicker]="dp2" [ngModel]="startdata.dodana | date:'yyyy-MM-dd'" (ngModelChange)="startdata.dodana=$event" placeholder="Do dana" name="dodana">
              <mat-datepicker-toggle matIconSuffix [for]="dp2"></mat-datepicker-toggle>
              <mat-datepicker #dp2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class ="col-12">
            <div class="col-lg-4 col-12">
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Dug na dan</mat-label>
                <input matInput type="date-local" [matDatepicker]="dp3" [ngModel]="startdata.danduga | date:'yyyy-MM-dd'" (ngModelChange)="startdata.danduga=$event" placeholder="Na dan" name="danduga">
                <mat-datepicker-toggle matIconSuffix [for]="dp3"></mat-datepicker-toggle>
                <mat-datepicker #dp3></mat-datepicker>
              </mat-form-field>
            </div>
            <div *ngIf="login.vrstaUsluge" class="col-lg-4 col-12">
              <mat-form-field appearance="fill" class="fullwidth">
                <mat-label>Vrsta usluge</mat-label>
                <mat-select ngDefaultControl name="vrstaUsluge" [(ngModel)]="startdata.vrstausluge">
                  <mat-option value="">
                  </mat-option>
                  <mat-option value="V">
                    Voda
                  </mat-option>
                  <mat-option value="C">
                    Čistoća
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-4 col-12">
              <mat-radio-group aria-label="Saldo" [(ngModel)]="saldo">
                <mat-radio-button value="0">Saldo bez pokrenutih postupaka</mat-radio-button>
                <mat-radio-button value="1">Ukupni saldo (računi van valute)</mat-radio-button>
                <mat-radio-button value="2">Ukupni saldo (sa računima u valuti)</mat-radio-button>
              </mat-radio-group>
            </div>
</div>
        </div>
          <p>
            <button mat-button (click)="UcitajKorisnike()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Pretraži">done</mat-icon> Pretraži</button>
            <span *ngIf="loadingstart">
              <br />
              Učitavam...
              <img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
            </span>
          </p>
</mat-card>
    </div>


    <div *ngIf="lista" class="col-lg-6 col-12">
      <mat-card appearance="outlined">
        <mat-card-title>
          Pokretanje postupka za odabrane korisnike
        </mat-card-title>
        <mat-card-content>
          <mat-form-field *ngIf="listadokumenata" appearance="fill" class="col-lg-6 col-12">
            <mat-label>Dokument (status)</mat-label>
            <mat-select ngDefaultControl name="dokument" [(ngModel)]="startdata.dokumentId">
              <mat-option *ngFor="let dokument of listadokumenata" [value]="dokument.pravniDokumentVrstaId">
                {{dokument.sifra}} {{dokument.naziv}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-lg-4 col-12">
            <mat-label>Na dan</mat-label>
            <input matInput type="date" [ngModel]="startdata.nadan | date:'yyyy-MM-dd'" (ngModelChange)="startdata.nadan=$event" placeholder="Na dan" name="date">
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-lg-4 col-12">
            <mat-label>Rok za plaćanje</mat-label>
            <input matInput [(ngModel)]="startdata.rokdana" placeholder="Rok za plaćanje" name="dana" type="number">
          </mat-form-field>
          <div class="col-lg-4 col-12">
            <mat-checkbox [(ngModel)]="startdata.pamtiracune" name="pamtiracune">Pamti račune</mat-checkbox>
          </div>
          <p>
            <button mat-button (click)="openDialog()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Pokreni postupak">done</mat-icon> Pokreni postupak</button>
            <button mat-button (click)="pocetniuvjeti()" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">clear</mat-icon>Odustani</button><br />
          </p>
          <p *ngIf="loading">
            Pokrećem postupak...
            <img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </p>
          <p *ngIf="rezultat">
            {{rezultat}}
          </p>
        </mat-card-content>
      </mat-card>
    </div>
  </div>


  <div *ngIf="lista" class="d-flex justify-content-center row col-12 mt-1">
    <mat-card appearance="outlined" class="col-12">
      <span *ngIf="elpasedtime">Izvršeno za: {{elpasedtime}}s<br /></span>
      Broj odabranih: {{brojodabranih}}<br />
      <div class="d-flex justify-content-between">
        <mat-form-field appearance="fill">
          <mat-label>Traži</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
        </mat-form-field>
        <button mat-button (click)="OznaciSve()"><mat-icon *ngIf="!oznaci" aria-hidden="false" aria-label="Odustani"><span class="material-icons">check_box</span></mat-icon><mat-icon *ngIf="oznaci" aria-hidden="false" aria-label="Odustani"><span class="material-icons">check_box_outline_blank</span></mat-icon>Označi</button>
      </div>
      <table *ngIf="lista" mat-table [dataSource]="lista" multiTemplateDataRows matSort style="width:100%">


        <ng-container matColumnDef="sifra">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Šifra </th>
          <td mat-cell *matCellDef="let element"><span (click)="highlight(element)" class="material-icons pokazivac align-bottom">expand_more</span> {{element.sifra}} </td>
          <td mat-footer-cell *matFooterCellDef> Ukupno </td>
        </ng-container>

        <ng-container matColumnDef="ime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ime/Naziv </th>
          <td mat-cell *matCellDef="let element">{{element.ime}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="iznos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dug </th>
          <td mat-cell *matCellDef="let element" class="text-right">{{element.iznos | number: '1.2-2':'hr'}} </td>
          <td mat-footer-cell *matFooterCellDef class="text-right"> {{ukupno | number: '1.2-2':'hr'}} </td>          
        </ng-container>

        <ng-container matColumnDef="tipduga">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip duga </th>
          <td mat-cell *matCellDef="let element" class="text-right">{{element.vrstaDuga}} </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>



        <ng-container matColumnDef="pokrenipostupak">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Pokreni postupak </th>
          <td mat-cell *matCellDef="let element" style="width:10%" class="text-center"><mat-checkbox [(ngModel)]="element.pokrenipostupak" (change)="prebroj()" name="pokrenipostupak"></mat-checkbox></td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <mat-tab-group *ngIf="element.prikazikarticu" mat-align-tabs="start">
              <mat-tab label="Analitička kartica">
                <kartica [korisnik]="element.sifra" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></kartica>
              </mat-tab>
              <mat-tab label="Računi automatika">
                <otvoreni-racuni-id [korisnik]="element.sifra" [vrstausluge]="''"></otvoreni-racuni-id>
                <zatvoreni-racuni-id [korisnik]="element.sifra" [vrstausluge]="''"></zatvoreni-racuni-id>
              </mat-tab>
            </mat-tab-group>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" class="example-element-row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[ 10, 20,50]" showFirstLastButtons></mat-paginator>
    </mat-card>
  </div>
  <hr />
</div>
