import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { PosaljiPrijedlogModel } from 'src/app/_models/eOvrhe/PosaljiPrijedlogModel';



export interface DialogData {
  pitanje: string;
  pojasnjenje: string;
}

@Component({
  selector: 'PosaljiNaSudComponent',
  templateUrl: 'posalji-na-sud.component.html',
  styleUrls: ['posalji-na-sud.component.css']
})

export class PosaljiNaSudComponent {
  greska: string;
  getservice: httpservice;
  slanje: boolean = false;
  fileName: string = "";
  file:any;

  constructor(public dialogRef: MatDialogRef<PosaljiNaSudComponent>, @Inject(MAT_DIALOG_DATA) public data: PosaljiPrijedlogModel, dataservice: httpservice)
  {
    this.getservice = dataservice;
    this.data.identifikator = "";
    
  }


  KreirajPrijedlog() {
    this.slanje = true;
    const formData = new FormData();
    //formData.append("prijedlogModel", JSON.stringify(this.data));
    formData.append("file", this.file);

    this.getservice.PostRequestForm<Message>("komunalno/saldakonti/ovrhe/PosaljiPrijedlogNaSud?id="+this.data.id+"&identifikator="+this.data.identifikator, formData).subscribe(result => {
      if (result.status === "success") {
        this.dialogRef.close();
      }
      else {
        this.greska = result.data;
      }
      this.slanje = false;
    }, error => {
      this.greska = error;
      this.slanje = false;
});

  }
  
  onYesClick(): void {
    this.KreirajPrijedlog();
  }

  onFileSelected(event) {

    this.file = event.target.files[0];
    this.fileName = this.file.name;
  }

}



