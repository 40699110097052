
<div class="col-lg-6 col-12">
  <mat-card appearance="outlined">
    <h4>Uvjeti za korisnika</h4>
    <mat-form-field *ngIf="listaObveznika" appearance="fill" class="fullwidth">
      <mat-label>Obveznik</mat-label>
      <mat-select (selectionChange)="DohvatiBrojKorisnika()" [(ngModel)]="obveznik" name="obveznici">
        <mat-option *ngFor="let obveznik of listaObveznika" [value]="obveznik.sifra">
          {{obveznik.sifra}} {{obveznik.naziv}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="listaZona" appearance="fill" class="fullwidth">
      <mat-label>Zona</mat-label>
      <mat-select (selectionChange)="DohvatiBrojKorisnika()" [(ngModel)]="zona" name="zone">
        <mat-option *ngFor="let zona of listaZona" [value]="zona.sifra">
          {{zona.sifra}} {{zona.naziv}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="listaUlica" appearance="fill" class="fullwidth">
      <mat-label>Ulica</mat-label>
      <mat-select (selectionChange)="DohvatiBrojKorisnika()" [(ngModel)]="ulica" name="ulica">
        <mat-option *ngFor="let ulica of listaUlica" [value]="ulica.sifra">
          {{ulica.sifra}} {{ulica.naziv}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <h4>Broj korisnika koji zadovoljavaju uvjet: {{brojkorisnika}}</h4>
  </mat-card>
</div>
