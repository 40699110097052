import { formatDate } from '@angular/common';
import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';
import { TroskoviKarticaComponent } from '../../../../financije/bi/troskovi/troskovi-kartica/troskovi-kartica.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'troskovi-promet',
  templateUrl: 'troskovi-promet.component.html',
  styleUrls: ['troskovi-promet.component.css']
})

export class TroskoviPrometComponent implements OnInit {
  getservice: httpservice;
  oddana: Date;
  dodana: Date;
  lista: any;
  duguje: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  ukupno: number;
  potrazuje: number;
  loginService: LoginService;
  login: User;
  displayedColumns: string[] = ['trosak', 'opis', 'mjesec1', 'mjesec2', 'mjesec3', 'mjesec4', 'mjesec5', 'mjesec6', 'mjesec7', 'mjesec8', 'mjesec9', 'mjesec10', 'mjesec11','mjesec12','ukupno'];
  chartOption: any;
  chartOption2: any;
  podaciI: number[];
  podaciII: number[];
  podaciIII: number[];
  podaciIV: number[];  
  oznake: string[];
  

  constructor(dataservice: httpservice, loginservice: LoginService, @Inject(LOCALE_ID) private locale: string) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.dodana = new Date();
    this.oddana = new Date(this.dodana.getFullYear(), 0, 1);
    
    this.loginService.currentUser.subscribe(data => {
      this.login = data;
      if (data) {
        this.UcitajPromet();
      }
    });

  }




  UcitajPromet() {
    try {
      if (this.duguje) {
        this.duguje.forEach((e) => e = 0);
      }
      this.getservice.GetRequest<Message>("financije/bi/troskovi/troskovipromet?oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)).subscribe(result => {
          if (result.status === "success") {
            this.podaciI = [];
            this.podaciII = [];
            this.podaciIII = [];
            this.podaciIV = [];
            this.oznake = [];
            this.ukupno = 0;
            this.lista = (Object.values(result.data));
            this.lista.forEach((element) => {
              this.duguje[0] += element.mjesec1;
              this.duguje[1] += element.mjesec2;
              this.duguje[2] += element.mjesec3;
              this.duguje[3] += element.mjesec4;
              this.duguje[4] += element.mjesec5;
              this.duguje[5] += element.mjesec6;
              this.duguje[6] += element.mjesec7;
              this.duguje[7] += element.mjesec8;
              this.duguje[8] += element.mjesec9;
              this.duguje[9] += element.mjesec10;
              this.duguje[10] += element.mjesec11;
              this.duguje[11] += element.mjesec12;
              this.podaciI.push(element.mjesec1 + element.mjesec2 + element.mjesec3);
              this.podaciII.push(element.mjesec4 + element.mjesec5 + element.mjesec6);
              this.podaciIII.push(element.mjesec7 + element.mjesec8 + element.mjesec9);
              this.podaciIV.push(element.mjesec10 + element.mjesec11 + element.mjesec12);
              this.oznake.push(element.trosak);
              element.prikazikarticu = false;
            });
            for (var i = 0; i < 12; i++) {
              this.duguje[i]=parseFloat(this.duguje[i].toFixed(2))
              this.ukupno += this.duguje[i];
            }
            this.chartOption = {
              title: {
                text: 'Struktura troškova',
                left: 'center'
              },
              tooltip: {
                trigger: 'item'
              },
              xAxis: 
                {
                  type: 'category',
                  data: this.oznake                
                },              
              yAxis: 
                { type: 'value' },              
              series: [
                {
                  name: 'I. kvartal',
                  type: 'bar',
                  stack: 'total',
                  barWidth: '80%',
                  data: this.podaciI,
                  emphasis: {
                    focus: 'series'                    
                  }
                },
                {
                  name: 'II. kvartal',
                  type: 'bar',
                  stack: 'total',
                  barWidth: '80%',
                  data: this.podaciII,
                  emphasis: {
                    focus: 'series'                    
                  }
                },
                {
                  name: 'III. kvartal',
                  type: 'bar',
                  stack: 'total',
                  barWidth: '80%',
                  data: this.podaciIII,
                  emphasis: {
                    focus: 'series'                    
                  }
                },
                {
                  name: 'IV. kvartal',
                  type: 'bar',
                  stack: 'total',
                  barWidth: '80%',
                  data: this.podaciIV,
                  emphasis: {
                    focus: 'series'                    
                  }
                }
              ]
            };
            this.chartOption2 = {
              title: {
                text: 'Troškovi po mjesecima',
                left: 'center'
              },
              tooltip: {
                trigger: 'item'                
              },
              xAxis:
              {
                type: 'category',
                data: ["1","2","3","4","5","6","7","8","9","10","11","12"]
              },
              yAxis:
                { type: 'value' },
              series: [
                {
                  name: 'Mjesec',
                  type: 'bar',
                  barWidth: '80%',
                  data: this.duguje,
                  emphasis: {
                    focus: 'series'
                  }                  
                }
              ]
            }; 
          }
        }, error => console.error('Get karticu', error));
      }    
    catch (error) {
      console.error('Log error', error);
    }
  }

  Ispis() {    
    this.getservice.GetRequestFile<Blob>("financije/bi/troskovi/troskoviprometpdf?oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)).subscribe((data: Blob) => {
        var file = new Blob([data], { type: 'application/pdf' })
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (error) => {
        console.log('getPDF error: ', error);
      });
  }

  Export() {
    this.getservice.GetRequestFile<Blob>("financije/bi/troskovi/troskoviprometexcel?oddana=" + formatDate(this.oddana, "yyyy-MM-dd", this.locale) + "&dodana=" + formatDate(this.dodana, "yyyy-MM-dd", this.locale)).subscribe((data: Blob) => {
      var file = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }, (error) => {
      console.log('getPDF error: ', error);
    });
  }

  kartica(row) {
    if (row.prikazikarticu) {
      row.prikazikarticu = false;
    }
    else {
      row.prikazikarticu = true;
    }
  }
}




