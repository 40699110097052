import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

@Component({
  selector: 'upute-utuzivanje',
  templateUrl: './upute-utuzivanje.component.html'
})

export class UputeUtuzivanjeComponent implements OnInit {
  

  constructor() {    
  }

  ngOnInit() {
    
  }  
  
}
