<div class="addressbar">
  <span class="addresstext">Komunalno / Ovrhe</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Pregled prijedloga
    </mat-card-title>

    <p *ngIf="!lista"><em>Učitavam...</em></p>

    <hr />
    <input type="hidden" id="Cert" value="" [(ngModel)]="certifikat" />
    <div>
      <mat-form-field>
        <mat-label>Stranica</mat-label>
        <input matInput placeholder="Stranica" [(ngModel)]="page">
      </mat-form-field>&nbsp;
      <mat-form-field>
        <mat-label>Veličina</mat-label>
        <input matInput placeholder="Veličina" [(ngModel)]="size">
      </mat-form-field>&nbsp;
      <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>
        <mat-select ngDefaultControl name="status" [(ngModel)]="status">
          <mat-option value="NACRT">
            NACRT
          </mat-option>
          <mat-option value="POSLAN_NA_SUD">
            POSLAN NA SUD
          </mat-option>
        </mat-select>
      </mat-form-field>&nbsp;
      <button mat-stroked-button (click)="Ucitaj()" class="align-items-center ok-color"><mat-icon aria-hidden="false" aria-label="Osvježi">refresh</mat-icon> Osvježi</button>&nbsp;
      <p>
        <button (click)="DohvatiPdf()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Dohvati pdf">refresh</mat-icon> Dohvati PDF</button>&nbsp;
        <button (click)="DohvatiAnaPdf()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Dohvati pdf analitičke kartice">refresh</mat-icon> Dohvati prilog</button>&nbsp;
        <button (click)="openDialog()" mat-stroked-button class="delete-color"><mat-icon aria-hidden="false" aria-label="Brisanje">delete</mat-icon> Obustavi postupak</button>&nbsp;
        <button (click)="PosaljiNaSudPrijedlog()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Slanje na sud">mail</mat-icon> Pošalji na sud</button>&nbsp;
        <button (click)="KreirajZahtjev()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Kreiraj zahtjev za naplatu">mail</mat-icon> Kreiraj zahtjev za naplatu</button>
      </p>
    </div>

    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" multiTemplateDataRows matSort style="width:100%">

        <ng-container matColumnDef="jedinstveniIdentifikator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Identifikator </th>
          <td mat-cell *matCellDef="let element"> {{element.jedinstveniIdentifikator}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="vrijemeKreiranja">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum </th>
          <td mat-cell *matCellDef="let element"> {{element.vrijemeKreiranja | date: 'dd.MM.yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="ovrsenik">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ovršenik </th>
          <td mat-cell *matCellDef="let element"><span>{{element.ovrsenik}}</span></td>
        </ng-container>

        <!-- *ngIf="element.ovrsenici?.length" {{element.ovrsenici?.[0].ime}} {{element.ovrsenici?.[0].prezime}} -->
        <ng-container matColumnDef="iznos">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Iznos </th>
          <td mat-cell *matCellDef="let element"> {{element.iznos | number: '1.2-2':'hr'}} </td>
        </ng-container>

        <ng-container matColumnDef="ovrhovoditelj">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ovrhovoditelj </th>
          <td mat-cell *matCellDef="let element"><span>{{element.ovrhovoditelj}}</span></td>
          &#125;
        </ng-container>



        <!-- *ngIf="element.ovrsenici?.length" {{element.ovrhovoditelji?.[0].naziv}}  -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" (click)="highlight(row)" [ngClass]="{highlight: selectedrow.jedinstveniIdentifikator === row.jedinstveniIdentifikator}" class="example-element-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[ 10,20,50]" showFirstLastButtons></mat-paginator>
      </div>
  </mat-card>
  <hr />
  </div>
