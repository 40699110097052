import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Firma } from 'src/app/_models/firma';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';
import { Korisnik } from '../../_models/korisnik';

@Component({
  selector: 'korisnik',
  templateUrl: './korisnik.component.html',
  styleUrls: ['./korisnik.component.css']
})
export class KorisnikComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['sifra','ime', 'obveznik','zona','ulica','dodatnaAdresa'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  lista : any;


  constructor(dataservice: httpservice, public dialog: MatDialog, private router: Router) {
    this.getservice = dataservice;
    
  }
  ngOnInit() {

  
    try {
      this.getservice.GetRequest<Message>("komunalno/korisnik/List").subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource<Korisnik>(Object.values(result.data));
          this.lista.paginator = this.paginator;
          setTimeout(() => { this.lista.sort = this.sort; this.paginator._intl = new MatPaginatorIntlCro() }, 2000);
          
          if (this.lista.data.length > 0) {
            this.selectedrow = this.lista.data[0];
          }
        }
      }, error => console.error('Get korisnike',error));
    }
    catch (error) {
      console.error('Log error', error);
    }   
  }
  

  highlight(row) {
    this.selectedrow = row;
  }

 
  


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }  
}


