import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';
import { DokumentVrstaAddComponent } from 'src/app/Komunalno/SaldaKonti/Utuzivanje/DokumentVrsta/dokumentvrstaAdd.component';
import { PravniDokument } from 'src/app/_models/Utuzivanje/pravnidokument';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { KarticaKomboComponent } from '../../../../Komunalno/SaldaKonti/Utuzivanje/kartica-kombo/kartica-kombo.component';
import { OtvoreniRacuniComponent } from '../../../../Komunalno/SaldaKonti/otvoreni-racuni/otvoreni-racuni.component';
import { ZatvoreniRacuniComponent } from '../../../../Komunalno/SaldaKonti/zatvoreni-racuni/zatvoreni-racuni.component';
import { DokumentRacuniComponent } from '../../../../Komunalno/SaldaKonti/Utuzivanje/dokument-racuni/dokument-racuni.component';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/_models/user';
import { ZahtjeviRezultat } from '../../../../_models/ovrhe';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'pregled-zahtjeva',
  templateUrl: './pregled-zahtjeva.component.html'
})
export class PregledZahtjevaComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['jedinstveniIdentifikator', 'status', 'vrijemeKreiranja','ovrsenik','ovrhovoditelj'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  lista: any;
  showAdd: boolean;
  currentItem: 0;
  aktivan: string = "2";
  listadokumenata: any;
  vrstadokumentaid = 0;
  loginService: LoginService;
  login: User;
  vrstaUsluge: string = "";
  certifikat: string = "";
  lozinka: string = "";
  page = 0;
  size = 50;
  
  constructor(dataservice: httpservice, loginservice: LoginService, public dialog: MatDialog, private router: Router, private _snackBar: MatSnackBar) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }
  ngOnInit() {
    this.showAdd = false;
    this.Ucitaj();
    
  }
  
  Ucitaj() {
    this.lista = null;
    this.getservice.GetRequest<Message>("komunalno/saldakonti/ovrhe/zahtjevi?page="+this.page+"&size="+this.size).subscribe(result => {
        if (result !== null) {
          this.lista = new MatTableDataSource<ZahtjeviRezultat>(Object.values(result.data));
          this.lista.paginator = this.paginator;
          setTimeout(() => this.lista.sort = this.sort, 2000);
          this.paginator._intl = new MatPaginatorIntlCro();
          if (this.lista.data.length > 0) {
            this.selectedrow = this.lista.data[0];
          }
        }
      }, error => console.error('Get zahtjev', error));    
  }

  //UcitajCertifikat() {
  //  SignerDigital.getSelectedCertificate(certThumbPrint = "", showExpired = false, keyUsageFilter = 128)
  //}



  highlight(row) {
    this.selectedrow = row;    
  }

  kartica(row) {
    if (row.prikazikarticu) {
      row.prikazikarticu = false;
    }
    else {
      row.prikazikarticu = true;
    }
  }



  add() {
    this.currentItem = 0;
    this.showAdd = true;
  }

  
  openDialog() {
    if (this.selectedrow) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Obustavljanje postupka ' + this.selectedrow.jedinstveniIdentifikator
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ObustaviPostupak();
        }
      });
    }
  }

  ObustaviPostupak() {
    var ind = this.lista.data.findIndex(function checkData(data) {
      return data.jedinstveniIdentifikator == this.selectedrow.jedinstveniIdentifikator;
    }, this);
    if (ind > -1) {
      this.getservice.PostRequest<Message>("komunalno/saldakonti/ovrhe/ObrisiZahtjev", this.selectedrow.jedinstveniIdentifikator).subscribe(result => {
        if (result.status === "success") {
          this.lista.data.splice(ind, 1);
          this.lista._updateChangeSubscription();
          this.PostupakZavrsen("Prijedlog uspješno obrisan", "Uredu");
        }
      });
    }
  }


  EditData() {
    if (this.selectedrow) {      
      this.currentItem = this.selectedrow.jedinstveniIdentifikator;
      this.showAdd = true;
      //this.router.navigate(['/komunalno/saldakonti/utuzivanje/dokument/add', this.selectedrow.pravniDokumentVrstaId]);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }


  SlanjeNaSud() {
    if (this.selectedrow) {
      if (this.selectedrow.status == "NACRT") {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            pitanje: 'Da li ste sigurni ?',
            pojasnjenje: 'Slanje prijedloga ' + this.selectedrow.jedinstveniIdentifikator + ' na sud.'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.PosaljiNaSud();
          }
        });
      }
      else {
        if (this.selectedrow.status == "POSLAN_NA_SUD") {
          this.PostupakZavrsen("Prijedlog je već poslan na sud.", "Uredu");
        }
      }
    }
  }


  PosaljiNaSud() {
    var ind = this.lista.data.findIndex(function checkData(data) {
      return data.pravniDokumentId == this.selectedrow.pravniDokumentId;
    }, this);
    if (ind > -1) {
      this.getservice.PostRequest<Message>("komunalno/saldakonti/ovrhe/PosaljiPrijedlogNaSud/", this.selectedrow.jedinstveniIdentifikator).subscribe(result => {
        if (result.status === "success") {
          this.lista.data.splice(ind, 1);
          this.lista._updateChangeSubscription();
          this.PostupakZavrsen("Prijedlog uspješno poslan na sud", "Uredu");
        }
        else {
          this.PostupakZavrsen("Greška kod slanja na sud: " + result.data, "Uredu");
        }
      });
    }
  }

  DohvatiPdf() {
    var ind = this.lista.data.findIndex(function checkData(data) {
      return data.pravniDokumentId == this.selectedrow.pravniDokumentId;
    }, this);
    if (ind > -1) {
      this.getservice.GetRequestFile<Blob>("komunalno/saldakonti/ovrhe/ZahtjevPdf?id=" + this.selectedrow.jedinstveniIdentifikator).subscribe((data: Blob) => {
        if (data != null) {
          this.PostupakZavrsen("Pdf zahtjeva uspješno dohvaćen", "Uredu");
          var file = new Blob([data], { type: 'application/pdf' })
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }
        else {
          this.PostupakZavrsen("Greška kod preuzimanja pdfa: " + this.selectedrow.pravniDokumentId, "Uredu");
        }
      }, error => console.error('Get zahtjev', error));
    }
  }

  PosaljiNaProvjeru() {

  }

  PosaljiUFinu() {

  }

  PostupakZavrsen(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}


