<div class="addressbar">
  <span class="addresstext">Sustav / Licence</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Licence
    </mat-card-title>

    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />

    <mat-card-actions>
    <button (click)="EditData()" mat-stroked-button class="edit-color"><mat-icon aria-hidden="false" aria-label="Ispravak">edit</mat-icon> Ispravak</button>&nbsp;
    <button (click)="openDialog()" mat-stroked-button class="delete-color"><mat-icon aria-hidden="false" aria-label="Brisanje">delete</mat-icon> Briši</button>&nbsp;
    <button (click)="UcitajPodatke()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Osvježi">update</mat-icon> Učitaj podatke</button><br />
      </mat-card-actions>
    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Klijent </th>
          <td mat-cell *matCellDef="let element"> {{element.client}} </td>
        </ng-container>

        <ng-container matColumnDef="datumKreiranja">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum kreiranja </th>
          <td mat-cell *matCellDef="let element"> {{element.datumKreiranja | date: 'dd.MM.yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="datumIsteka">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum isteka </th>
          <td mat-cell *matCellDef="let element"> {{element.datumIsteka | date: 'dd.MM.yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="produzi">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Produži </th>
          <td mat-cell *matCellDef="let element"><mat-checkbox disabled [(ngModel)]="element.produzi"></mat-checkbox></td>
        </ng-container>
        <ng-container matColumnDef="produziDana">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Produži dana </th>
          <td mat-cell *matCellDef="let element"> {{element.produziDana}} </td>
        </ng-container>
        <ng-container matColumnDef="diskSerialNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Serijski broj diska </th>
          <td mat-cell *matCellDef="let element"> {{element.diskSerialNumber}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" [ngClass]="{highlight: selectedrow.id === row.id}" (click)="highlight(row)"></tr>
      </table>
      <mat-paginator [pageSizeOptions]="[ 10, 20,50]" showFirstLastButtons></mat-paginator>
      </div>
  </mat-card>
  <hr />
  </div>
