<div class="addressbar">
  <span class="addresstext">Komunalno / Salda-konti / Početno stanje</span>
</div>
<div class="containerpadding">
  <div class="row">
    <h1 class="col-12">Razdvajanje početnog stanja u saldu po godinama</h1> 
    <button *ngIf="!start" mat-button (click)="PokreniPostupak()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Pokreni">done</mat-icon> Pokreni</button><br />
    <mat-progress-bar *ngIf="start" style="height:25px;"
                      mode="determinate"
                      [value]="progressvalue">
    </mat-progress-bar>
    <div *ngIf="start" class="text-center col-12">
      {{current}}/{{count}} - {{progressvalue | number: '1.2-2':'hr'}}%
      <br />
      <div *ngIf="start" class="text-center">
        {{message}}
      </div>
      <br />
      <button mat-button (click)="PrekiniPostupak()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Pokreni">done</mat-icon> Odustani</button>
    </div>
  </div>
</div>
