import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { RequestList,Request } from 'src/app/_models/request';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  getservice: httpservice;
  lista: RequestList[];
  listazatvoreni: RequestList[];
  page: number;
  noviZahtjev: boolean;
  ispravakZahtjeva: boolean;
  ispravakNovog: boolean;
  currentItem: any;
  changelog: any;

  constructor(dataservice: httpservice, private router: Router) {
    this.getservice = dataservice;
    this.page = 1;
  }
  ngOnInit() {
    this.noviZahtjev = false;
    this.ispravakZahtjeva = false;
    this.ispravakNovog = false;
    this.UcitajChangeLog();
    this.UcitajOtvorene();
    this.UcitajZatvorene();
  }

  NoviZahtjev() {
    this.noviZahtjev = true;
  }
  IspraviZahtjev(id) {
    this.currentItem = id;
    this.ispravakZahtjeva = true;
  }
  IspraviNoviZahtjev(id) {
    this.currentItem = id;
    this.ispravakNovog = true;
  }


  addItem(newItem: Request) {
    this.noviZahtjev = false;
    if (newItem.id > 0) {
      this.UcitajOtvorene();
      }      
  }

  editItem(newItem: Request) {
    this.ispravakZahtjeva = false;   
      this.UcitajZatvorene();   
  }

  editnewItem(newItem: Request) {
    this.ispravakNovog = false;    
      this.UcitajOtvorene();    
  }
  

  UcitajChangeLog() {
    this.getservice.GetRequest<Message>("changelog/list").subscribe(result => {
      if (result.status === "success") {
        this.changelog = result.data;
      }
    }, error => console.error('Get zahtjev', error));
  }

  UcitajOtvorene() {
    this.getservice.GetRequest<Message>("request/listActive").subscribe(result => {
      if (result.status === "success") {
        this.lista = result.data;
      }
    }, error => console.error('Get zahtjev', error));
  }
  UcitajZatvorene() {
    this.getservice.GetRequest<Message>("request/listClosed/?page=" + this.page).subscribe(result => {
      if (result.status === "success") {
        this.listazatvoreni = result.data;
      }
    }, error => console.error('Get zahtjev', error));
  }

  OsvjeziZatvorene() {
    this.page = 1;
    this.listazatvoreni = null;
    this.UcitajZatvorene();
  }


  UcitajJos() {
    this.page++;
    this.getservice.GetRequest<Message>("request/listClosed/?page=" + this.page).subscribe(result => {
      if (result.status === "success") {
        var i = 0;
        var lista = result.data as RequestList[];
        lista.forEach(element => { this.listazatvoreni.push(element); });
        //for (i = 0; i < lista.length; i++) {
        //  this.listazatvoreni.push(lista[i]);
        //}
      }
    }, error => console.error('Get zahtjev', error));
  }

}
