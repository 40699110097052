import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, retry, catchError } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { UserToken, LoginModel, Message } from 'src/app/commons/commons.module';
import { User } from 'src/app/_models/user';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
}
)
export class LoginService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public LoginMessage: Observable<Message>;

    
  constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(window.localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
  }

  public set currentUserSetValue(value) {
    this.currentUserSubject.next(value);
    this.currentUser = this.currentUserSubject;
  }

  public Login(user: LoginModel) {        
      
    this.LoginMessage = new Observable((observer) => {
      let poruka: Message = { status: "Prijava", data: "Prijava u tijeku..." };
      observer.next(poruka);
      this.SendPost(user).subscribe(response => {
        if (response.status == "success") {
          // login successful if there's a jwt token in the response
          
          if (response.data.token) {
            let suser: User = { username: user.UserName, password: response.data.password, token: response.data.token, id: response.data.id, firstName: "", lastName: "", mail: "", role: response.data.role, aktivnaGodina: response.data.aktivnaGodina, aktivnaFirma: response.data.aktivnaFirma, konto: response.data.konto, vrstaUsluge: response.data.vrstaUsluge };
            localStorage.setItem('currentUser', JSON.stringify(suser));
            this.currentUserSubject.next(suser);
            this.currentUser = this.currentUserSubject;
            poruka.status = "OK";
            poruka.data = suser;
            observer.next(poruka);                     
          }         
        }
        else {
          poruka.status = "Error";
          poruka.data = response.data;
          observer.next(poruka);
        }
      });
    });
    return this.LoginMessage;

            //.pipe(retry(3), catchError(this.handleError<UserToken>('postLogim')));       
  }

  private SendPost(user: LoginModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'rejectUnauthorized': 'false'
      })
    };
    return this.http.post<Message>(environment.apiURL + "User/login", {
      "UserName": user.UserName,
      "Password": user.Password
    }, httpOptions);
  }





    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.log(`${operation} failed: ${error.message}`);

            return of(result as T);
        };
    }

    private log(message: string) {
        console.log(message);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
   
}


