import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Changelog } from 'src/app/_models/changelog';
import { Observable } from 'rxjs';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort,MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_dialog/dialog.component';
import { Router } from '@angular/router';
import { ChangeLogUnosComponent } from 'src/app/changelog/changelog-unos.component';

@Component({
  selector: 'changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css']
})
export class ChangeLogComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['id','date', 'description','version'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  lista: any;
  showAdd: boolean;
  currentItem: 0;


  constructor(dataservice: httpservice, public dialog: MatDialog, private router: Router) {
    this.getservice = dataservice;
    
  }
  ngOnInit() {

    this.showAdd = false;
    try {
      this.getservice.GetRequest<Message>("changelog/list").subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource<Changelog>(Object.values(result.data));
          this.lista.paginator = this.paginator;
          setTimeout(() => this.lista.sort = this.sort, 2000);
          this.paginator._intl = new MatPaginatorIntlCro();
          if (this.lista.data.length > 0) {
            this.selectedrow = this.lista.data[0];
          }
        }
      }, error => console.error('Get zahtjev', error));      
    }
    catch (error) {
      console.error('Log error', error);
    }   
  }




  highlight(row) {
    this.selectedrow = row;
  }

  add() {
    this.currentItem = 0;
    this.showAdd = true;
  }

  addItem(newItem: Changelog) {
    this.showAdd = false;
    if (newItem.id > 0) {
      var ind = this.lista.data.findIndex(function checkUser(user) {
        return user.id == newItem.id;
      }, this);
      if (ind > -1) {
        this.lista.data[ind] = newItem;
      }
      else {
        this.lista.data.push(newItem);
      }
      this.selectedrow = newItem;
      this.lista._updateChangeSubscription();
    }
  }

  openDialog() {
    if (this.selectedrow) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          pitanje: 'Da li ste sigurni ?',
          pojasnjenje: 'Brisanje zahtjeva ' + this.selectedrow.description
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.DeleteData();
        }
      });
    }
  }
  

  DeleteData() {
    var ind = this.lista.data.findIndex(function checkUser(user) {
      return user.id == this.selectedrow.id;
    },this);
    if (ind > -1) {
      this.getservice.PostRequest<Message>("changelog/Remove", this.selectedrow.id).subscribe(result => {
        if (result.status === "success") {

          this.lista.data.splice(ind, 1);
          this.lista._updateChangeSubscription();
        }
      });
    }
  }

  

  EditData() {
    if (this.selectedrow) {
      this.currentItem = this.selectedrow.id;
      this.showAdd = true;      
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }  
}


