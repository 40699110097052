import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort,MatSortModule } from '@angular/material/sort';
import { MatPaginatorIntlCro } from 'src/app/commons/commons.module';
import { Router } from '@angular/router';

@Component({
  selector: 'datalog',
  templateUrl: './datalog.component.html',
  styleUrls: ['./datalog.component.css']
})
export class DataLogComponent implements OnInit {
  getservice: httpservice;
  displayedColumns: string[] = ['id', 'date', 'username','firma',  'controller','operation','status'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedrow: any;
  lista: any;


  constructor(dataservice: httpservice, private router: Router) {
    this.getservice = dataservice;
    
  }
  ngOnInit() {

    this.UcitajPodatke();
   
  }


  UcitajPodatke() {
    try {
      this.getservice.GetRequest<Message>("system/list").subscribe(result => {
        if (result.status === "success") {
          this.lista = new MatTableDataSource(Object.values(result.data));
          this.lista.paginator = this.paginator;
          setTimeout(() => this.lista.sort = this.sort, 2000);
          this.paginator._intl = new MatPaginatorIntlCro();
          if (this.lista.data.length > 0) {
            this.selectedrow = this.lista.data[0];
          }
        }
      }, error => console.error('Get datalog', error));
    }
    catch (error) {
      console.error('Log error', error);
    }
  }

  highlight(row) {
    this.selectedrow = row;
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lista.filter = filterValue.trim().toLowerCase();
  }  
}


