<div class="addressbar">
  <span class="addresstext">Komunalno / Obrada dugovanja / Nastavak</span>
</div>
<div class="containerpadding">
  <div class="row">
    <div class="col-12">
      <mat-card-title>
        Nastavak procesa obrade dugovanja
      </mat-card-title>
      <hr />
    </div>
    <div *ngIf="!lista" class="col-lg-6 col-12">
      <mat-radio-group aria-label="Odabir uvjeta" [(ngModel)]="pojedinacnikorisnik" (ngModelChange)="onchangeKorisnik($event)">
        <mat-radio-button value="0">Više korisnika</mat-radio-button>
        <mat-radio-button class="ml-2" value="1">Jednog korisnika</mat-radio-button>
      </mat-radio-group>
      <mat-card appearance="outlined" *ngIf="pojedinacnikorisnik==='0'">
        <h4>Uvjeti za korisnika</h4>
        <mat-form-field *ngIf="listaObveznika" appearance="fill" class="fullwidth">
          <mat-label>Obveznik</mat-label>
          <mat-select [(ngModel)]="qObveznik" name="obveznici">
            <mat-option *ngFor="let obveznik of listaObveznika" [value]="obveznik.sifra">
              {{obveznik.sifra}} {{obveznik.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="listaZona" appearance="fill" class="fullwidth">
          <mat-label>Zona</mat-label>
          <mat-select [(ngModel)]="qZona" name="zone">
            <mat-option *ngFor="let zona of listaZona" [value]="zona.sifra">
              {{zona.sifra}} {{zona.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="listaUlica" appearance="fill" class="fullwidth">
          <mat-label>Ulica</mat-label>
          <mat-select [(ngModel)]="qUlica" name="ulica">
            <mat-option *ngFor="let ulica of listaUlica" [value]="ulica.sifra">
              {{ulica.sifra}} {{ulica.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card>
      <mat-card appearance="outlined">
        <mat-form-field *ngIf="listadokumenata" appearance="fill" class="fullwidth">
          <mat-label>Dokument (status)</mat-label>
          <mat-select ngDefaultControl name="dokument" [(ngModel)]="odabranidokument">
            <mat-option *ngFor="let dokument of listadokumenata" [value]="dokument">
              {{dokument.sifra}} {{dokument.naziv}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div class="col-lg-4 col-12">
          <mat-radio-group aria-label="Saldo (iznos duga) dokumenta" [(ngModel)]="vrstasalda">
            <mat-radio-button value="0">Trenutni saldo (trenutni dug)</mat-radio-button>
            <mat-radio-button value="1">Saldo bez računa nakon kreiranja dokumenta</mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-checkbox [(ngModel)]="aktivan" name="aktivan">Aktivan</mat-checkbox>
        <mat-form-field *ngIf="login.vrstaUsluge" appearance="fill" class="fullwidth">
          <mat-label>Vrsta usluge</mat-label>
          <mat-select ngDefaultControl name="vrstaUsluge" [(ngModel)]="qVrstausluge">
            <mat-option value="">
              Sve
            </mat-option>
            <mat-option value="V">
              Voda
            </mat-option>
            <mat-option value="C">
              Čistoća
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card>
      <korisnik-select *ngIf="pojedinacnikorisnik==='1'" (podaci)="OdabirKorisnika($event)"></korisnik-select>
      <korisnik-info *ngIf="korisnik" [korisnik]="korisnik"></korisnik-info>
      <mat-card appearance="outlined">
        <p>
          <button mat-button (click)="UcitajKorisnike()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Pretraži">done</mat-icon> Pretraži</button>
        </p>
      </mat-card>
      <span *ngIf="loadingstart">
        <br />
        Učitavam...
        <img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </span>
    </div>
    <div *ngIf="lista" class="col-lg-9 col-12">
      <mat-card appearance="outlined">
        <mat-card-title>
          Nastavak postupka za odabrane korisnike
        </mat-card-title>
        <mat-card-content>
          <mat-form-field *ngIf="listadokumenata" appearance="fill" class="col-lg-3 col-12">
            <mat-label>Dokument (status)</mat-label>
            <mat-select ngDefaultControl name="dokument" [(ngModel)]="startdata.dokumentId">
              <mat-option *ngFor="let dokument of listadokumenata" [value]="dokument.pravniDokumentVrstaId">
                {{dokument.sifra}} {{dokument.naziv}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-lg-3 col-12">
            <mat-label>Na dan</mat-label>
            <input matInput type="date" [ngModel]="startdata.nadan | date:'yyyy-MM-dd'" placeholder="Na dan" name="date">
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-lg-3 col-12">
            <mat-label>Rok za plaćanje</mat-label>
            <input matInput [(ngModel)]="startdata.rokdana" placeholder="Rok za plaćanje" name="dana" type="number">
          </mat-form-field>
          <div class="col-lg-4 col-12">
            <mat-radio-group aria-label="Saldo (iznos duga) dokumenta" [(ngModel)]="startdata.tipiznosa">
              <mat-radio-button value="0">Trenutni saldo (trenutni dug)</mat-radio-button>
              <mat-radio-button value="1">Saldo (dug) sa prethodnog dokumenta</mat-radio-button>              
            </mat-radio-group>
          </div>
          <p>
            <button mat-button (click)="openDialog()" class="ok-color"><mat-icon aria-hidden="false" aria-label="Nastavi postupak">done</mat-icon> Pokreni postupak</button>
            <button mat-button (click)="pocetniuvjeti()" class="cancel-color"><mat-icon aria-hidden="false" aria-label="Odustani">clear</mat-icon>Odustani</button><br />
          </p>
          <p *ngIf="rezultat">
            {{rezultat}}
          </p>
        </mat-card-content>
      </mat-card>
    </div>


    <div *ngIf="lista" class="col-12 mt-1">
      <mat-card appearance="outlined" class="col-12">
        Korisnici sa statusom (dokumentom) {{odabranidokument.naziv}} - Broj odabranih: {{brojodabranih}}<br />
        <div class="d-flex justify-content-between">
          <mat-form-field appearance="fill">
            <mat-label>Traži</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
          </mat-form-field>
          <button mat-button (click)="OznaciSve()"><mat-icon *ngIf="!oznaci" aria-hidden="false" aria-label="Odustani"><span class="material-icons">check_box</span></mat-icon><mat-icon *ngIf="oznaci" aria-hidden="false" aria-label="Odustani"><span class="material-icons">check_box_outline_blank</span></mat-icon>Označi</button>
        </div>
        <table *ngIf="lista" mat-table [dataSource]="lista" multiTemplateDataRows matSort style="width:100%">


          <ng-container matColumnDef="sifra">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Šifra </th>
            <td mat-cell *matCellDef="let element"><span (click)="highlight(element)" class="material-icons pokazivac align-bottom">expand_more</span> {{element.sifra}} </td>
          </ng-container>

          <ng-container matColumnDef="ime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Ime/Naziv </th>
            <td mat-cell *matCellDef="let element">{{element.ime}}</td>
          </ng-container>

          <ng-container matColumnDef="datum">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dan pokretanja </th>
            <td mat-cell *matCellDef="let element">{{element.datum | date: 'dd.MM.yyyy'}}</td>
          </ng-container>

          <ng-container matColumnDef="iznos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Dug </th>
            <td mat-cell *matCellDef="let element" class="text-right">{{element.iznos | number: '1.2-2':'hr'}} </td>
          </ng-container>

          <ng-container matColumnDef="saldo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> Trenutni saldo </th>
            <td mat-cell *matCellDef="let element" class="text-right">{{element.saldo | number: '1.2-2':'hr'}} </td>
          </ng-container>

          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <kartica *ngIf="element.prikazikarticu" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'" [korisnik]="element.sifra"></kartica>
            </td>
          </ng-container>
          <ng-container matColumnDef="nastavipostupak">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Nastavi postupak </th>
            <td mat-cell *matCellDef="let element" style="width:10%" class="text-center"><mat-checkbox [(ngModel)]="element.nastavipostupak" (change)="prebroj()" name="nastavipostupak"></mat-checkbox></td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row;columns: displayedColumns;" class="example-element-row"></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[ 10, 20,50]" showFirstLastButtons></mat-paginator>
      </mat-card>
    </div>

  </div>
  <hr />
  </div>
