export class User {
  id: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  token?: string;
  mail: string;
  role: string;
  aktivnaGodina: number;
  aktivnaFirma: number;
  vrstaUsluge: boolean;
  konto: boolean;
}
