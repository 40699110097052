import { httpservice, Message } from 'src/app/commons/commons.module';
import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/_models/client';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { ClientFirmePost } from '../_models/clientfirmepost';
import { Firma } from '../_models/firma';


@Component({
  selector: 'klijentAdd',
  templateUrl: './klijentAdd.component.html'
})
export class KlijentAddComponent implements OnInit {
  getservice: httpservice;
  data: Client = {id:0,naziv:"",ulica:"",broj:"",mjesto:"",oib:"",iban:""};
  loading = false;
  submitted = false;
  status: string = "";
  returnUrl: string;
  error = '';
  editing = false;
  poruka = "Dodavanje";
  listafirmi: any[];
  odabraneFirme: number[];

  constructor(dataservice: httpservice, private router: Router, private route: ActivatedRoute, private location: Location) {
    this.getservice = dataservice;       
  }
  ngOnInit() {
    this.odabraneFirme = [];
    var idkor = this.route.snapshot.paramMap.get('id');
    if (idkor != null) {
      this.getClient(idkor);
    }
    else {
      idkor = "0";
    }
      try {
        this.getservice.GetRequest<Message>("Client/Firme?id=" + idkor).subscribe(result => {
          if (result.status === "success") {
            this.listafirmi = Object.values(result.data);
            setTimeout(() => { this.oznaciFirme(); });
          }
        }, error => console.error('Get zahtjev', error));
      }
      catch (error) {
        console.error('Log error', error);
      }
    
  }

  oznaciFirme() {
    for (var a = 0; a < this.listafirmi.length; a++) {
      if (this.listafirmi[a].selected) {
        this.odabraneFirme.push(this.listafirmi[a].id);
      }
    }
  }
  

  addClient() {
    this.submitted = true;
    this.loading = true;
    
      if (!this.editing) {
        this.getservice.PostRequest<Message>("Client/add", this.data).subscribe(result => {
          if (result.status === "success")
          {
            this.data.id = result.data;
            this.addFirme();            
          }
          else {
            console.error('Log error', result);
          }
        },
          error => console.error('Log error', error));
      }
      else {
        this.getservice.PostRequest<Message>("client/edit", this.data).subscribe(result => {
          if (result.status === "success") {
            this.addFirme(); 
          }
          else {
            console.error('Log error', result);
          }
        }, error => console.error('Log error',error));
      }        
  }

  addFirme() {

    if (this.odabraneFirme.length > 0)
    {
      let senddata: ClientFirmePost = { id: this.data.id, firme: this.odabraneFirme };


      this.getservice.PostRequest<Message>("Client/addFirme", senddata).subscribe(result => {
        if (result.status === "success")
        {
          this.router.navigate(['/klijent']);
        }
        else {
          console.error('Log error', result);
          this.editing = true;
        }
      },
        error => console.error('Log error', error));
      return false;
    }
    else
    {
      if (!this.editing)
      {
        // Dodavanje firme ako nema izabrane i dodavanje je klijenta
        this.DodajFirmu();
      }
    }
  }


  DodajFirmu() {
    var novafirma: Firma = { id: 0, sifra: "0001", naziv: this.data.naziv, ulica: this.data.ulica, broj: this.data.broj, mjesto: this.data.mjesto, oib: this.data.oib, iban: this.data.iban, bazaKomunalno: "", drzava: 1, eMail: "", jib: "", porezniBroj: "", telefon: "", ziroRacun: "", eOvrheAPIKey: "", ptt: "", konto: false, vrstaUsluge: false, eOvrheAddress: "", modulBI: false, modulFakturno: false, modulFinancije: false, modulGdpr: false, modulKomunalno: false, modulMaterijalno: false, modulOsnovna: false, modulPlace: false, sokopBanka: "", sokopFunkcija: "", sokopIme: "", sokopPrezime: "", sokopSud: "", sokopUlica: "" };

    this.getservice.PostRequest<Message>("Firma/add", novafirma).subscribe(result => {
      if (result.status === "success")
      {
        this.PoveziFirmu(result.data);        
      }
      else {
        console.error('Log error', result);
        this.editing = true;
      }
    }, error => console.error('Log error', error));
  }

  PoveziFirmu(id:number) {
    let odabranaFirma: number[] = [id];
    
    let senddata: ClientFirmePost = { id: this.data.id, firme: odabranaFirma };


    this.getservice.PostRequest<Message>("Client/addFirme", senddata).subscribe(result => {
      if (result.status === "success") {
        this.router.navigate(['/klijent']);
      }
      else {
        console.error('Log error', result);
        this.editing = true;
      }
    },
      error => console.error('Log error', error));
  }



  goBack() {
    this.location.back();
  }   

  getClient(id: string) {
    this.getservice.GetRequest<Message>("client/Get?id=" + id).subscribe(result => {
      if (result.status === "success") {
        this.editing = true;
        this.poruka = "Ispravak";
        this.data = result.data;
      }
    });
  }

}
