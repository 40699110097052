<div>
  <h1 mat-dialog-title>Kreiraj prijedlog dokumenta {{data.id}}</h1>
  <div mat-dialog-content class="col-12">
    <mat-form-field *ngIf="listaSudova" appearance="fill" class="fullwidth">
      <mat-label>Sud</mat-label>
      <mat-select [(ngModel)]="sud" name="sudovi">
        <mat-option *ngFor="let sud of listaSudova" [value]="sud.sifra">
          {{sud.sifra}} {{sud.naziv}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="listaBanaka" appearance="fill" class="fullwidth">
      <mat-label>Banka</mat-label>
      <mat-select  [(ngModel)]="banka" name="banka">
        <mat-option *ngFor="let banka of listaBanaka" [value]="banka.sifra">
          {{banka.sifra}} {{banka.naziv}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p *ngIf="slanje">Radim...<img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /></p>
    <p *ngIf="greska">{{greska}}</p>
  </div>
  <hr />
  <div mat-dialog-actions class="col-12 d-flex justify-content-center">
    <button mat-button (click)="KreirajPrijedlog()" class="ok-color"><mat-icon aria-hidden="false" aria-label="DA">check</mat-icon> Da</button>
    <button mat-button mat-dialog-close class="cancel-color"><mat-icon aria-hidden="false" aria-label="Ne">cancel</mat-icon> Ne</button>
  </div>
</div>
