import { Component, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { LoginService } from 'src/app/login/login.service';
import { Router } from '@angular/router';
import { User } from 'src/app/_models/user';
import { Observable } from 'rxjs';
import { httpservice, Message } from 'src/app/commons/commons.module';
import { Firma } from '../_models/firma';
import { UserSettingsComponent } from '../users/users-settings.component';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';






@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html'  
})

export class MainMenuComponent implements OnInit {
  getservice: httpservice;
    login: User;
    loginService: LoginService;
    logiran = false;
    firmapodaci: Firma;

  constructor(loginservice: LoginService, public dialog: MatDialog, dataservice: httpservice, private router: Router) {
    this.getservice = dataservice;
    this.loginService = loginservice;
  }

  ngOnInit() {
    this.firmapodaci = { id: 0, bazaKomunalno: "", broj: "", drzava: 1, eMail: "", iban: "", jib: "", mjesto: "", naziv: "", oib: "", porezniBroj: "", ptt: "", sifra: "", telefon: "", ulica: "", ziroRacun: "", eOvrheAPIKey: "", vrstaUsluge: false, konto: false, eOvrheAddress: "", modulBI: false, modulFakturno: false, modulFinancije: false, modulGdpr: false, modulKomunalno: false, modulMaterijalno: false, modulOsnovna: false, modulPlace: false, sokopBanka: "", sokopFunkcija: "", sokopIme: "", sokopPrezime: "", sokopSud: "", sokopUlica: "" }
      this.loginService.currentUser.subscribe(data => {
          this.login = data;
        if (data) {
          this.UcitajPodatkeoFirmi();
            if (data.role == "SuperUser") {
            }  
          }
        });
  }

  UcitajPodatkeoFirmi() {
    this.getservice.GetRequest<Message>("firma/get?id="+this.login.aktivnaFirma).subscribe(result => {
      if (result.status === "success") {
        this.firmapodaci = result.data;
        
      }
    }, error => console.error('Get firma', error));
  } 
    logout() {
        this.loginService.logout();
      this.logiran = false;      
      this.router.navigate(['/login']);
    }

  openUserSettings() {
    const dialogRef = this.dialog.open(UserSettingsComponent, {
      data: this.login
    }
      );

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          
        }
      });
    }
  }




