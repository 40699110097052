import { Component, Inject, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { UserToken } from 'src/app/commons/commons.module';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';





@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    loading = false;
    submitted = false;
    login: LoginService;
    status: string = "";
    podaci: UserToken = { token: "", expiration: "" };
    nekitekst: string = "Prvi tekst";
    username: string = "";
    password: string = "";
    returnUrl: string;
    error = '';

    constructor(loginservice: LoginService, private router: Router, private route: ActivatedRoute) {
        this.login = loginservice;        
    }

    ngOnInit() {
        
        // reset login status
        this.login.logout();

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    


    //public ulogirajse() {
    //    this.login.Login({ UserName: this.username, Password: this.password }).subscribe(data =>
    //    {
    //        if (data === undefined) {
    //            this.OsvjeziStatus("Neuspješna prijava");
    //            }
    //        else {
    //            this.podaci.token = data.token;
    //            this.podaci.expiration = data.expiration;
    //            this.OsvjeziStatus("Uspješna prijava");
    //        }
    //    },
    //        error => {
    //            this.OsvjeziStatus("Neuspješna prijava");
    //            console.log(error);
    //        }
    //    );        
    //}

  ulogirajse() {
    if (this.username != "" && this.password != "") {
      this.error = "";
      this.submitted = true;
      this.loading = true;
      this.login.Login({ UserName: this.username, Password: this.password }).subscribe(data =>

      //.pipe(first())
      {
        if (data) {
          if (data.status == "OK") {
            if (data.data) {
              this.router.navigate([this.returnUrl]);
            }
            else {
              this.loading = false;
              this.error = "Neispravno korisničko ime ili lozinka.";
            }
          }
          else {
            if (data.status == "Error") {
              this.loading = false;
              this.error = data.data;
            }
            else {
              this.error = "Prijava u tijeku...";
            }
          }

        }
        else {
          this.loading = false;
          this.error = "Greška prilikom prijave";
        }
      });
    }
    else {
      this.error = "Upišite korisničko ime i lozinku";
    }
  }


    private OsvjeziStatus(stat:string) {
        this.status = stat;
    }

    public promjenitekst() {
        this.nekitekst = this.podaci.expiration;
    }
    
}

