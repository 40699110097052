  <div class="navbar navbar-light">
    <div>
      <button type="button" mat-button (click)="drawer.toggle()" class="edit-color">
        <mat-icon aria-hidden="false" aria-label="Izbornik">menu</mat-icon>
      </button>
      <a class="navbar-brand" [routerLink]="['/home']">Matsys portal</a>      
    </div>
  <main-menu></main-menu>
</div>
<mat-drawer-container autosize>
  
  
  <mat-drawer #drawer mode="side" class="leftpanel" opened="true">
    <app-nav-menu>
    </app-nav-menu>
  </mat-drawer>

  <div class="container">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
<footer><span>Portal © 2021 Matsys d.o.o.</span><span class="ml-auto">Verzija 1.0</span></footer>
 
