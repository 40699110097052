<div class="addressbar">
  <span class="addresstext"> Matični / Pdv</span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Pdv stope
    </mat-card-title>

    <p *ngIf="!lista"><em>Učitavam...</em></p>
    <hr />


    <button (click)="add()" mat-stroked-button class="add-color"><mat-icon aria-hidden="false" aria-label="Dodavanje">add</mat-icon> Novi</button>&nbsp;
    <button (click)="EditData()" mat-stroked-button class="edit-color"><mat-icon aria-hidden="false" aria-label="Ispravak">edit</mat-icon> Ispravak</button>&nbsp;
    <button (click)="openDialog()" mat-stroked-button class="delete-color"><mat-icon aria-hidden="false" aria-label="Brisanje">delete</mat-icon> Briši</button><br />

    <pdvAdd *ngIf="showAdd" [item]="currentItem" (newItemEvent)="addItem($event)"></pdvAdd>
    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <table *ngIf="lista" mat-table [dataSource]="lista" matSort style="width:100%">

      <ng-container matColumnDef="sifra">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Šifra </th>
        <td mat-cell *matCellDef="let element"> {{element.sifra}} </td>
      </ng-container>

      <ng-container matColumnDef="naziv">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Naziv </th>
        <td mat-cell *matCellDef="let element"> {{element.naziv}} </td>
      </ng-container>

      <ng-container matColumnDef="postotak">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Stopa </th>
        <td mat-cell *matCellDef="let element"> {{element.postotak}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row;columns: displayedColumns;" [ngClass]="{highlight: selectedrow.pdvId === row.pdvId}" (click)="highlight(row)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[ 10, 20,50]" showFirstLastButtons></mat-paginator>
  </mat-card>
  </div>
