<div class="addressbar">
  <span class="addresstext">Komunalno / Ovrhe </span>
</div>
<div class="containerpadding">
  <mat-card appearance="outlined">
    <mat-card-title>
      Pregled zahtjeva
    </mat-card-title>

    <p *ngIf="!lista"><em>Učitavam...</em></p>

    <hr />
    <input type="hidden" id="Cert" value="" [(ngModel)]="certifikat" />
    <div>
      <mat-form-field>
        <mat-label>Stranica</mat-label>
        <input matInput placeholder="Stranica" [(ngModel)]="page">
      </mat-form-field>&nbsp;
      <mat-form-field>
        <mat-label>Veličina</mat-label>
        <input matInput placeholder="Veličina" [(ngModel)]="size">
      </mat-form-field>&nbsp;
      <button mat-stroked-button (click)="Ucitaj()" class="align-items-center ok-color"><mat-icon aria-hidden="false" aria-label="Osvježi">refresh</mat-icon> Osvježi</button>&nbsp;
      <p>
        <button (click)="DohvatiPdf()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Dohvati pdf">refresh</mat-icon> Dohvati PDF</button>&nbsp;
        <button (click)="openDialog()" mat-stroked-button class="delete-color"><mat-icon aria-hidden="false" aria-label="Brisanje">delete</mat-icon> Obriši zahtjev</button>&nbsp;
        <button (click)="PosaljiNaProvjeru()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Provjera na fini">mail</mat-icon> Provjera na fini</button>&nbsp;
        <button (click)="PosaljiUFinu()" mat-stroked-button class="ok-color"><mat-icon aria-hidden="false" aria-label="Slanje u finu">mail</mat-icon> Slanje u finu</button>
      </p>
    </div>

    <mat-form-field>
      <mat-label>Traži</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Tekst">
    </mat-form-field>
    <div class="mat-elevation-z6">
      <table *ngIf="lista" mat-table [dataSource]="lista" multiTemplateDataRows matSort style="width:100%">

        <ng-container matColumnDef="jedinstveniIdentifikator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Identifikator </th>
          <td mat-cell *matCellDef="let element"> {{element.jedinstveniIdentifikator}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        <ng-container matColumnDef="vrijemeKreiranja">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Datum </th>
          <td mat-cell *matCellDef="let element"> {{element.vrijemeKreiranja | date: 'dd.MM.yyyy'}} </td>
        </ng-container>
        <ng-container matColumnDef="ovrsenik">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ovršenik </th>
          <td mat-cell *matCellDef="let element"><span *ngIf="element.ovrsenici?.length">{{element.ovrsenici?.[0].ime}} {{element.ovrsenici?.[0].prezime}}</span></td>
        </ng-container>

        <ng-container matColumnDef="ovrhovoditelj">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ovrhovoditelj </th>
          <td mat-cell *matCellDef="let element"><span *ngIf="element.ovrsenici?.length">{{element.ovrhovoditelji?.[0].naziv}}</span></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row;columns: displayedColumns;" (click)="highlight(row)" [ngClass]="{highlight: selectedrow.jedinstveniIdentifikator === row.jedinstveniIdentifikator}" class="example-element-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[ 10,20,50]" showFirstLastButtons></mat-paginator>
      </div>
  </mat-card>
  <hr />
  </div>
