
<p *ngIf="!data">
  Učitavam podatke <img class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
</p>

<mat-card appearance="outlined" class="mt-2">
  <p class="data-title">
    Financijsko stanje salda-konta
  </p>
  <div *ngIf="data" class="row col-12">
    <div class="col-lg-3 col-12">
      <span class="data-description">Zaduženje (računi)</span><br />
      <span class="data-content">{{data.zaduzenje | number: '1.2-2':'hr'}}</span>
    </div>
    <div class="col-lg-3 col-12">
      <span class="data-description">
        Uplate
      </span><br />
      <span class="data-content">{{data.uplate | number: '1.2-2':'hr'}}</span>
    </div>
    <div class="col-lg-3 col-12">
      <span class="data-description">
        Ostalo
      </span><br />
      <span class="data-content">{{data.ostalo | number: '1.2-2':'hr'}}</span>
    </div>
    <div class="col-lg-3 col-12">
      <span class="data-description">
        Ukupni dug
      </span><br />
      <span class="data-content">{{ukupnidug | number: '1.2-2':'hr'}}</span>
    </div>
  </div>
  <div *ngIf="datacistoca" class="row col-12">
    <div class="col-lg-3 col-12">
      <span class="data-description">Zaduženje (računi) čistoća</span><br />
      <span class="data-content">{{datacistoca.zaduzenje | number: '1.2-2':'hr'}}</span>
    </div>
    <div class="col-lg-3 col-12">
      <span class="data-description">
        Uplate
      </span><br />
      <span class="data-content">{{datacistoca.uplate | number: '1.2-2':'hr'}}</span>
    </div>
    <div class="col-lg-3 col-12">
      <span class="data-description">
        Ostalo
      </span><br />
      <span class="data-content">{{datacistoca.ostalo | number: '1.2-2':'hr'}}</span>
    </div>
    <div class="col-lg-3 col-12">
      <span class="data-description">
        Ukupni dug čistoća
      </span><br />
      <span class="data-content">{{ukupnidugcistoca | number: '1.2-2':'hr'}}</span>
    </div>
  </div>
</mat-card>
<mat-card appearance="outlined" class="mt-2">
  <div echarts [options]="chartOption" class="chart"></div>
</mat-card>
<mat-card appearance="outlined" class="mt-2">
  <table class="col-12" *ngIf="data">
    <thead>
      <tr class="table-header text-center">
        <td>Struktura duga</td>
        <td>Iznos</td>
        <td>Postotak u ukupnom dugu</td>
        <td>Broj dužnika</td>
        <td>Prosječni dug</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dug of data.struktura" class="table-row">
        <td>{{dug.naziv}}</td>
        <td class="text-right">{{dug.iznos | number: '1.2-2':'hr'}}</td>
        <td class="text-center">{{dug.iznos*100/ukupniiznos | number: '1.0-0':'hr'}}%</td>
        <td class="text-right">{{dug.kolicina  | number: '1.0-0':'hr'}}</td>
        <td class="text-right">{{dug.iznos/dug.kolicina  | number: '1.2-2':'hr'}}</td>
      </tr>
      <tr class="table-footer">
        <td>Ukupno</td>
        <td class="text-right">{{ukupniiznos | number: '1.2-2':'hr'}}</td>
        <td></td>
        <td class="text-right">{{ukupnibroj | number: '1.0-0':'hr'}}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</mat-card>
<mat-card appearance="outlined" class="mt-2" *ngIf="datacistoca">
  <div echarts [options]="chartOptioncistoca" class="chart"></div>
</mat-card>
<mat-card appearance="outlined" class="mt-2" *ngIf="datacistoca">
  <table class="col-12" *ngIf="datacistoca">
    <thead>
      <tr class="table-header text-center">
        <td>Struktura duga čistoća</td>
        <td>Iznos</td>
        <td>Postotak u ukupnom dugu</td>
        <td>Broj dužnika</td>
        <td>Prosječni dug</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dug of datacistoca.struktura" class="table-row">
        <td>{{dug.naziv}}</td>
        <td class="text-right">{{dug.iznos | number: '1.2-2':'hr'}}</td>
        <td class="text-center">{{dug.iznos*100/ukupniiznos | number: '1.0-0':'hr'}}%</td>
        <td class="text-right">{{dug.kolicina  | number: '1.0-0':'hr'}}</td>
        <td class="text-right">{{dug.iznos/dug.kolicina  | number: '1.2-2':'hr'}}</td>
      </tr>
      <tr class="table-footer">
        <td>Ukupno</td>
        <td class="text-right">{{ukupniiznoscistoca | number: '1.2-2':'hr'}}</td>
        <td></td>
        <td class="text-right">{{ukupnibrojcistoca | number: '1.0-0':'hr'}}</td>
        <td></td>
      </tr>
    </tbody>
  </table>
</mat-card>

